import set from 'lodash/set';
import merge from 'lodash/merge';

import RefDatas from '@lib/RefDatas';
import getStore from '@store/store';
import { prettyStatus } from '../helpers/GridHelper';
import { isAllowed } from '@security/User';
import dayjs from 'dayjs';
const localizedFormat = require('dayjs/plugin/localizedFormat');
dayjs.extend(localizedFormat);

const other = {};
const red = { backgroundColor: 'rgba(255, 42, 42, 0.65)', ...other };
const orange = { backgroundColor: 'rgba(253, 180, 45, 0.65)', ...other };
const yellow = { backgroundColor: 'rgba(226, 226, 71, 0.65)', ...other };
const green = { backgroundColor: 'rgba(43, 145, 43, 0.65)', ...other };
const grey = { backgroundColor: 'grey', ...other };
const blue = { backgroundColor: 'rgba(38, 38, 248, 0.65)', ...other };
const none = { backgroundColor: 'none', ...other };

const currencyFormat = new Intl.NumberFormat('fr-FR', {
  style: 'currency',
  currency: 'EUR',
});

export default class ColumnsDefinition {
  constructor(refDatas, gridName, filters) {
    this.colDef = [];
    this.refDatas = new RefDatas(refDatas, gridName);
    this.filters = filters || {};

    this.REF_LABELS = {};
    for (let refData in getStore().getState().refDatas.data) {
      // if (refData === 'RefSite') {
      //   continue;
      // }

      this.REF_LABELS[refData] = getStore()
        .getState()
        .refDatas.data[refData].flatMap((elem) => {
          if (elem.options) {
            return elem.options.map((subElem) =>
              subElem.label.split('[')[0].trim(),
            );
          } else {
            return elem.label;
          }
        })
        .sort();
    }

    // Right access
    this.roles = {
      units: [
        'ROLE_WRI_MH_INFO',
        'ROLE_WRI_MH_EQUI',
        'ROLE_WRI_MH_EMPL',
        'ROLE_WRI_MH_HIVERN',
        'ROLE_WRI_MH_MVT',
        'ROLE_WRI_MH_COMMERCIAL_TYPE',
        'ROLE_WRI_MH_USE_TYPE',
        'ROLE_ACCOUNTING_MGNT',
      ],
      transfers: ['ROLE_WRI_MH_MVT', 'ROLE_WRI_MVT_LOGISTIC', 'ROLE_ADMIN_MVT'],
      sites: ['ROLE_WRI_SITE'],
      sales: ['ROLE_WRI_MH_SALES'],
      pitchFees: ['ROLE_WRI_PITCH_FEES'],
      pitches: ['ROLE_WRI_PITCHES'],
    };
  }

  //
  // Get col definition for an AG grid.
  //
  // Parameters:
  //   conf: columns def JSON.
  //   methods: used for some of the renderer/filter/actions (see getColDefs in MhList/MvList).
  //   namespace: used to get the permissions using `isAllowedField` (also used on forms).
  //
  getConfiguration(conf, methods, namespace) {
    // console.log('getConfiguration: conf=', conf)
    this.addCheckbox();
    this.addActions(methods);

    conf.forEach((columnDef) => {
      let columnDefinition = this.getColumnDefinition(columnDef, namespace);

      // extra loop for childrens
      if (columnDefinition.children) {
        columnDefinition.children.forEach((columnDefChild, i) => {
          columnDefinition.children[i] = this.getColumnDefinition(
            columnDefChild,
            namespace,
          );
        });
      }
      // console.log('columnDefinition: ', columnDefinition)
      this.colDef.push(columnDefinition);
    });

    return this.colDef;
  }

  //
  // Get definition for one column, based on conf entry provided
  //
  getColumnDefinition(columnDef, namespace) {
    // const oColDef = merge(this.getDefaultDefinition(), columnDef)
    const oColDef = columnDef;

    // // If column is editable, check if you're allowed
    // // We replace `editable:true` but a method, to check the row data.
    // // If not, editable will be overriden to false !
    // if (oColDef.editable) {
    //   // This method is called only on cell editing,
    //   // not for all cells of the grid!
    //   oColDef.editable = node => {
    //     const allowed = isFieldAllowed(namespace, oColDef.field, {
    //       mvLocked: node && node.data ? node.data.locked : false,
    //     })

    //     return allowed
    //   }

    //   oColDef.enableCellChangeFlash = true
    // }

    // // handle simple refData fields
    // if (oColDef.refData && !RefDatas.GROUPED_REF_DATA.includes(oColDef.refData)) {
    //   this.addStandardRefFields(oColDef)
    // }

    // // add predefined parameters and remove "shortcut" ones (to avoid warnings...)
    // // EDITORS
    // if (oColDef.customEditor) {
    //   this.addCustomEditor(oColDef)
    // }
    // delete oColDef.customEditor

    // // FILTERS
    // // disable filter if custom filter is "false"
    // // oColDef.suppressFilter = oColDef.customFilter === false

    // if (oColDef.customFilter) {
    //   // this.addCustomFilter(oColDef)
    // }
    // delete oColDef.customFilter

    // // tune default ag-grid filters
    // switch (oColDef.filter) {
    //   case 'agTextColumnFilter':
    //     oColDef.filterParams = {
    //       filterOptions: ['contains'],
    //       suppressAndOrCondition: true,
    //     }
    //     break
    //   /*case 'agDateColumnFilter':
    //     oColDef.filterParams = {
    //       suppressAndOrCondition: true,
    //     }
    //     break*/
    //   default:
    // }

    // // ROW GROUP - handle complex objects : MUST RETURN a simple string
    // if (oColDef.enableRowGroup === true) {
    //   if (oColDef.refData) {
    //     oColDef.keyCreator = function(params) {
    //       // console.log('keyCreator, params=', params, ', return params.label || params.lib = ', params.value.label || params.value.lib)
    //       return params && params.value ? params.value.label || params.value.lib : 'undefined'
    //     }
    //   }
    // }
    return oColDef;
  }

  // get basic definition for a column
  getDefaultDefinition() {
    return {
      field: undefined,
      headerName: undefined,
      // enablerowgroup: false,
      editable: false,
      enableCellChangeFlash: true,
    };
  }

  addStandardRefFields(conf) {
    return merge(conf, {
      // enablerowgroup: true,
      cellRenderer: 'listCellRenderer',
      // customEditor: conf.customEditor ? conf.customEditor : 'agRichSelectCellEditor',
      //
      customFilter: conf.customFilter === false ? false : true,
      newValueHandler: function (params) {
        // if the parent(s) nodes does not exists, create them
        set(params.node.data, params.colDef.field, params.newValue);
      },
    });
  }

  addCustomEditor(conf) {
    let groupRef = false;
    if (RefDatas.GROUPED_REF_DATA.includes(conf.refData)) {
      groupRef = true;
    }
    const mergedConf = merge(conf, {
      cellEditor: conf.customEditor,
      cellEditorParams: {
        values: this.refDatas.getRefData(conf.refData),
        formatedValues: this.refDatas.getRefSelectFormatedData(conf.refData),
        cellRenderer: 'listCellRenderer',
        group: groupRef,
      },
    });
    return mergedConf;
  }

  addCustomFilter(conf) {
    const mergedConf = merge(conf, {
      filter: 'multiSelectFilter',
      filterParams: {
        options: this.refDatas.getRefSelectFormatedData(conf.refData),
      },
      // floatingFilterComponent: 'multiSelectFloatingFilter',
      // floatingFilterComponentParams: {
      //   options: this.refDatas.getRefSelectFormatedData(conf.refData),
      //   suppressFilterButton: false,
      //   selectedValues: this.filters[conf.field],
      // },
    });
    if (RefDatas.GROUPED_REF_DATA.includes(conf.refData)) {
      mergedConf.filterParams.group = true;
      // mergedConf.floatingFilterComponentParams.group = true
    }
    return mergedConf;
  }

  // ---------------------------------------------------
  // Default columns
  // ---------------------------------------------------
  addActions(methods) {
    this.colDef.push({
      colId: 'actions',
      headerName: 'Actions',
      pinned: 'left',
      width: 200,
      cellRenderer: 'buttonCellRenderer',
      cellRendererParams: {
        methods: methods,
      },
      suppressMenu: true,
      sort: false,
      filter: false,
      suppressSizeToFit: true,
    });
  }
  addCheckbox() {
    this.colDef.push({
      colId: 'selection',
      headerName: '☑︎',
      headerCheckboxSelection: true,
      headerCheckboxSelectionFilteredOnly: true,
      checkboxSelection: true,
      pinned: 'left',
      width: 30,
      suppressMenu: true,
      // suppressSorting: true,
      // suppressFilter: true,
      suppressSizeToFit: true,
    });
  }

  // ---------------------------------------------------
  // Special cases
  // ---------------------------------------------------
  generateYearListTD() {
    let aY = [];
    const cy = new Date().getFullYear();
    for (let i = cy + 2; i > cy - 25; i--) aY.push(i);
    return aY;
  }

  generateRefWeek() {
    const result = [
      'Y0-W36',
      'Y0-W37',
      'Y0-W38',
      'Y0-W39',
      'Y0-W40',
      'Y0-W41',
      'Y0-W42',
      'Y0-W43',
      'Y0-W44',
      'Y0-W45',
      'Y0-W47',
      'Y0-W47',
      'Y0-W48',
      'Y0-W49',
      'Y0-W50',
      'Y0-W51',
      'Y0-W52',
      'Y1-W01',
      'Y1-W02',
      'Y1-W03',
      'Y1-W04',
      'Y1-W05',
      'Y1-W06',
      'Y1-W07',
      'Y1-W08',
      'Y1-W09',
      'Y1-W10',
      'Y1-W11',
      'Y1-W12',
      'Y1-W13',
      'Y1-W14',
      'Y1-W15',
      'Y1-W16',
      'Y1-W17',
      'Y1-W18',
      'Y1-W19',
      'Y1-W20',
      'Y1-W21',
    ];
    return result;
  }

  getTransfersTypes(params) {
    params?.data?.transfer_types?.sort((a, b) => a.code.localeCompare(b.code));
    console.log('===>', params.data);
    return (
      params?.data?.transfer_types?.reduce(
        (acc, current) => (acc += `${current.code}, `),
        '',
      ) || ''
    );
  }

  getLibFromRefurbItems(params) {
    params?.data?.refurb_items?.sort((a, b) => a.lib.localeCompare(b.lib));
    // console.log(params.data);
    return (
      params?.data?.refurb_items?.reduce(
        (acc, current) => (acc += `${current.lib}, `),
        '',
      ) || ''
    );
  }

  getCostValue(params) {
    let response = null;

    if (params && params.data) {
      const [connections, object, type, detail] = [
        ...params.column.colId.split('.'),
      ];

      if (
        params.data[connections] &&
        params.data[connections][object] &&
        params.data[connections][object][type] &&
        params.data[connections][object][type][detail]
      ) {
        const costValue = parseFloat(
          params.data[connections][object][type][detail],
          10,
        );

        response = `${costValue.toFixed(2)} ${
          params.data[connections][object][type].currency || '€'
        }`;
      }
    }

    return response;
  }

  getRiskColor(params) {
    let response = null;

    if (params && params.value) {
      if (params.value === 'No Risk') {
        response = green;
      } else if (params.value === 'Medium') {
        response = orange;
      } else if (params.value === 'High') {
        response = red;
      }
    }

    return response;
  }

  getLogisticsColorStatus(params) {
    let response = null;

    if (params?.data) {
      const [connections, object, type, detail] = [
        ...params.column.colId.split('.'),
      ];

      // TODO: Voir si je peux par API fermer et ouvrir les sous colonnes
      if (
        params.data[connections] &&
        params.data[connections][object] &&
        params.data[connections][object][type] &&
        params.data[connections][object][type].status
      ) {
        const { _id: statusID, lib } =
          params.data[connections][object][type].status || {};

        // console.log('JYO: params.data: ', params.data)
        // console.log('JYO: connections: ', connections);
        // console.log('JYO: object: ', object);
        // console.log('JYO: type: ', type);
        // console.log('JYO: detail: ', detail);
        // console.log('JYO: statusID: ', statusID);
        // console.log('JYO: lib: ', lib);
        // console.log('JYO: status: ', status);
        // console.log('JYO: typeof status: ', typeof status);
        // console.log('JYO: params.data[connections][object][type].status: ', params.data[connections][object][type].status);

        // * 5d52e73146c0e20020547570 === Sub TBD
        if (statusID === '5d52e73146c0e20020547570') {
          response = red;
        }
        // * 5d52e73846c0e20020547572 === Sub TBC
        else if (statusID === '5d52e73846c0e20020547572') {
          if (
            detail === 'status' ||
            detail === 'purchase_order' ||
            detail === 'week' ||
            detail === 'week_confirmed'
          ) {
            response = red;
          } else if (detail === 'cost' || detail === 'subcontractor') {
            response = orange;
          }
        }
        // * 578f680d8e43630a0032fcec === Sub Conf / Week TBC
        else if (statusID === '578f680d8e43630a0032fcec') {
          if (
            detail === 'status' ||
            detail === 'week' ||
            detail === 'week_confirmed'
          ) {
            response = orange;
          } else if (
            detail === 'cost' ||
            detail === 'subcontractor' ||
            detail === 'purchase_order'
          ) {
            response = green;
          }
        }
        // * 578f68178e43630a0032fced === Planned
        else if (statusID === '578f68178e43630a0032fced') {
          if (
            detail === 'status' ||
            detail === 'week' ||
            detail === 'week_confirmed'
          ) {
            response = yellow;
          } else if (
            detail === 'cost' ||
            detail === 'subcontractor' ||
            detail === 'purchase_order'
          ) {
            response = green;
          }
        }
        // * 578f68335a58720f0053b6b6 === Done
        else if (statusID === '578f68335a58720f0053b6b6') {
          response = green;
        }
        // * 578f68248e43630a0032fcee === Cancelled
        else if (statusID === '578f68248e43630a0032fcee') {
          response = grey;
        }
        // * 578f68048e43630a0032fcea === Not To Do
        else if (statusID === '578f68048e43630a0032fcea') {
          response = blue;
        }
        // * 5d63da92fb385a00207077b5 === Null
        else if (statusID === '5d63da92fb385a00207077b5') {
          response = none;
        }
      }
    }

    return response;
  }

  // getRightPitchFeesValue(params) {
  //   const pitch_fees = 'pitch_fees';
  //   const pitch_fee = params && params.data && params.data[pitch_fees] && params.data[pitch_fees].constructor === Array && params.data[pitch_fees].find(element => element.year === params.data.selectedSeason) || false;
  //   let response = null;

  //   if (pitch_fee && pitch_fee.price && pitch_fee.currency) {
  //     response = `${pitch_fee.price.toFixed(2)} ${pitch_fee.currency}`;
  //   }
  //   return response;
  // }

  // Select the right index to update the pitch_fee price
  // setRightPitchFeesValue(params) {
  //   if (params) {
  //     const pitch_fees = 'pitch_fees';

  //     if (!params.data[pitch_fees]) {
  //       params.data[pitch_fees] = [{ year: params.data.selectedSeason }];
  //     }

  //     const pitchFeeIndex = params.data[pitch_fees].constructor === Array && params.data[pitch_fees].findIndex(element => element.year === params.data.selectedSeason);

  //     if (pitchFeeIndex !== -1) {
  //       if (params.newValue === null || params.newValue === undefined || params.newValue === "") {
  //         params.data[pitch_fees][pitchFeeIndex].price = 0;
  //       }
  //       else {
  //         params.data[pitch_fees][pitchFeeIndex].price = parseFloat(params.newValue);
  //       }
  //     }
  //     else {
  //       params.data[pitch_fees].push({ year: params.data.selectedSeason, price: parseFloat(params.newValue), currency: '€', comment: '' });
  //     }

  //     getStore().dispatch(saveNewPitchFees({ _id: params.data._id, pitchFees: params.data[pitch_fees] }));
  //   }
  // }

  // getRightPitchFeesComment(params) {
  //   const pitch_fees = 'pitch_fees';
  //   const pitch_fee = params && params.data  && params.data[pitch_fees] && params.data[pitch_fees].constructor === Array && params.data[pitch_fees].find(element => element.year === params.data.selectedSeason) || false;
  //   let response = null;

  //   if (pitch_fee && pitch_fee.comment) {
  //     response = pitch_fee.comment;
  //   }
  //   return response;
  // }

  // Select the right index to update the pitch_fee price
  // setRightPitchFeesComment(params) {
  //   if (params) {
  //     const pitch_fees = 'pitch_fees';

  //     if (!params.data[pitch_fees]) {
  //       params.data[pitch_fees] = [{ year: params.data.selectedSeason }];
  //     }

  //     const pitchFeeIndex = params.data[pitch_fees].findIndex(element => element.year === params.data.selectedSeason);

  //     if (pitchFeeIndex !== -1) {
  //       params.data[pitch_fees][pitchFeeIndex].comment = params.newValue;
  //     }
  //     else {
  //       params.data[pitch_fees].push({ year: params.data.selectedSeason, comment: params.newValue });
  //     }

  //     getStore().dispatch(saveNewPitchFees({ _id: params.data._id, pitchFees: params.data[pitch_fees] }));
  //   }
  // }

  getExcelStyles() {
    return [
      {
        id: 'numberType',
        numberFormat: {
          format: '0',
        },
      },
      {
        id: 'currencyType',
        numberFormat: {
          format: '#,##0.00 €',
        },
      },
      {
        id: 'percentType',
        numberFormat: {
          format: '#,##0.00 %',
        },
      },
      {
        id: 'negativeInBrackets',
        numberFormat: {
          format: '$[blue] #,##0;$ [red](#,##0)',
        },
      },
      {
        id: 'booleanType',
        dataType: 'Boolean',
      },
      {
        id: 'stringType',
        dataType: 'string',
      },
      {
        id: 'dateType',
        dataType: 'DateTime',
      },
    ];
  }

  getMHListConfiguration(methods, t) {
    return this.getConfiguration(
      [
        // Root mh_model (refData)
        {
          headerName: t('page.units.column.unit_model.header', 'Unit model'),
          headerClass: 'groupColumnHeader',
          children: [
            {
              field: 'mh_model.lib',
              headerName: t('page.units.column.unit_model.lib', 'Label'),
              editable: isAllowed(this.roles.units, true),
              cellEditorSelector: () => {
                return {
                  component: 'agRichSelect',
                  params: {
                    values: this.REF_LABELS.MhModel,
                  },
                };
              },
              filter: 'agSetColumnFilter',
              filterParams: {},
              floatingFilterComponentParams: { suppressFilterButton: false },
            },
            {
              field: 'mh_model.type.lib',
              headerName: t('page.units.column.unit_model.unit_type', 'Type'),
              filter: 'agSetColumnFilter',
              filterParams: {},
              floatingFilterComponentParams: { suppressFilterButton: false },
              cellRenderer: 'unitTypeCellRenderer',
            },
            {
              field: 'mh_model.manufacturer.lib',
              headerName: t(
                'page.units.column.unit_model.manufacturer',
                'Manufacturer',
              ),
              columnGroupShow: 'open',
              filter: 'agSetColumnFilter',
              filterParams: {},
              floatingFilterComponentParams: { suppressFilterButton: false },
            },
            {
              field: 'mh_model.manufacturer_model.lib',
              headerName: t(
                'page.units.column.unit_model.manufacturer_model',
                'Manufacturer model',
              ),
              columnGroupShow: 'open',
              filter: 'agSetColumnFilter',
              filterParams: {},
              floatingFilterComponentParams: { suppressFilterButton: false },
            },
            {
              field: 'mh_model.vintage',
              headerName: t('page.units.column.unit_model.vintage', 'Vintage'),
              columnGroupShow: 'open',
              filter: 'agSetColumnFilter',
              filterParams: {},
              floatingFilterComponentParams: { suppressFilterButton: false },
            },
            {
              field: 'mh_model.length',
              headerName: t(
                'page.units.column.unit_model.length',
                'Length (m)',
              ),
              columnGroupShow: 'open',
              hide: true,
              filter: 'agSetColumnFilter',
              filterParams: {},
              floatingFilterComponentParams: { suppressFilterButton: false },
            },
            {
              field: 'mh_model.width',
              headerName: t('page.units.column.unit_model.width', 'Width (m)'),
              columnGroupShow: 'open',
              hide: true,
              filter: 'agSetColumnFilter',
              filterParams: {},
              floatingFilterComponentParams: { suppressFilterButton: false },
            },
            // {
            //   field: 'mh_model.nb_rooms',
            //   headerName: t('page.mh_list.column.mh_model.nb_rooms'),
            //   columnGroupShow: 'open',
            //   hide: true,
            //   filter: 'agSetColumnFilter',
            //   filterParams: {
            //
            //   },
            //   floatingFilterComponentParams: { suppressFilterButton: false },
            // },
            // {
            //   field: 'mh_model.nb_beds',
            //   headerName: t('page.mh_list.column.mh_model.nb_beds'),
            //   columnGroupShow: 'open',
            //   hide: true,
            //   filter: 'agSetColumnFilter',
            //   filterParams: {
            //
            //   },
            //   floatingFilterComponentParams: { suppressFilterButton: false },
            // },
            {
              field: 'mh_model.roof_type.lib',
              headerName: t(
                'page.units.column.unit_model.roof_type',
                'Roof type',
              ),
              columnGroupShow: 'open',
              hide: true,
              filter: 'agSetColumnFilter',
              filterParams: {},
              floatingFilterComponentParams: { suppressFilterButton: false },
            },
            {
              field: 'mh_model.additional_information',
              headerName: t(
                'page.units.column.unit_model.additional_information',
                'Additional information',
              ),
              editable: false,
              columnGroupShow: 'closed',
              hide: true,
              filter: 'agSetColumnFilter',
              filterParams: {},
              floatingFilterComponentParams: { suppressFilterButton: false },
              tooltipField: 'mh_model.additional_information',
            },
          ],
        },

        // {
        //   // TODO: There is the right place to get this data ?
        //   field: 'cladding_color.lib',
        //   headerName: t('page.mh_list.column.mh_model.cladding_color'),
        //   columnGroupShow: 'open',
        //   hide: true,
        //   filter: 'agSetColumnFilter',
        //   filterParams: {
        //
        //   },
        //   floatingFilterComponentParams: { suppressFilterButton: false },
        // },
        // {
        //   // TODO: There is the right place to get this data ?
        //   field: 'cladding_material.lib',
        //   headerName: t('page.mh_list.column.mh_model.cladding_material'),
        //   columnGroupShow: 'open',
        //   hide: true,
        //   filter: 'agSetColumnFilter',
        //   filterParams: {
        //
        //   },
        //   floatingFilterComponentParams: { suppressFilterButton: false },
        // },

        // Other fields
        {
          field: 'barcode',
          headerName: t('page.units.column.barcode', 'Barcode'),
          // editable: isAllowed(this.roles.units, true),
          filter: 'agSetColumnFilter',
          filterParams: {},
          floatingFilterComponentParams: { suppressFilterButton: false },
          cellStyle: { 'font-weight': 600 },
        },
        {
          field: 'former_barcode',
          headerName: t('page.units.column.former_barcode', 'Former barcode'),
          hide: true,
          filter: 'agSetColumnFilter',
          filterParams: {},
          floatingFilterComponentParams: { suppressFilterButton: false },
        },
        {
          field: 'id_chassis',
          headerName: t('page.units.column.chassis_id', 'Chassis ID'),
          editable: isAllowed(this.roles.units, true),

          filter: 'agSetColumnFilter',
          filterParams: {},
          floatingFilterComponentParams: { suppressFilterButton: false },
          cellClass: 'stringType',
          cellStyle: { 'font-weight': 600 },
        },
        {
          field: 'perimeter',
          headerName: t('page.units.column.perimeter', 'Maintenance perimeter'),
          editable: isAllowed(this.roles.units, true),
          cellEditorSelector: () => {
            return {
              component: 'agRichSelect',
              params: {
                values: [
                  'Brand Managed',
                  'Marvilla',
                  'ECG Support',
                  'Site Managed',
                  'ERD',
                  'Cisca',
                  'Out',
                ],
              },
            };
          },
          filter: 'agSetColumnFilter',
          filterParams: {},
          floatingFilterComponentParams: { suppressFilterButton: false },
        },
        {
          headerName: t('page.units.column.skirting.title', 'Skirting'),
          hide: true,
          headerClass: 'groupColumnHeader',
          children: [
            {
              field: 'skirting_material.lib',
              headerName: t('page.units.column.skirting.material', 'Material'),
              editable: isAllowed(this.roles.units, true),
              cellEditorSelector: () => {
                return {
                  component: 'agRichSelect',
                  params: {
                    values: this.REF_LABELS.RefSkirtingMaterial,
                  },
                };
              },
              filter: 'agSetColumnFilter',
              filterParams: {},
              floatingFilterComponentParams: { suppressFilterButton: false },
            },
            {
              field: 'skirting_sides.lib',
              headerName: t('page.units.column.skirting.sides', 'Sides'),
              editable: isAllowed(this.roles.units, true),
              cellEditorSelector: () => {
                return {
                  component: 'agRichSelect',
                  params: {
                    values: this.REF_LABELS.RefSkirtingSides,
                  },
                };
              },
              filter: 'agSetColumnFilter',
              filterParams: {},
              floatingFilterComponentParams: { suppressFilterButton: false },
            },
          ],
        },
        {
          headerName: t('page.units.column.sales.title', 'Sales'),
          headerClass: 'groupColumnHeader',
          children: [
            {
              field: 'sales.status.lib',
              headerName: t('page.units.column.sales.status', 'Status'),
              filter: 'agSetColumnFilter',
              editable: isAllowed(this.roles.sales, true),
              filterParams: {},
              floatingFilterComponentParams: { suppressFilterButton: false },
              cellEditorSelector: () => {
                return {
                  component: 'agRichSelect',
                  params: {
                    values: this.REF_LABELS.RefSalesStatus,
                  },
                };
              },
              cellRenderer: 'tagCellRenderer',
            },
            {
              field: 'sales.tax',
              columnGroupShow: 'closed',
              headerName: t('page.units.column.sales.tax', 'Tax'),
              editable: isAllowed(this.roles.sales, true),
              filter: 'agSetColumnFilter',
              filterParams: {},
              floatingFilterComponentParams: { suppressFilterButton: false },
              cellEditorSelector: () => {
                return {
                  component: 'agRichSelect',
                  params: {
                    values: [true, false],
                  },
                };
              },
              valueFormatter: (params) => (params.value ? 'YES' : 'NO'),
              cellClass: (params) =>
                params.value ? 'default_boolean_true' : 'default_boolean_false',
            },
            {
              field: 'sales.category.lib',
              headerName: t('page.units.column.sales.category', 'Category'),
              filter: 'agSetColumnFilter',
              filterParams: {},
              floatingFilterComponentParams: { suppressFilterButton: false },
              cellEditorSelector: () => {
                return {
                  component: 'agRichSelect',
                  params: {
                    values: this.REF_LABELS.RefSalesCategory,
                  },
                };
              },
              cellRenderer: 'tagCellRenderer',
            },
            {
              field: 'sales.advisor_data',
              headerName: t(
                'page.units.column.sales.advisor_data',
                'Advisor data',
              ),
              columnGroupShow: 'closed',
              editable: isAllowed(this.roles.sales, true),
              cellEditor: 'agLargeTextCellEditor',
              cellEditorParams: {
                maxLength: '300', // override the editor defaults
                cols: '50',
                rows: '5',
              },
              filter: 'agSetColumnFilter',
              filterParams: {},
              floatingFilterComponentParams: { suppressFilterButton: false },
            },
          ],
        },
        {
          headerName: t('page.units.column.campsite.title', 'Campsite'),
          headerClass: 'groupColumnHeader',
          children: [
            {
              field: 'site.lib',
              headerName: t('page.units.column.campsite.name', 'Name'),
              // editable: isAllowed(this.roles.units, true),
              cellEditorSelector: () => {
                return {
                  component: 'agRichSelect',
                  params: {
                    values: this.REF_LABELS.Site,
                  },
                };
              },

              filter: 'agSetColumnFilter',
              filterParams: {},
              floatingFilterComponentParams: { suppressFilterButton: false },
            },
            {
              field: 'site.code_group',
              headerName: t(
                'page.units.column.campsite.group_code',
                'Group code',
              ),

              filter: 'agSetColumnFilter',
              filterParams: {},
              floatingFilterComponentParams: { suppressFilterButton: false },
              cellStyle: { 'font-weight': 600 },
            },
            {
              field: 'site.loca.country',
              headerName: t(
                'page.units.column.campsite.loca.country',
                'Country',
              ),
              columnGroupShow: 'open',

              filter: 'agSetColumnFilter',
              filterParams: {},
              floatingFilterComponentParams: { suppressFilterButton: false },
            },
            {
              field: 'site.loca.region',
              headerName: t('page.units.column.campsite.loca.region', 'Region'),
              columnGroupShow: 'open',

              filter: 'agSetColumnFilter',
              filterParams: {},
              floatingFilterComponentParams: { suppressFilterButton: false },
            },
            {
              field: 'site.loca.city',
              headerName: t('page.units.column.campsite.loca.city', 'City'),
              columnGroupShow: 'open',

              filter: 'agSetColumnFilter',
              filterParams: {},
              floatingFilterComponentParams: { suppressFilterButton: false },
            },
            {
              field: 'site.is_propre',
              headerName: t('page.campsites.column.own_site', 'Own site'),
              columnGroupShow: 'open',

              filter: 'agSetColumnFilter',
              filterParams: {},
              floatingFilterComponentParams: { suppressFilterButton: false },
              valueFormatter: (params) => (params.value ? '✅' : '❌'),
            },
          ],
        },
        /*{
          field: 'f2m_data_sc',
          headerName: t(
            'page.units.column.safety_commissioning',
            'Safety commissioning',
          ),
          editable: false,

          filter: 'agSetColumnFilter',
          filterParams: {},
          floatingFilterComponentParams: { suppressFilterButton: false },
          valueFormatter: (params) => {
            switch (params.value) {
              case true:
                return 'PASS 🎆';
              case false:
                return 'FAIL 💥';
              default:
                return '-';
            }
          },
          cellClassRules: {
            control_true: (params) => params.value === true,
            control_false: (params) => params.value === false,
          },
        },*/
        {
          field: 'f2m_data_tc',
          headerName: t('page.units.column.technical_check', 'Technical check'),
          editable: false,

          filter: 'agSetColumnFilter',
          filterParams: {},
          floatingFilterComponentParams: { suppressFilterButton: false },

          valueFormatter: (params) => {
            switch (params.value) {
              case true:
                return 'PASS 🎆';
              case false:
                return 'FAIL 💥';
              default:
                return '-';
            }
          },
          cellClassRules: {
            control_true: (params) => params.value === true,
            control_false: (params) => params.value === false,
          },
        },
        {
          field: 'f2m_data_ec',
          headerName: t('page.units.column.equipment_check', 'Equipment check'),
          editable: false,

          filter: 'agSetColumnFilter',
          filterParams: {},
          floatingFilterComponentParams: { suppressFilterButton: false },

          valueFormatter: (params) => {
            switch (params.value) {
              case true:
                return 'PASS 🎆';
              case false:
                return 'FAIL 💥';
              default:
                return '-';
            }
          },
          cellClassRules: {
            control_true: (params) => params.value === true,
            control_false: (params) => params.value === false,
          },
        },
        {
          field: 'five_year_review',
          headerName: t(
            'page.mh_list.column.five_year_review',
            'Five-year review',
          ),
          hide: true,
          editable: isAllowed(this.roles.units, true),
          cellEditor: 'agLargeTextCellEditor',
          cellEditorParams: {
            maxLength: '300', // override the editor defaults
            cols: '50',
            rows: '5',
          },
          filter: 'agSetColumnFilter',
          filterParams: {},
          floatingFilterComponentParams: { suppressFilterButton: false },
        },
        {
          field: 'f2m_data_ms',
          headerName: t(
            'page.units.column.maintenance_status',
            'Maintenance status',
          ),
          editable: false,

          filter: 'agSetColumnFilter',
          filterParams: {},
          floatingFilterComponentParams: { suppressFilterButton: false },
          valueFormatter: (params) => {
            switch (params.value) {
              case '✔':
                return 'PASS 🎆';
              case '✖':
                return 'FAIL 💥';
              default:
                return '-';
            }
          },
          cellClassRules: {
            control_true: (params) => params.value === '✔',
            control_false: (params) => params.value === '✖',
          },
        },
        // {
        //   field: 'f2m_data_nmr',
        //   headerName: t('page.mh_list.column.number_maintenance_request'),
        //   editable: false,

        //   filter: 'agSetColumnFilter',
        //   filterParams: {
        //
        //   },
        //   floatingFilterComponentParams: { suppressFilterButton: false },
        // },
        // {
        //   headerName: t('page.mh_list.column.equipement_check'),
        //   editable: false,
        //   filter: 'agSetColumnFilter',
        //   filterParams: {
        //
        //   },
        //   floatingFilterComponentParams: { suppressFilterButton: false },
        //   valueGetter: (params) => {
        //     if (params.data?.f2m_data) {
        //       const f2mdata = params.data.f2m_data;
        //       let sc = f2mdata.filter(
        //         (item) =>
        //           item.season == new Date().getFullYear() &&
        //           item.app_name === 'equipment_check',
        //       );
        //       if (sc.length < 1) return '-';
        //       return sc.some((item) => item.status === 'Complete')
        //         ? '✅'
        //         : '❌';
        //     }
        //     return '-';
        //   },
        // },
        // {
        //   headerName: t(
        //     'page.mh_list.column.technical_check',
        //     'Technical check',
        //   ),
        //   editable: false,

        //   filter: 'agSetColumnFilter',
        //   filterParams: {
        //
        //   },
        //   floatingFilterComponentParams: { suppressFilterButton: false },
        //   valueGetter: (params) => {
        //     if (params.data?.f2m_data) {
        //       const f2mdata = params.data.f2m_data;
        //       let sc = f2mdata.filter(
        //         (item) =>
        //           item.season == new Date().getFullYear() &&
        //           item.app_name === 'technical_check',
        //       );
        //       if (sc.length < 1) return '-';
        //       return sc.some((item) => item.status === 'Complete')
        //         ? '✅'
        //         : '❌';
        //     }
        //     return '-';
        //   },
        // },

        // ----------------------------------------------------------------------------------------

        {
          field: 'pitch',
          headerName: t('page.units.column.pitch', 'Pitch'),
          // editable: isAllowed(this.roles.units, true),

          filter: 'agSetColumnFilter',
          filterParams: {},
          floatingFilterComponentParams: { suppressFilterButton: false },
          cellClass: 'stringType',
        },
        {
          field: 'pitch_corres',
          headerName: t('page.units.column.pitch_corres', 'Pitch corres'),
          hide: true,
          editable: isAllowed(this.roles.units, true),

          filter: 'agSetColumnFilter',
          filterParams: {},
          floatingFilterComponentParams: { suppressFilterButton: false },
          cellClass: 'stringType',
        },
        {
          field: 'use_type.lib',
          headerName: t('page.units.column.use_type', 'Use type'),
          editable: isAllowed(this.roles.units, true),
          cellEditorSelector: () => {
            return {
              component: 'agRichSelect',
              params: {
                values: this.REF_LABELS.RefTypeUtilisation,
              },
            };
          },
          filter: 'agSetColumnFilter',
          filterParams: {},
          floatingFilterComponentParams: { suppressFilterButton: false },
          cellRenderer: 'tagCellRenderer',
        },
        {
          field: 'key_tag',
          headerName: t('page.mh_list.column.ec_key', 'Key tag'),
          hide: true,
          editable: isAllowed(this.roles.units, true),

          filter: 'agSetColumnFilter',
          filterParams: {},
          floatingFilterComponentParams: { suppressFilterButton: false },
        },
        {
          headerName: t('page.units.column.commercial.title', 'Commercial'),
          headerClass: 'groupColumnHeader',
          children: [
            {
              field: 'commercial_brand.lib',
              headerName: t(
                'page.units.column.commercial_brand',
                'Commercial brand',
              ),
              editable: isAllowed(this.roles.units, true),
              cellEditorSelector: () => {
                return {
                  component: 'agRichSelect',
                  params: {
                    values: this.REF_LABELS.RefMarqueCommerciale,
                  },
                };
              },
              filter: 'agSetColumnFilter',
              filterParams: {},
              floatingFilterComponentParams: { suppressFilterButton: false },
              cellRenderer: 'flagCellRenderer',
            },
            {
              field: 'commercial_type.lib',
              headerName: t(
                'page.units.column.commercial_type.lib',
                'Commercial type',
              ),
              // editable: isAllowed(this.roles.units, true),
              cellEditorSelector: () => {
                return {
                  component: 'agRichSelect',
                  params: {
                    values: this.REF_LABELS.RefTypeCommercial,
                  },
                };
              },
              filter: 'agSetColumnFilter',
              filterParams: {},
              floatingFilterComponentParams: { suppressFilterButton: false },
            },
            {
              field: 'commercial_type.code',
              headerName: t(
                'page.units.column.commercial_type.code',
                'Commercial type code',
              ),
              filter: 'agSetColumnFilter',
              filterParams: {},
              floatingFilterComponentParams: { suppressFilterButton: false },
              cellStyle: { 'font-weight': 600 },
            },
            {
              field: 'commercial_type_actual.lib',
              headerName: t(
                'page.units.column.commercial_type_actual.lib',
                'Commercial type actual',
              ),
              // editable: isAllowed(this.roles.units, true),
              cellEditorSelector: () => {
                return {
                  component: 'agRichSelect',
                  params: {
                    values: this.REF_LABELS.RefTypeCommercial,
                  },
                };
              },
              filter: 'agSetColumnFilter',
              filterParams: {},
              floatingFilterComponentParams: { suppressFilterButton: false },
            },
            {
              field: 'commercial_type_actual.code',
              headerName: t(
                'page.units.column.commercial_type_actual.code',
                'Commercial type code actual',
              ),
              filter: 'agSetColumnFilter',
              filterParams: {},
              floatingFilterComponentParams: { suppressFilterButton: false },
              cellStyle: { 'font-weight': 600 },
            },
            {
              field: 'former_commercial_type_code',
              headerName: t(
                'page.units.column.former_commercial_type_code',
                'Former commercial type code',
              ),
              filter: 'agSetColumnFilter',
              filterParams: {},
              floatingFilterComponentParams: { suppressFilterButton: false },
              cellStyle: { 'font-weight': 600 },
            },
            {
              field: 'commercial_type.group',
              headerName: t('page.units.column.commercial_type.group', 'Group'),
              filter: 'agSetColumnFilter',
              filterParams: {},
              floatingFilterComponentParams: { suppressFilterButton: false },
            },
            {
              field: 'commercial_type.parent_lib',
              headerName: t(
                'page.units.column.commercial_type.parent_lib',
                'Parent',
              ),
              hide: true,
              columnGroupShow: 'closed',
              filter: 'agSetColumnFilter',
              filterParams: {},
              floatingFilterComponentParams: { suppressFilterButton: false },
            },
            {
              field: 'commercial_type.is_clim',
              headerName: t(
                'page.units.column.commercial_type.ac',
                'Air conditioning',
              ),
              filter: 'agSetColumnFilter',
              filterParams: {},
              floatingFilterComponentParams: { suppressFilterButton: false },
              valueFormatter: (params) => (params.value ? '❄️' : '-'),
            },
            {
              field: 'commercial_type.is_terrasse',
              headerName: t('page.units.column.commercial_type.deck', 'Deck'),
              columnGroupShow: 'closed',
              filter: 'agSetColumnFilter',
              filterParams: {},
              floatingFilterComponentParams: { suppressFilterButton: false },
              valueFormatter: (params) => (params.value ? '🕶️' : '-'),
            },
            {
              field: 'commercial_type.dishwasher',
              headerName: t(
                'page.units.column.commercial_type.dishwasher',
                'Dishwasher',
              ),
              columnGroupShow: 'closed',
              filter: 'agSetColumnFilter',
              filterParams: {},
              floatingFilterComponentParams: { suppressFilterButton: false },
              valueFormatter: (params) => (params.value ? '🧼' : '-'),
            },
            {
              field: 'commercial_type.wifi',
              headerName: t('page.units.column.commercial_type.wifi', 'WiFi'),
              columnGroupShow: 'closed',
              filter: 'agSetColumnFilter',
              filterParams: {},
              floatingFilterComponentParams: { suppressFilterButton: false },
              valueFormatter: (params) => (params.value ? '📶' : '-'),
            },
            {
              field: 'commercial_type.is_empl_premium',
              headerName: t(
                'page.units.column.commercial_type.premium_pitch',
                'Premium pitch',
              ),
              columnGroupShow: 'closed',
              filter: 'agSetColumnFilter',
              filterParams: {},
              floatingFilterComponentParams: { suppressFilterButton: false },
              valueFormatter: (params) => (params.value ? '💖' : '-'),
            },
          ],
        },
        {
          headerName: t('page.units.column.deck.title', 'Deck'),
          headerClass: 'groupColumnHeader',
          children: [
            {
              field: 'deck_type.lib',
              headerName: t('page.units.column.deck_type', 'Deck type'),
              hide: true,
              editable: isAllowed(this.roles.units, true),
              cellEditorSelector: () => {
                return {
                  component: 'agRichSelect',
                  params: {
                    values: this.REF_LABELS.RefTypeTerrasse,
                  },
                };
              },

              filter: 'agSetColumnFilter',
              filterParams: {},
              floatingFilterComponentParams: { suppressFilterButton: false },
            },
            {
              field: 'deck_size.lib',
              headerName: t('page.units.column.deck_size', 'Deck size'),
              hide: true,
              editable: isAllowed(this.roles.units, true),
              cellEditorSelector: () => {
                return {
                  component: 'agRichSelect',
                  params: {
                    values: this.REF_LABELS.RefTailleTerrasse,
                  },
                };
              },

              filter: 'agSetColumnFilter',
              filterParams: {},
              floatingFilterComponentParams: { suppressFilterButton: false },
            },
            {
              field: 'deck_year',
              headerName: t('page.units.column.deck_year', 'Deck year'),
              hide: true,
              editable: isAllowed(this.roles.units, true),
              // ! Check why is not working...
              cellEditorSelector: () => {
                return {
                  component: 'agRichSelect',
                  params: {
                    values: this.generateYearListTD(),
                  },
                };
              },

              filter: 'agSetColumnFilter',
              filterParams: {},
              floatingFilterComponentParams: { suppressFilterButton: false },
            },
            {
              field: 'deck_manufacturer.lib',
              headerName: t(
                'page.units.column.deck_manufacturer',
                'Deck manufacturer',
              ),
              hide: true,
              editable: isAllowed(this.roles.units, true),
              cellEditorSelector: () => {
                return {
                  component: 'agRichSelect',
                  params: {
                    values: this.REF_LABELS.RefDeckManufacturer,
                  },
                };
              },
              filter: 'agSetColumnFilter',
              filterParams: {},
              floatingFilterComponentParams: { suppressFilterButton: false },
            },
          ],
        },
        {
          field: 'gas_supply_type.lib',
          headerName: t(
            'page.units.column.energy_supply_type',
            'Energy supply type',
          ),
          hide: true,
          editable: isAllowed(this.roles.units, true),
          cellEditorSelector: () => {
            return {
              component: 'agRichSelect',
              params: {
                values: this.REF_LABELS.RefGasSupplyType,
              },
            };
          },
          // ! Fix les xxx MH qui n'ont pas le bon type de data, il faut mettre le type objet (vide) /!\

          filter: 'agSetColumnFilter',
          filterParams: {},
          floatingFilterComponentParams: { suppressFilterButton: false },
        },
        {
          field: 'heater_type.lib',
          headerName: t('page.units.column.heater.type', 'Heater type'),
          editable: isAllowed(this.roles.units, true),
          cellEditorSelector: () => {
            return {
              component: 'agRichSelect',
              params: {
                values: this.REF_LABELS.RefHeaterType,
              },
            };
          },
          filter: 'agSetColumnFilter',
          filterParams: {},
          floatingFilterComponentParams: { suppressFilterButton: false },
        },
        {
          field: 'air_conditioning_year',
          headerName: t(
            'page.units.column.air_conditioning_year',
            'Air conditioning year',
          ),
          hide: true,
          editable: isAllowed(this.roles.units, true),
          cellEditorSelector: () => {
            return {
              component: 'agRichSelect',
              params: {
                values: this.generateYearListTD(),
              },
            };
          },

          filter: 'agSetColumnFilter',
          filterParams: {},
          floatingFilterComponentParams: { suppressFilterButton: false },
        },
        {
          field: 'dt_achat',
          headerName: t('page.units.column.purchase_date', 'Purchase date'),
          hide: true,
          editable: isAllowed(this.roles.units, true),

          filter: 'agSetColumnFilter',
          filterParams: {},
          floatingFilterComponentParams: { suppressFilterButton: false },
        },
        {
          field: 'dt_vente',
          headerName: t('page.units.column.sale_date', 'Sale date'),
          hide: true,
          editable: isAllowed(this.roles.units, true),

          filter: 'agSetColumnFilter',
          filterParams: {},
          floatingFilterComponentParams: { suppressFilterButton: false },
        },
        {
          field: 'renov_year',
          headerName: t('page.units.column.renovation_year', 'Renovation year'),
          hide: true,

          filter: 'agSetColumnFilter',
          filterParams: {},
          floatingFilterComponentParams: { suppressFilterButton: false },
        },
        {
          field: 'renov_year_history',
          headerName: t(
            'page.units.column.renovation_year_history',
            'Renovation year history',
          ),
          hide: true,

          filter: 'agSetColumnFilter',
          filterParams: {},
          floatingFilterComponentParams: { suppressFilterButton: false },
        },
        {
          field: 'renov_status.lib',
          headerName: t(
            'page.units.column.renovation_status',
            'Renovation status',
          ),
          hide: true,
          editable: isAllowed(this.roles.units, true),
          cellEditorSelector: () => {
            return {
              component: 'agRichSelect',
              params: {
                values: this.REF_LABELS.RefStatutRenov,
              },
            };
          },

          filter: 'agSetColumnFilter',
          filterParams: {},
          floatingFilterComponentParams: { suppressFilterButton: false },
        },
        {
          field: 'is_renov',
          headerName: t('page.units.column.renovated', 'Renovated'),
          hide: true,

          filter: 'agSetColumnFilter',
          filterParams: {},
          floatingFilterComponentParams: { suppressFilterButton: false },
        },
        {
          field: 'wintering_status.lib',
          headerName: t(
            'page.units.column.wintering_status',
            'Wintering status',
          ),
          hide: true,
          editable: isAllowed(this.roles.units, true),
          cellEditorSelector: () => {
            return {
              component: 'agRichSelect',
              params: {
                values: this.REF_LABELS.RefStatutHivernage,
              },
            };
          },
          // ! Fix les xxx MH qui n'ont pas le bon type de data, il faut mettre le type objet (vide) /!\

          filter: 'agSetColumnFilter',
          filterParams: {},
          floatingFilterComponentParams: { suppressFilterButton: false },
        },
        {
          field: 'purchase_leasing_code',
          headerName: t(
            'page.units.column.purchase_leasing_code',
            'Purchase leasing code',
          ),
          hide: true,
          editable: isAllowed(this.roles.units, true),

          filter: 'agSetColumnFilter',
          filterParams: {},
          floatingFilterComponentParams: { suppressFilterButton: false },
        },
        {
          field: 'reno_leasing_code',
          headerName: t(
            'page.units.column.renovation_leasing_code',
            'Renovation leasing code',
          ),
          hide: true,
          editable: isAllowed(this.roles.units, true),

          filter: 'agSetColumnFilter',
          filterParams: {},
          floatingFilterComponentParams: { suppressFilterButton: false },
        },
        {
          field: 'fixed_asset_code',
          headerName: t(
            'page.units.column.fixed_asset_code',
            'Fixed asset code',
          ),
          hide: true,
          editable: isAllowed(this.roles.units, true),

          filter: 'agSetColumnFilter',
          filterParams: {},
          floatingFilterComponentParams: { suppressFilterButton: false },
        },
        {
          field: 'owner.lib',
          headerName: t('page.units.column.owner', 'Owner'),
          hide: true,
          editable: isAllowed(this.roles.units, true),
          cellEditorSelector: () => {
            return {
              component: 'agRichSelect',
              params: {
                values: this.REF_LABELS.RefOwner,
              },
            };
          },

          filter: 'agSetColumnFilter',
          filterParams: {},
          floatingFilterComponentParams: { suppressFilterButton: false },
        },
        {
          field: 'status.lib',
          headerName: t('page.units.column.status', 'Status'),
          editable: isAllowed(this.roles.units, true),
          cellEditorSelector: () => {
            return {
              component: 'agRichSelect',
              params: {
                values: this.REF_LABELS.RefStatut,
              },
            };
          },

          filter: 'agSetColumnFilter',
          filterParams: {},
          floatingFilterComponentParams: { suppressFilterButton: false },
          cellClass: (params) => {
            const value = params?.value?.toLowerCase();
            return value === 'active'
              ? 'status_active'
              : value === 'ghost'
              ? 'status_ghost'
              : value === 'pending movement'
              ? 'status_pending_movement'
              : value === 'pending confirmation' &&
                'status_pending_confirmation';
          },
        },
        // {
        //   field: 'transfer_id',
        //   headerName: t('page.mh_list.column.transfer_id.length'),

        //   filter: 'agSetColumnFilter',
        //   filterParams: {
        //
        //   },
        //   floatingFilterComponentParams: { suppressFilterButton: false },
        // },
        // {
        //   field: 'is_checked_by_admin',
        //   headerName: t(
        //     'page.mh_list.column.is_checked_by_admin',
        //     'Admin Check',
        //   ),
        //   hide: true,
        //   filter: 'agSetColumnFilter',
        //   filterParams: {
        //
        //   },
        //   floatingFilterComponentParams: { suppressFilterButton: false },
        // },
        // {
        //   field: 'updated_at',
        //   headerName: t('common.label.updated_at'),

        //   filter: 'agSetColumnFilter',
        //   filterParams: {
        //
        //   },
        //   floatingFilterComponentParams: { suppressFilterButton: false },
        // },
        // {
        //   field: 'created_at',
        //   headerName: t('common.label.created_at'),
        //   hide: true,

        //   filter: 'agSetColumnFilter',
        //   filterParams: {
        //
        //   },
        //   floatingFilterComponentParams: { suppressFilterButton: false },
        // },
        {
          field: 'issue_reporting',
          headerName: t(
            'page.units.column.reporting_issues',
            'Reporting issues',
          ),
          filter: 'agSetColumnFilter',
          hide: true,
          filterParams: {},
          floatingFilterComponentParams: { suppressFilterButton: false },
          valueFormatter: (params) => {
            return params.value
              ? t('page.units.cell.issue_reported', 'Issue(s) reported')
              : t('page.units.cell.no_issue', 'All good');
          },
          cellClass: (params) => {
            return params.value === true
              ? 'issue_reporting_true'
              : 'issue_reporting_false';
          },
        },
        {
          field: '_id',
          hide: true,
          filter: 'agSetColumnFilter',
          filterParams: {},
          floatingFilterComponentParams: { suppressFilterButton: false },
        },
      ],
      methods,
      // namespace for permissions.
      'mh',
    );
  }

  //
  // Parameters:
  //   methods: array of object defining the different action methods (See ButtonCellRenderer.js).
  //

  getMVListConfiguration(methods, t) {
    return this.getConfiguration(
      [
        {
          field: 'season',
          headerName: t('page.transfers.column.season', 'Season'),
          editable: isAllowed(this.roles.transfers, true),
          cellEditorSelector: () => {
            return {
              component: 'agRichSelect',
              params: {
                values: this.generateYearListTD(),
              },
            };
          },

          filter: 'agSetColumnFilter',
          filterParams: {},
          floatingFilterComponentParams: { suppressFilterButton: false },
        },
        {
          field: 'transfer_types',
          headerName: t(
            'page.transfers.column.transfer_types.lib',
            'Transfer types',
          ),

          filter: 'agSetColumnFilter',
          filterParams: {},
          floatingFilterComponentParams: { suppressFilterButton: false },
          cellRenderer: 'transferTypesCellRenderer',
          valueGetter: (params) => {
            return params?.data?.transfer_types?.map((type) => type.lib);
          },
        },
        {
          // Dernormalized field, the real field is in mobilhome_id.barcode, but not filterable.
          field: 'barcode',
          headerName: t('page.transfers.column.barcode', 'Barcode'),

          filter: 'agSetColumnFilter',
          filterParams: {},
          floatingFilterComponentParams: { suppressFilterButton: false },
          cellStyle: { 'font-weight': 600 },
        },
        {
          // Dernormalized field, the real field is in mobilhome_id.barcode, but not filterable.
          field: 'id_chassis',
          headerName: t('page.transfers.column.chassis_id', 'Chassis ID'),
          hide: true,

          filter: 'agSetColumnFilter',
          filterParams: {},
          floatingFilterComponentParams: { suppressFilterButton: false },
          cellClass: 'stringType',
          cellStyle: { 'font-weight': 600 },
        },
        // {
        //   field: 'mobilhome_id.mh_model.manufacturer',
        //   headerName: t('page.transfers.column.mobilhome_id.constructeur.lib'),
        //   refData: 'RefConstructeur',
        //   customFilter: false,
        // },
        // {
        //   field: 'mobilhome_id.mh_model.manufacturer_model',
        //   headerName: t('page.transfers.column.mobilhome_id.modele_constructeur.lib'),
        //   hide: true,
        //   refData: 'RefModeleConstructeur',
        //   customFilter: false,
        // },
        // {
        //   field: 'mobilhome_id.mh_model.vintage',
        //   headerName: t('page.transfers.column.mobilhome_id.vintage'),
        //   hide: true,
        //   enableRowGroup: true,
        //   customFilter: false,
        // },
        {
          headerName: t(
            'page.transfers.column.transfer.unit_model',
            'Unit model',
          ),
          headerClass: 'groupColumnHeader',
          children: [
            // {
            //   field: 'mobilhome_id.mh_model.lib',
            //   headerName: t('page.transfers.column.mh_model.lib'),
            //   filter: 'agSetColumnFilter',
            //   filterParams: {
            //
            //   },
            //   floatingFilterComponentParams: { suppressFilterButton: false },
            // },
            // {
            //   field: 'mobilhome_id.mh_model.length',
            //   headerName: t('page.transfers.column.mh_model.mh_length'),
            //   columnGroupShow: 'open',
            //   filter: 'agSetColumnFilter',
            //   filterParams: {
            //
            //   },
            //   floatingFilterComponentParams: { suppressFilterButton: false },
            // },
            // {
            //   field: 'mobilhome_id.mh_model.width',
            //   headerName: t('page.transfers.column.mh_model.mh_width'),
            //   columnGroupShow: 'open',
            //   filter: 'agSetColumnFilter',
            //   filterParams: {
            //
            //   },
            //   floatingFilterComponentParams: { suppressFilterButton: false },
            // },
          ],
        },
        {
          headerName: t(
            'page.transfers.column.transfer.status.title',
            'Status',
          ),
          headerClass: 'groupColumnHeader',
          children: [
            {
              field: 'transfer_status.lib',
              headerName: t(
                'page.transfers.column.transfer_status.lib',
                'Label',
              ),
              columnGroupShow: undefined,

              filter: 'agSetColumnFilter',
              filterParams: {},
              floatingFilterComponentParams: { suppressFilterButton: false },
              cellRenderer: 'tagCellRenderer',
            },
            {
              field: 'status_physical_amend.lib',
              headerName: t(
                'page.transfers.column.physical_amend_status',
                'Physical amendment status',
              ),
              columnGroupShow: 'open',
              editable: isAllowed(this.roles.transfers, true),
              cellEditorSelector: () => {
                return {
                  component: 'agRichSelect',
                  params: {
                    values: this.REF_LABELS.RefStatusPhyAmend,
                  },
                };
              },

              filter: 'agSetColumnFilter',
              filterParams: {},
              floatingFilterComponentParams: { suppressFilterButton: false },
            },
            {
              field: 'status_comm_amend.lib',
              headerName: t(
                'page.transfers.column.commercial_amend_status',
                'Commercial amendment status',
              ),
              columnGroupShow: 'open',
              editable: isAllowed(this.roles.transfers, true),
              cellEditorSelector: () => {
                return {
                  component: 'agRichSelect',
                  params: {
                    values: this.REF_LABELS.RefStatusCommAmend,
                  },
                };
              },

              filter: 'agSetColumnFilter',
              filterParams: {},
              floatingFilterComponentParams: { suppressFilterButton: false },
            },
            {
              field: 'status_refurb.lib',
              headerName: t(
                'page.transfers.column.refurbishment_status',
                'Refurbishment status',
              ),
              columnGroupShow: 'open',
              editable: isAllowed(this.roles.transfers, true),
              cellEditorSelector: () => {
                return {
                  component: 'agRichSelect',
                  params: {
                    values: this.REF_LABELS.RefStatusRefurbAmend,
                  },
                };
              },

              filter: 'agSetColumnFilter',
              filterParams: {},
              floatingFilterComponentParams: { suppressFilterButton: false },
            },
            {
              field: 'status_deck_replacement.lib',
              headerName: t(
                'page.transfers.column.deck_replacement_status',
                'Deck replacement status',
              ),
              columnGroupShow: 'open',
              editable: isAllowed(this.roles.transfers, true),
              cellEditorSelector: () => {
                return {
                  component: 'agRichSelect',
                  params: {
                    values: this.REF_LABELS.RefStatusDeckReplacement,
                  },
                };
              },

              filter: 'agSetColumnFilter',
              filterParams: {},
              floatingFilterComponentParams: { suppressFilterButton: false },
            },
          ],
        },

        {
          field: 'comment',
          headerName: t('page.transfers.column.comment', 'Comment'),
          hide: true,
          editable: isAllowed(this.roles.transfers, true),
          cellEditor: 'agLargeTextCellEditor',
          cellEditorParams: {
            maxLength: '300', // override the editor defaults
            cols: '50',
            rows: '5',
          },
          tooltipField: 'comment',

          filter: 'agSetColumnFilter',
          filterParams: {},
          floatingFilterComponentParams: { suppressFilterButton: false },
        },
        {
          headerName: t(
            'page.transfers.column.campsite_from.lib',
            'Campsite from',
          ),
          headerClass: 'groupColumnHeader',
          children: [
            {
              field: 'site_from.lib',
              headerName: t(
                'page.transfers.column.campsite_from.name',
                'Campsite name',
              ),
              cellClass: 'siteFrom',

              filter: 'agSetColumnFilter',
              filterParams: {},
              floatingFilterComponentParams: { suppressFilterButton: false },
            },
            {
              field: 'site_from.loca.country',
              headerName: t(
                'page.transfers.column.campsite_from.loca.country',
                'Country',
              ),
              columnGroupShow: 'open',
              // hide: true,
              cellClass: 'siteFrom',

              filter: 'agSetColumnFilter',
              filterParams: {},
              floatingFilterComponentParams: { suppressFilterButton: false },
            },
            {
              field: 'site_from.loca.region',
              headerName: t(
                'page.transfers.column.campsite_from.loca.region',
                'Region',
              ),
              columnGroupShow: 'open',
              // hide: true,
              cellClass: 'siteFrom',

              filter: 'agSetColumnFilter',
              filterParams: {},
              floatingFilterComponentParams: { suppressFilterButton: false },
            },
            {
              field: 'site_from.loca.city',
              headerName: t(
                'page.transfers.column.campsite_from.loca.city',
                'City',
              ),
              columnGroupShow: 'open',
              // hide: true,
              cellClass: 'siteFrom',

              filter: 'agSetColumnFilter',
              filterParams: {},
              floatingFilterComponentParams: { suppressFilterButton: false },
            },
            // {
            //   field: 'site_from.zip_code',
            //   headerName: t('page.transfers.column.site_from.zip_code'),
            //   columnGroupShow: 'open',
            //   // hide: true,
            //   cellClass: 'siteFrom',

            //   filter: 'agSetColumnFilter',
            //   filterParams: {
            //
            //   },
            //   floatingFilterComponentParams: { suppressFilterButton: false },
            // },
            // {
            //   field: 'site_from.closing_date.lib',
            //   headerName: t('page.transfers.column.site_from.closing_date'),
            //   columnGroupShow: 'open',
            //   cellClass: 'siteFrom',

            //   filter: 'agSetColumnFilter',
            //   filterParams: {
            //
            //   },
            //   floatingFilterComponentParams: { suppressFilterButton: false },
            // },
            {
              field: 'site_from.code_group',
              headerName: t(
                'page.transfers.column.campsite_from.group_code',
                'Group code',
              ),
              // hide: true,
              cellClass: 'siteFrom',

              filter: 'agSetColumnFilter',
              filterParams: {},
              floatingFilterComponentParams: { suppressFilterButton: false },
              cellStyle: { 'font-weight': 600 },
            },
            {
              field: 'commercial_brand_from.lib',
              headerName: t(
                'page.transfers.column.commercial_brand_from.lib',
                'Commercial brand',
              ),
              // columnGroupShow: 'open',
              // hide: true,
              cellClass: 'siteFrom',
              filter: 'agSetColumnFilter',
              filterParams: {},
              floatingFilterComponentParams: { suppressFilterButton: false },
              cellRenderer: 'flagCellRenderer',
            },
            {
              field: 'use_type_from.lib',
              headerName: t(
                'page.transfers.column.use_type_from.lib',
                'Use type',
              ),
              cellClass: 'siteFrom',
              filter: 'agSetColumnFilter',
              filterParams: {},
              floatingFilterComponentParams: { suppressFilterButton: false },
              cellRenderer: 'tagCellRenderer',
            },
            {
              field: 'pitch_from',
              headerName: t('page.transfers.column.pitch_from', 'Pitch'),
              // columnGroupShow: 'open',
              // hide: true,
              cellClass: 'stringType',

              filter: 'agSetColumnFilter',
              filterParams: {},
              floatingFilterComponentParams: { suppressFilterButton: false },
            },
            {
              field: 'deck_type_from.lib',
              headerName: t(
                'page.transfers.column.deck_type_from',
                'Deck type',
              ),
              columnGroupShow: 'open',
              // hide: true,
              cellClass: 'siteFrom',

              filter: 'agSetColumnFilter',
              filterParams: {},
              floatingFilterComponentParams: { suppressFilterButton: false },
            },
            {
              field: 'deck_size_from.lib',
              headerName: t(
                'page.transfers.column.deck_size_from',
                'Deck size',
              ),
              columnGroupShow: 'open',
              // hide: true,
              cellClass: 'siteFrom',

              filter: 'agSetColumnFilter',
              filterParams: {},
              floatingFilterComponentParams: { suppressFilterButton: false },
            },
            {
              field: 'deck_manufacturer_from.lib',
              headerName: t(
                'page.transfers.column.deck_manufacturer_from',
                'Deck manufacturer',
              ),
              columnGroupShow: 'open',
              // hide: true,
              cellClass: 'siteFrom',

              filter: 'agSetColumnFilter',
              filterParams: {},
              floatingFilterComponentParams: { suppressFilterButton: false },
            },
            {
              field: 'cladding_color_from.lib',
              headerName: t(
                'page.transfers.column.cladding_color_from',
                'Cladding color',
              ),
              columnGroupShow: 'open',
              // hide: true,
              cellClass: 'siteFrom',

              filter: 'agSetColumnFilter',
              filterParams: {},
              floatingFilterComponentParams: { suppressFilterButton: false },
            },
            {
              field: 'commercial_type_from.lib',
              headerName: t(
                'page.transfers.column.commercial_type_from.lib',
                'Commercial type',
              ),
              cellClass: 'siteFrom',
              columnGroupShow: 'open',

              filter: 'agSetColumnFilter',
              filterParams: {},
              floatingFilterComponentParams: { suppressFilterButton: false },
            },
            {
              field: 'commercial_type_from.code',
              headerName: t(
                'page.transfers.column.commercial_type_from.code',
                'Commercial type code',
              ),
              cellClass: 'siteFrom',
              columnGroupShow: 'open',
              editable: false,

              filter: 'agSetColumnFilter',
              filterParams: {},
              floatingFilterComponentParams: { suppressFilterButton: false },
            },
            {
              field: 'commercial_type_from.parent_lib',
              headerName: t(
                'page.transfers.column.commercial_type_from.parent_lib',
                'Commercial type parent',
              ),
              columnGroupShow: 'open',
              // hide: true,
              cellClass: 'siteFrom',

              filter: 'agSetColumnFilter',
              filterParams: {},
              floatingFilterComponentParams: { suppressFilterButton: false },
            },
            {
              field: 'commercial_type_from.group',
              headerName: t(
                'page.transfers.column.commercial_type_from.group',
                'Commercial type group',
              ),
              columnGroupShow: 'open',
              // hide: true,
              cellClass: 'siteFrom',

              filter: 'agSetColumnFilter',
              filterParams: {},
              floatingFilterComponentParams: { suppressFilterButton: false },
            },
            // {
            //   field: 'commercial_type_from.is_terrasse',
            //   headerName: t('page.transfers.column.type_commercial_from.is_terrasse'),
            //   columnGroupShow: 'open',
            //   // hide: true,
            //   cellClass: 'siteFrom',
            // },
            // {
            //   field: 'commercial_type_from.is_clim',
            //   headerName: t('page.transfers.column.updated_at'),
            //   columnGroupShow: 'open',
            //   // hide: true,
            //   cellClass: 'siteFrom',
            // },
            // {
            //   field: 'commercial_type_from.is_empl_premium',
            //   headerName: t('page.transfers.column.type_commercial_from.is_empl_premium'),
            //   columnGroupShow: 'open',
            //   // hide: true,
            //   cellClass: 'siteFrom',
            // },
            // {
            //   field: 'gas_supply_type_from.lib',
            //   headerName: t('page.transfers.column.gas_supply_type_from'),
            //   columnGroupShow: 'open',
            //   cellClass: 'siteFrom',

            //   filter: 'agSetColumnFilter',
            //   filterParams: {
            //
            //   },
            //   floatingFilterComponentParams: { suppressFilterButton: false },
            // },
            // {
            //   field: 'commercial_type_from.is_clim',
            //   headerName: t(
            //     'page.transfers.column.type_commercial_from.is_clim',
            //   ),
            //   columnGroupShow: 'open',
            //   cellClass: 'siteFrom',

            //   filter: 'agSetColumnFilter',
            //   filterParams: {
            //
            //   },
            //   floatingFilterComponentParams: { suppressFilterButton: false },
            // },
            // {
            //   field: 'site_from.is_propre',
            //   headerName: t('page.transfers.column.site_from.is_propre'),
            //   columnGroupShow: 'open',
            //   cellClass: 'siteFrom',

            //   filter: 'agSetColumnFilter',
            //   filterParams: {
            //
            //   },
            //   floatingFilterComponentParams: { suppressFilterButton: false },
            // },
          ],
        },
        {
          headerName: t('page.transfers.column.campsite_to.lib', 'Campsite to'),
          headerClass: 'groupColumnHeader',
          children: [
            {
              field: 'site_to.lib',
              headerName: t('page.transfers.column.campsite_to.name'),
              editable: isAllowed(this.roles.transfers, true),
              cellClass: 'siteTo',
              cellEditorSelector: () => {
                return {
                  component: 'agRichSelect',
                  params: {
                    values: this.REF_LABELS.Site,
                  },
                };
              },

              filter: 'agSetColumnFilter',
              filterParams: {},
              floatingFilterComponentParams: { suppressFilterButton: false },
            },
            {
              field: 'site_to.loca.country',
              headerName: t(
                'page.transfers.column.campsite_to.loca.country',
                'Country',
              ),
              columnGroupShow: 'open',
              // hide: true,
              cellClass: 'siteTo',

              filter: 'agSetColumnFilter',
              filterParams: {},
              floatingFilterComponentParams: { suppressFilterButton: false },
            },
            {
              field: 'site_to.loca.region',
              headerName: t(
                'page.transfers.column.campsite_to.loca.region',
                'Region',
              ),
              columnGroupShow: 'open',
              // hide: true,
              cellClass: 'siteTo',

              filter: 'agSetColumnFilter',
              filterParams: {},
              floatingFilterComponentParams: { suppressFilterButton: false },
            },
            {
              field: 'site_to.loca.city',
              headerName: t(
                'page.transfers.column.campsite_to.loca.city',
                'City',
              ),
              columnGroupShow: 'open',
              // hide: true,
              cellClass: 'siteTo',

              filter: 'agSetColumnFilter',
              filterParams: {},
              floatingFilterComponentParams: { suppressFilterButton: false },
            },
            // {
            //   field: 'site_to.zip_code',
            //   headerName: t('page.transfers.column.site_to.zip_code'),
            //   columnGroupShow: 'open',
            //   cellClass: 'siteTo',

            //   filter: 'agSetColumnFilter',
            //   filterParams: {
            //
            //   },
            //   floatingFilterComponentParams: { suppressFilterButton: false },
            // },
            // {
            //   field: 'site_to.opening_date.lib',
            //   headerName: t('page.transfers.column.site_to.opening_date'),
            //   columnGroupShow: 'open',
            //   // hide: true,
            //   cellClass: 'siteTo',

            //   filter: 'agSetColumnFilter',
            //   filterParams: {
            //
            //   },
            //   floatingFilterComponentParams: { suppressFilterButton: false },
            // },
            // {
            //   field: 'opening_brand.lib',
            //   headerName: t('page.transfers.column.opening_brand'),
            //   columnGroupShow: 'open',
            //   editable: isAllowed(this.roles.transfers, true),
            //   // hide: true,
            //   cellClass: 'siteTo',
            //   cellEditorSelector: () => {
            //     return {
            //       component: 'agRichSelect',
            //       params: {
            //         values: this.generateRefWeek(),
            //       },
            //     };
            //   },

            //   filter: 'agSetColumnFilter',
            //   filterParams: {
            //
            //   },
            //   floatingFilterComponentParams: { suppressFilterButton: false },
            // },
            // {
            //   field: 'site_to.rmt_region.rmt_name',
            //   headerName: t(
            //     'page.transfers.column.site_to.rmt_region.rmt_name',
            //   ),
            //   columnGroupShow: 'open',
            //   // editable: true,
            //   // hide: true,
            //   cellClass: 'siteTo',

            //   filter: 'agSetColumnFilter',
            //   filterParams: {
            //
            //   },
            //   floatingFilterComponentParams: { suppressFilterButton: false },
            // },
            // {
            //   field: 'site_to.rmt_region.senior_rmt_email',
            //   headerName: t(
            //     'page.transfers.column.site_to.rmt_region.senior_rmt_email',
            //   ),
            //   columnGroupShow: 'open',
            //   // editable: true,
            //   // hide: true,
            //   cellClass: 'siteTo',

            //   filter: 'agSetColumnFilter',
            //   filterParams: {
            //
            //   },
            //   floatingFilterComponentParams: { suppressFilterButton: false },
            // },
            {
              field: 'site_to.code_group',
              headerName: t(
                'page.transfers.column.campsite_to.group_code',
                'Group code',
              ),
              // hide: true,
              cellClass: 'siteTo',

              filter: 'agSetColumnFilter',
              filterParams: {},
              floatingFilterComponentParams: { suppressFilterButton: false },
              cellStyle: { 'font-weight': 600 },
            },
            {
              field: 'commercial_brand_to.lib',
              headerName: t(
                'page.transfers.column.commercial_brand_to.lib',
                'Commercial brand',
              ),
              // columnGroupShow: 'open',
              // hide: true,
              cellClass: 'siteTo',
              editable: isAllowed(this.roles.transfers, true),
              cellEditorSelector: () => {
                return {
                  component: 'agRichSelect',
                  params: {
                    values: this.REF_LABELS.RefMarqueCommerciale,
                  },
                };
              },
              filter: 'agSetColumnFilter',
              filterParams: {},
              floatingFilterComponentParams: { suppressFilterButton: false },
              cellRenderer: 'flagCellRenderer',
            },
            {
              field: 'commercial_type_to.lib',
              headerName: t(
                'page.transfers.column.commercial_type_to.lib',
                'Commercial type',
              ),
              columnGroupShow: 'open',
              // hide: true,
              cellClass: 'siteTo',
              editable: isAllowed(this.roles.transfers, true),
              cellEditorSelector: () => {
                return {
                  component: 'agRichSelect',
                  params: {
                    values: this.REF_LABELS.RefTypeCommercial,
                  },
                };
              },

              filter: 'agSetColumnFilter',
              filterParams: {},
              floatingFilterComponentParams: { suppressFilterButton: false },
            },
            {
              field: 'commercial_type_to.code',
              headerName: t(
                'page.transfers.column.commercial_type_to.code',
                'Commercial type code',
              ),
              cellClass: 'siteTo',
              columnGroupShow: 'open',
              editable: false,

              filter: 'agSetColumnFilter',
              filterParams: {},
              floatingFilterComponentParams: { suppressFilterButton: false },
            },
            {
              field: 'commercial_type_to.parent_lib',
              headerName: t(
                'page.transfers.column.commercial_type_to.parent_lib',
                'Parent',
              ),
              columnGroupShow: 'open',
              // hide: true,
              cellClass: 'siteTo',

              filter: 'agSetColumnFilter',
              filterParams: {},
              floatingFilterComponentParams: { suppressFilterButton: false },
            },
            {
              field: 'commercial_type_to.group',
              headerName: t(
                'page.transfers.column.commercial_type_to.group',
                'Commercial type group',
              ),
              columnGroupShow: 'open',
              // hide: true,
              cellClass: 'siteTo',

              filter: 'agSetColumnFilter',
              filterParams: {},
              floatingFilterComponentParams: { suppressFilterButton: false },
            },
            // {
            //   field: 'commercial_type_to.is_terrasse',
            //   headerName: t('page.transfers.column.type_commercial_to.is_terrasse'),
            //   columnGroupShow: 'open',
            //   // hide: true,
            //   cellClass: 'siteTo',
            // },
            // {
            //   field: 'commercial_type_to.is_clim',
            //   headerName: t('page.transfers.column.type_commercial_to.is_clim'),
            //   columnGroupShow: 'open',
            //   // hide: true,
            //   cellClass: 'siteTo',
            //   editable: isAllowed(this.roles.transfers, true),
            //   cellEditorSelector: () => {
            //     return {
            //       component: 'agRichSelect',
            //       params: {
            //         values: ['true', 'false'],
            //       },
            //     };
            //   },

            //   filter: 'agSetColumnFilter',
            //   filterParams: {
            //
            //   },
            //   floatingFilterComponentParams: { suppressFilterButton: false },
            // },
            // {
            //   field: 'commercial_type_to.is_empl_premium',
            //   headerName: t('page.transfers.column.type_commercial_to.is_empl_premium'),
            //   columnGroupShow: 'open',
            //   // hide: true,
            //   cellClass: 'siteTo',
            // },
            {
              field: 'use_type_to.lib',
              headerName: t(
                'page.transfers.column.use_type_to.lib',
                'Use type',
              ),
              // columnGroupShow: 'open',
              // hide: true,
              cellClass: 'siteTo',
              editable: isAllowed(this.roles.transfers, true),
              cellEditorSelector: () => {
                return {
                  component: 'agRichSelect',
                  params: {
                    values: this.REF_LABELS.RefTypeUtilisation,
                  },
                };
              },

              filter: 'agSetColumnFilter',
              filterParams: {},
              floatingFilterComponentParams: { suppressFilterButton: false },
              cellRenderer: 'tagCellRenderer',
            },
            {
              field: 'pitch_to',
              headerName: t('page.transfers.column.pitch_to', 'Pitch'),
              // columnGroupShow: 'open',
              // hide: true,
              cellClass: 'stringType',
              editable: isAllowed(this.roles.transfers, true),

              filter: 'agSetColumnFilter',
              filterParams: {},
              floatingFilterComponentParams: { suppressFilterButton: false },
            },
            {
              field: 'deck_type_to.lib',
              headerName: t('page.transfers.column.deck_type_to', 'Deck type'),
              columnGroupShow: 'open',
              // hide: true,
              cellClass: 'siteTo',
              editable: isAllowed(this.roles.transfers, true),
              cellEditorSelector: () => {
                return {
                  component: 'agRichSelect',
                  params: {
                    values: this.REF_LABELS.RefTypeTerrasse,
                  },
                };
              },

              filter: 'agSetColumnFilter',
              filterParams: {},
              floatingFilterComponentParams: { suppressFilterButton: false },
            },
            {
              field: 'deck_size_to.lib',
              headerName: t('page.transfers.column.deck_size_to', 'Deck size'),
              columnGroupShow: 'open',
              // hide: true,
              cellClass: 'siteTo',
              editable: isAllowed(this.roles.transfers, true),
              cellEditorSelector: () => {
                return {
                  component: 'agRichSelect',
                  params: {
                    values: this.REF_LABELS.RefTailleTerrasse,
                  },
                };
              },

              filter: 'agSetColumnFilter',
              filterParams: {},
              floatingFilterComponentParams: { suppressFilterButton: false },
            },
            {
              field: 'deck_manufacturer_to.lib',
              headerName: t(
                'page.transfers.column.deck_manufacturer_to',
                'Deck manufacturer to',
              ),
              columnGroupShow: 'open',
              // hide: true,
              cellClass: 'siteTo',
              editable: isAllowed(this.roles.transfers, true),
              cellEditorSelector: () => {
                return {
                  component: 'agRichSelect',
                  params: {
                    values: this.REF_LABELS.RefDeckManufacturer,
                  },
                };
              },

              filter: 'agSetColumnFilter',
              filterParams: {},
              floatingFilterComponentParams: { suppressFilterButton: false },
            },
            {
              field: 'cladding_color_to.lib',
              headerName: t(
                'page.transfers.column.cladding_color_to',
                'Cladding color',
              ),
              columnGroupShow: 'open',
              // hide: true,
              cellClass: 'siteTo',
              editable: isAllowed(this.roles.transfers, true),
              cellEditorSelector: () => {
                return {
                  component: 'agRichSelect',
                  params: {
                    values: this.REF_LABELS.RefCladdingColor,
                  },
                };
              },

              filter: 'agSetColumnFilter',
              filterParams: {},
              floatingFilterComponentParams: { suppressFilterButton: false },
            },
            // {
            //   field: 'gas_supply_type_to.lib',
            //   headerName: t('page.transfers.column.gas_supply_type_to'),
            //   columnGroupShow: 'open',
            //   cellClass: 'siteTo',
            //   editable: isAllowed(this.roles.transfers, true),
            //   cellEditorSelector: () => {
            //     return {
            //       component: 'agRichSelect',
            //       params: {
            //         values: this.REF_LABELS.RefGasSupplyType,
            //       },
            //     };
            //   },

            //   filter: 'agSetColumnFilter',
            //   filterParams: {
            //
            //   },
            //   floatingFilterComponentParams: { suppressFilterButton: false },
            // },
            // {
            //   field: 'site_to.is_propre',
            //   headerName: t('page.transfers.column.site_to.is_propre'),
            //   columnGroupShow: 'open',
            //   cellClass: 'siteTo',

            //   filter: 'agSetColumnFilter',
            //   filterParams: {
            //
            //   },
            //   floatingFilterComponentParams: { suppressFilterButton: false },
            // },
            {
              field: 'pitch_target',
              headerName: t(
                'page.transfers.column.pitch_target',
                'Pitch target',
              ),
              columnGroupShow: 'open',
              cellClass: 'stringType',
              editable: isAllowed(this.roles.transfers, true),

              filter: 'agSetColumnFilter',
              filterParams: {},
              floatingFilterComponentParams: { suppressFilterButton: false },
            },
          ],
        },

        {
          field: 'refurb_items.lib',
          headerName: t(
            'page.transfers.column.refurb_items.lib',
            'Refurbishment items',
          ),
          valueGetter: this.getLibFromRefurbItems,

          // TODO:
          filter: 'agSetColumnFilter',
          filterParams: {},
          floatingFilterComponentParams: { suppressFilterButton: false },
        },

        // Simon Team column...
        // pitch_to_availability
        {
          field: 'pitch_to_availability.lib',
          headerName: t(
            'page.transfers.column.pitch_to_availability',
            'Pitch to availability',
          ),
          hide: true,
          editable: isAllowed(this.roles.transfers, true),
          cellEditorSelector: () => {
            return {
              component: 'agRichSelect',
              params: {
                values: this.generateRefWeek(),
              },
            };
          },

          filter: 'agSetColumnFilter',
          filterParams: {},
          floatingFilterComponentParams: { suppressFilterButton: false },
        },
        {
          field: 'deck_year',
          headerName: t('page.transfers.column.deck_year', 'Deck year'),
          hide: true,
          // editable: true,

          filter: 'agSetColumnFilter',
          filterParams: {},
          floatingFilterComponentParams: { suppressFilterButton: false },
        },
        {
          field: 'go_realistic.lib',
          headerName: t('page.transfers.column.go_realistic', 'Go realistic'),
          hide: true,
          editable: isAllowed(this.roles.transfers, true),
          cellEditorSelector: () => {
            return {
              component: 'agRichSelect',
              params: {
                values: this.generateRefWeek(),
              },
            };
          },

          filter: 'agSetColumnFilter',
          filterParams: {},
          floatingFilterComponentParams: { suppressFilterButton: false },
        },
        {
          field: 'deck_state_comment',
          headerName: t(
            'page.transfers.column.deck_state_comment',
            'Deck state comment',
          ),
          hide: true,
          editable: isAllowed(this.roles.transfers, true),

          filter: 'agSetColumnFilter',
          filterParams: {},
          floatingFilterComponentParams: { suppressFilterButton: false },
        },
        {
          field: 'pitch_to_constraints',
          headerName: t(
            'page.transfers.column.pitch_to_constraints',
            'Pitch to constraints',
          ),
          hide: true,
          editable: isAllowed(this.roles.transfers, true),

          filter: 'agSetColumnFilter',
          filterParams: {},
          floatingFilterComponentParams: { suppressFilterButton: false },
        },
        {
          field: 'exit_week_dead_line.lib',
          headerName: t(
            'page.transfers.column.exit_week_deadline',
            'Exit week deadline',
          ),
          hide: true,
          editable: isAllowed(this.roles.transfers, true),
          cellEditorSelector: () => {
            return {
              component: 'agRichSelect',
              params: {
                values: this.generateRefWeek(),
              },
            };
          },

          filter: 'agSetColumnFilter',
          filterParams: {},
          floatingFilterComponentParams: { suppressFilterButton: false },
        },
        {
          field: 'accomodation_ready_at_risk',
          headerName: t(
            'page.transfers.column.accomodation_ready_at_risk',
            'Accomodation ready at risk',
          ),
          hide: true,
          cellStyle: this.getRiskColor,

          filter: 'agSetColumnFilter',
          filterParams: {},
          floatingFilterComponentParams: { suppressFilterButton: false },
        },
        {
          field: 'delivered_before_opening_week',
          headerName: t(
            'page.transfers.column.delivered_before_opening_week',
            'Delivered before opening week',
          ),
          hide: true,

          filter: 'agSetColumnFilter',
          filterParams: {},
          floatingFilterComponentParams: { suppressFilterButton: false },
        },
        {
          field: 'sold_to',
          headerName: t('page.transfers.column.sold_to', 'Sold to'),
          hide: true,
          editable: isAllowed(this.roles.transfers, true),

          filter: 'agSetColumnFilter',
          filterParams: {},
          floatingFilterComponentParams: { suppressFilterButton: false },
        },
        {
          field: 'cancel_and_duplicate',
          headerName: t(
            'page.transfers.column.cancel_and_duplicate',
            'Cancel and duplicate',
          ),
          hide: true,

          filter: 'agSetColumnFilter',
          filterParams: {},
          floatingFilterComponentParams: { suppressFilterButton: false },
        },
        {
          field: 'carpark_info',
          headerName: t('page.transfers.column.carpark_info', 'Car park info'),
          hide: true,
          editable: isAllowed(this.roles.transfers, true),

          filter: 'agSetColumnFilter',
          filterParams: {},
          floatingFilterComponentParams: { suppressFilterButton: false },
        },
        {
          field: 'site_avail',
          headerName: t(
            'page.transfers.column.campsite_avail',
            'Campsite avail',
          ),
          hide: true,
          editable: isAllowed(this.roles.transfers, true),

          filter: 'agSetColumnFilter',
          filterParams: {},
          floatingFilterComponentParams: { suppressFilterButton: false },
        },
        {
          field: 'safety_com_schedule.lib',
          headerName: t(
            'page.transfers.column.safety_com_schedule',
            'Safety commissioning schedule',
          ),
          hide: true,
          editable: isAllowed(this.roles.transfers, true),
          cellEditorSelector: () => {
            return {
              component: 'agRichSelect',
              params: {
                values: this.generateRefWeek(),
              },
            };
          },

          filter: 'agSetColumnFilter',
          filterParams: {},
          floatingFilterComponentParams: { suppressFilterButton: false },
        },
        {
          field: 'accomodation_ready.lib',
          headerName: t(
            'page.transfers.column.accomodation_ready',
            'Accomodation ready',
          ),
          editable: isAllowed(this.roles.transfers, true),
          cellEditorSelector: () => {
            return {
              component: 'agRichSelect',
              params: {
                values: this.generateRefWeek(),
              },
            };
          },

          filter: 'agSetColumnFilter',
          filterParams: {},
          floatingFilterComponentParams: { suppressFilterButton: false },
        },
        {
          field: 'contract_manager_to.lib',
          headerName: t(
            'page.transfers.column.contract_manager_to',
            'Contract manager',
          ),
          editable: isAllowed(this.roles.transfers, true),
          cellEditorSelector: () => {
            return {
              component: 'agRichSelect',
              params: {
                values: this.REF_LABELS.RefContractManager,
              },
            };
          },

          filter: 'agSetColumnFilter',
          filterParams: {},
          floatingFilterComponentParams: { suppressFilterButton: false },
        },
        {
          headerName: t(
            'page.transfers.column.connections.aircon.disconn.name',
            'Connections AC disconn',
          ),
          headerClass: 'groupColumnHeader',
          children: [
            {
              field: 'connections.aircon.disconn.week.lib',
              headerName: t(
                'page.transfers.column.connections.aircon.disconn.week',
                'Week',
              ),
              hide: true,
              editable: isAllowed(this.roles.transfers, true),
              cellStyle: this.getLogisticsColorStatus,
              cellEditorSelector: () => {
                return {
                  component: 'agRichSelect',
                  params: {
                    values: this.generateRefWeek(),
                  },
                };
              },

              filter: 'agSetColumnFilter',
              filterParams: {},
              floatingFilterComponentParams: { suppressFilterButton: false },
            },
            {
              field: 'connections.aircon.disconn.status.lib',
              headerName: t(
                'page.transfers.column.connections.aircon.disconn.status',
                'Status',
              ),
              columnGroupShow: 'open',
              hide: true,
              editable: isAllowed(this.roles.transfers, true),
              cellStyle: this.getLogisticsColorStatus,
              // refData: 'RefConnectionStatus',
              cellEditorSelector: () => {
                return {
                  component: 'agRichSelect',
                  params: {
                    values: this.REF_LABELS.RefConnectionStatus,
                  },
                };
              },

              // TODO:
              filter: 'agSetColumnFilter',
              filterParams: {},
              floatingFilterComponentParams: { suppressFilterButton: false },
            },
            {
              field: 'connections.aircon.disconn.week_confirmed.lib',
              headerName: t(
                'page.transfers.column.connections.aircon.disconn.week_confirmed',
                'Week confirmed',
              ),
              columnGroupShow: 'open',
              hide: true,
              editable: isAllowed(this.roles.transfers, true),
              cellStyle: this.getLogisticsColorStatus,
              cellEditorSelector: () => {
                return {
                  component: 'agRichSelect',
                  params: {
                    values: this.generateRefWeek(),
                  },
                };
              },

              filter: 'agSetColumnFilter',
              filterParams: {},
              floatingFilterComponentParams: { suppressFilterButton: false },
            },
            {
              field: 'connections.aircon.disconn.subcontractor.lib',
              headerName: t(
                'page.transfers.column.connections.aircon.disconn.subcontractor',
                'Subcontractor',
              ),
              columnGroupShow: 'open',
              hide: true,
              editable: isAllowed(this.roles.transfers, true),
              // refData: 'RefSubcontractor',
              cellStyle: this.getLogisticsColorStatus,

              // TODO:
              filter: 'agSetColumnFilter',
              filterParams: {},
              floatingFilterComponentParams: { suppressFilterButton: false },
            },
            {
              field: 'connections.aircon.disconn.cost',
              headerName: t(
                'page.transfers.column.connections.aircon.disconn.cost',
                'Cost',
              ),
              columnGroupShow: 'open',
              hide: true,
              editable: isAllowed(this.roles.transfers, true),
              valueGetter: this.getCostValue,
              cellStyle: this.getLogisticsColorStatus,

              // TODO:
              filter: 'agSetColumnFilter',
              filterParams: {},
              floatingFilterComponentParams: { suppressFilterButton: false },
            },
            {
              field: 'connections.aircon.disconn.purchase_order',
              headerName: t(
                'page.transfers.column.connections.aircon.disconn.purchase_order',
                'Purchase order',
              ),
              columnGroupShow: 'open',
              hide: true,
              editable: isAllowed(this.roles.transfers, true),
              cellStyle: this.getLogisticsColorStatus,

              // TODO:
              filter: 'agSetColumnFilter',
              filterParams: {},
              floatingFilterComponentParams: { suppressFilterButton: false },
            },
          ],
        },
        {
          headerName: t(
            'page.transfers.column.connections.deck.disconn.name',
            'Connection deck disconn',
          ),
          headerClass: 'groupColumnHeader',
          children: [
            {
              field: 'connections.deck.disconn.week.lib',
              headerName: t(
                'page.transfers.column.connections.deck.disconn.week',
                'Week',
              ),
              hide: true,
              editable: isAllowed(this.roles.transfers, true),
              cellStyle: this.getLogisticsColorStatus,
              cellEditorSelector: () => {
                return {
                  component: 'agRichSelect',
                  params: {
                    values: this.generateRefWeek(),
                  },
                };
              },

              filter: 'agSetColumnFilter',
              filterParams: {},
              floatingFilterComponentParams: { suppressFilterButton: false },
            },
            {
              field: 'connections.deck.disconn.status.lib',
              headerName: t(
                'page.transfers.column.connections.deck.disconn.status',
                'Status',
              ),
              columnGroupShow: 'open',
              hide: true,
              editable: isAllowed(this.roles.transfers, true),
              // refData: 'RefConnectionStatus',
              cellStyle: this.getLogisticsColorStatus,
              cellEditorSelector: () => {
                return {
                  component: 'agRichSelect',
                  params: {
                    values: this.REF_LABELS.RefConnectionStatus,
                  },
                };
              },

              filter: 'agSetColumnFilter',
              filterParams: {},
              floatingFilterComponentParams: { suppressFilterButton: false },
            },
            {
              field: 'connections.deck.disconn.week_confirmed.lib',
              headerName: t(
                'page.transfers.column.connections.deck.disconn.week_confirmed',
                'Week confirmed',
              ),
              columnGroupShow: 'open',
              hide: true,
              editable: isAllowed(this.roles.transfers, true),
              cellStyle: this.getLogisticsColorStatus,
              cellEditorSelector: () => {
                return {
                  component: 'agRichSelect',
                  params: {
                    values: this.generateRefWeek(),
                  },
                };
              },

              filter: 'agSetColumnFilter',
              filterParams: {},
              floatingFilterComponentParams: { suppressFilterButton: false },
            },
            {
              field: 'connections.deck.disconn.subcontractor.lib',
              headerName: t(
                'page.transfers.column.connections.deck.disconn.subcontractor',
                'Subcontractor',
              ),
              columnGroupShow: 'open',
              hide: true,
              editable: isAllowed(this.roles.transfers, true),
              // refData: 'RefSubcontractor',
              cellStyle: this.getLogisticsColorStatus,

              // TODO:
              filter: 'agSetColumnFilter',
              filterParams: {},
              floatingFilterComponentParams: { suppressFilterButton: false },
            },
            {
              field: 'connections.deck.disconn.cost',
              headerName: t(
                'page.transfers.column.connections.deck.disconn.cost',
                'Cost',
              ),
              columnGroupShow: 'open',
              hide: true,
              editable: isAllowed(this.roles.transfers, true),
              valueGetter: this.getCostValue,
              cellStyle: this.getLogisticsColorStatus,

              // TODO:
              filter: 'agSetColumnFilter',
              filterParams: {},
              floatingFilterComponentParams: { suppressFilterButton: false },
            },
            {
              field: 'connections.deck.disconn.purchase_order',
              headerName: t(
                'page.transfers.column.connections.deck.disconn.purchase_order',
                'Purchase order',
              ),
              columnGroupShow: 'open',
              hide: true,
              editable: isAllowed(this.roles.transfers, true),
              cellStyle: this.getLogisticsColorStatus,

              // TODO:
              filter: 'agSetColumnFilter',
              filterParams: {},
              floatingFilterComponentParams: { suppressFilterButton: false },
            },
          ],
        },
        {
          headerName: t(
            'page.transfers.column.connections.deck.disposal.name',
            'Connections deck disposal',
          ),
          headerClass: 'groupColumnHeader',
          children: [
            {
              field: 'connections.deck.disposal.status.lib',
              headerName: t(
                'page.transfers.column.connections.deck.disposal.status',
                'Status',
              ),
              hide: true,
              editable: isAllowed(this.roles.transfers, true),
              // refData: 'RefConnectionStatus',
              cellStyle: this.getLogisticsColorStatus,
              cellEditorSelector: () => {
                return {
                  component: 'agRichSelect',
                  params: {
                    values: this.REF_LABELS.RefConnectionStatus,
                  },
                };
              },
              filter: 'agSetColumnFilter',
              filterParams: {},
              floatingFilterComponentParams: { suppressFilterButton: false },
            },
            {
              field: 'connections.deck.disposal.subcontractor.lib',
              headerName: t(
                'page.transfers.column.connections.deck.disposal.subcontractor',
                'Subcontractor',
              ),
              columnGroupShow: 'open',
              hide: true,
              editable: isAllowed(this.roles.transfers, true),
              // refData: 'RefSubcontractor',
              cellStyle: this.getLogisticsColorStatus,

              // TODO:
              filter: 'agSetColumnFilter',
              filterParams: {},
              floatingFilterComponentParams: { suppressFilterButton: false },
            },
            {
              field: 'connections.deck.disposal.cost',
              headerName: t(
                'page.transfers.column.connections.deck.disposal.cost',
                'Cost',
              ),
              columnGroupShow: 'open',
              hide: true,
              editable: isAllowed(this.roles.transfers, true),
              valueGetter: this.getCostValue,
              cellStyle: this.getLogisticsColorStatus,

              // TODO:
              filter: 'agSetColumnFilter',
              filterParams: {},
              floatingFilterComponentParams: { suppressFilterButton: false },
            },
            {
              field: 'connections.deck.disposal.purchase_order',
              headerName: t(
                'page.transfers.column.connections.deck.disposal.purchase_order',
                'Purchase order',
              ),
              columnGroupShow: 'open',
              hide: true,
              editable: isAllowed(this.roles.transfers, true),
              cellStyle: this.getLogisticsColorStatus,

              // TODO:
              filter: 'agSetColumnFilter',
              filterParams: {},
              floatingFilterComponentParams: { suppressFilterButton: false },
            },
          ],
        },
        {
          headerName: t(
            'page.transfers.column.connections.deck.transport.name',
            'Connections deck transport',
          ),
          headerClass: 'groupColumnHeader',
          children: [
            {
              field: 'connections.deck.transport.week.lib',
              headerName: t(
                'page.transfers.column.connections.deck.transport.week',
                'Week',
              ),
              hide: true,
              editable: isAllowed(this.roles.transfers, true),
              cellStyle: this.getLogisticsColorStatus,
              cellEditorSelector: () => {
                return {
                  component: 'agRichSelect',
                  params: {
                    values: this.generateRefWeek(),
                  },
                };
              },

              filter: 'agSetColumnFilter',
              filterParams: {},
              floatingFilterComponentParams: { suppressFilterButton: false },
            },
            {
              field: 'connections.deck.transport.status.lib',
              headerName: t(
                'page.transfers.column.connections.deck.transport.status',
                'Status',
              ),
              columnGroupShow: 'open',
              hide: true,
              editable: isAllowed(this.roles.transfers, true),
              // refData: 'RefConnectionStatus',
              cellStyle: this.getLogisticsColorStatus,
              cellEditorSelector: () => {
                return {
                  component: 'agRichSelect',
                  params: {
                    values: this.REF_LABELS.RefConnectionStatus,
                  },
                };
              },
              filter: 'agSetColumnFilter',
              filterParams: {},
              floatingFilterComponentParams: { suppressFilterButton: false },
            },
            {
              field: 'connections.deck.transport.week_confirmed.lib',
              headerName: t(
                'page.transfers.column.connections.deck.transport.week_confirmed',
                'Week confirmed',
              ),
              columnGroupShow: 'open',
              hide: true,
              editable: isAllowed(this.roles.transfers, true),
              cellStyle: this.getLogisticsColorStatus,
              cellEditorSelector: () => {
                return {
                  component: 'agRichSelect',
                  params: {
                    values: this.generateRefWeek(),
                  },
                };
              },

              filter: 'agSetColumnFilter',
              filterParams: {},
              floatingFilterComponentParams: { suppressFilterButton: false },
            },
            {
              field: 'connections.deck.transport.subcontractor.lib',
              headerName: t(
                'page.transfers.column.connections.deck.transport.subcontractor',
                'Subcontractor',
              ),
              columnGroupShow: 'open',
              hide: true,
              editable: isAllowed(this.roles.transfers, true),
              // refData: 'RefSubcontractor',
              cellStyle: this.getLogisticsColorStatus,

              // TODO:
              filter: 'agSetColumnFilter',
              filterParams: {},
              floatingFilterComponentParams: { suppressFilterButton: false },
            },
            {
              field: 'connections.deck.transport.cost',
              headerName: t(
                'page.transfers.column.connections.deck.transport.cost',
                'Cost',
              ),
              columnGroupShow: 'open',
              hide: true,
              editable: isAllowed(this.roles.transfers, true),
              valueGetter: this.getCostValue,
              cellStyle: this.getLogisticsColorStatus,

              // TODO:
              filter: 'agSetColumnFilter',
              filterParams: {},
              floatingFilterComponentParams: { suppressFilterButton: false },
            },
            {
              field: 'connections.deck.transport.purchase_order',
              headerName: t(
                'page.transfers.column.connections.deck.transport.purchase_order',
                'Purchase order',
              ),
              columnGroupShow: 'open',
              hide: true,
              editable: isAllowed(this.roles.transfers, true),
              cellStyle: this.getLogisticsColorStatus,

              // TODO:
              filter: 'agSetColumnFilter',
              filterParams: {},
              floatingFilterComponentParams: { suppressFilterButton: false },
            },
          ],
        },
        {
          headerName: t(
            'page.transfers.column.connections.mh.disconn.name',
            'Connections unit disconn',
          ),
          headerClass: 'groupColumnHeader',
          children: [
            {
              field: 'connections.mh.disconn.week.lib',
              headerName: t(
                'page.transfers.column.connections.mh.disconn.week',
                'Week',
              ),
              hide: true,
              editable: isAllowed(this.roles.transfers, true),
              cellStyle: this.getLogisticsColorStatus,
              cellEditorSelector: () => {
                return {
                  component: 'agRichSelect',
                  params: {
                    values: this.generateRefWeek(),
                  },
                };
              },

              filter: 'agSetColumnFilter',
              filterParams: {},
              floatingFilterComponentParams: { suppressFilterButton: false },
            },
            {
              field: 'connections.mh.disconn.status.lib',
              headerName: t(
                'page.transfers.column.connections.mh.disconn.status',
                'Status',
              ),
              columnGroupShow: 'open',
              hide: true,
              editable: isAllowed(this.roles.transfers, true),
              // refData: 'RefConnectionStatus',
              cellStyle: this.getLogisticsColorStatus,
              cellEditorSelector: () => {
                return {
                  component: 'agRichSelect',
                  params: {
                    values: this.REF_LABELS.RefConnectionStatus,
                  },
                };
              },

              // TODO:
              filter: 'agSetColumnFilter',
              filterParams: {},
              floatingFilterComponentParams: { suppressFilterButton: false },
            },
            {
              field: 'connections.mh.disconn.week_confirmed.lib',
              headerName: t(
                'page.transfers.column.connections.mh.disconn.week_confirmed',
                'Week confirmed',
              ),
              columnGroupShow: 'open',
              hide: true,
              editable: isAllowed(this.roles.transfers, true),
              cellStyle: this.getLogisticsColorStatus,
              cellEditorSelector: () => {
                return {
                  component: 'agRichSelect',
                  params: {
                    values: this.generateRefWeek(),
                  },
                };
              },

              filter: 'agSetColumnFilter',
              filterParams: {},
              floatingFilterComponentParams: { suppressFilterButton: false },
            },
            {
              field: 'connections.mh.disconn.subcontractor.lib',
              headerName: t(
                'page.transfers.column.connections.mh.disconn.subcontractor',
                'Subcontractor',
              ),
              columnGroupShow: 'open',
              hide: true,
              editable: isAllowed(this.roles.transfers, true),
              // refData: 'RefSubcontractor',
              cellStyle: this.getLogisticsColorStatus,

              // TODO:
              filter: 'agSetColumnFilter',
              filterParams: {},
              floatingFilterComponentParams: { suppressFilterButton: false },
            },
            {
              field: 'connections.mh.disconn.cost',
              headerName: t(
                'page.transfers.column.connections.mh.disconn.cost',
                'Cost',
              ),
              columnGroupShow: 'open',
              hide: true,
              editable: isAllowed(this.roles.transfers, true),
              valueGetter: this.getCostValue,
              cellStyle: this.getLogisticsColorStatus,

              // TODO:
              filter: 'agSetColumnFilter',
              filterParams: {},
              floatingFilterComponentParams: { suppressFilterButton: false },
            },
            {
              field: 'connections.mh.disconn.purchase_order',
              headerName: t(
                'page.transfers.column.connections.mh.disconn.purchase_order',
                'Purchase order',
              ),
              columnGroupShow: 'open',
              hide: true,
              editable: isAllowed(this.roles.transfers, true),
              cellStyle: this.getLogisticsColorStatus,

              // TODO:
              filter: 'agSetColumnFilter',
              filterParams: {},
              floatingFilterComponentParams: { suppressFilterButton: false },
            },
            {
              field: 'connections.mh.disconn.craning',
              headerName: t(
                'page.transfers.column.connections.mh.disconn.craning',
                'Craning',
              ),
              columnGroupShow: 'open',
              hide: true,
              editable: isAllowed(this.roles.transfers, true),
              cellEditor: 'agRichSelectCellEditor',
              cellEditorParams: { values: ['true', 'false', ''] },

              filter: 'agSetColumnFilter',
              filterParams: {},
              floatingFilterComponentParams: { suppressFilterButton: false },
            },
          ],
        },
        {
          headerName: t(
            'page.transfers.column.connections.mh.transport.name',
            'Connections unit transport',
          ),
          headerClass: 'groupColumnHeader',
          children: [
            {
              field: 'connections.mh.transport.week.lib',
              headerName: t(
                'page.transfers.column.connections.mh.transport.week',
                'Week',
              ),
              hide: true,
              editable: isAllowed(this.roles.transfers, true),
              cellStyle: this.getLogisticsColorStatus,
              cellEditorSelector: () => {
                return {
                  component: 'agRichSelect',
                  params: {
                    values: this.generateRefWeek(),
                  },
                };
              },

              filter: 'agSetColumnFilter',
              filterParams: {},
              floatingFilterComponentParams: { suppressFilterButton: false },
            },
            {
              field: 'connections.mh.transport.status.lib',
              headerName: t(
                'page.transfers.column.connections.mh.transport.status',
                'Status',
              ),
              columnGroupShow: 'open',
              hide: true,
              editable: isAllowed(this.roles.transfers, true),
              // refData: 'RefConnectionStatus',
              cellStyle: this.getLogisticsColorStatus,
              cellEditorSelector: () => {
                return {
                  component: 'agRichSelect',
                  params: {
                    values: this.REF_LABELS.RefConnectionStatus,
                  },
                };
              },

              // TODO:
              filter: 'agSetColumnFilter',
              filterParams: {},
              floatingFilterComponentParams: { suppressFilterButton: false },
            },
            {
              field: 'connections.mh.transport.week_confirmed.lib',
              headerName: t(
                'page.transfers.column.connections.mh.transport.week_confirmed',
                'Week confirmed',
              ),
              columnGroupShow: 'open',
              hide: true,
              editable: isAllowed(this.roles.transfers, true),
              cellStyle: this.getLogisticsColorStatus,
              cellEditorSelector: () => {
                return {
                  component: 'agRichSelect',
                  params: {
                    values: this.generateRefWeek(),
                  },
                };
              },

              filter: 'agSetColumnFilter',
              filterParams: {},
              floatingFilterComponentParams: { suppressFilterButton: false },
            },
            {
              field: 'connections.mh.transport.subcontractor.lib',
              headerName: t(
                'page.transfers.column.connections.mh.transport.subcontractor',
                'Subcontractor',
              ),
              columnGroupShow: 'open',
              hide: true,
              editable: isAllowed(this.roles.transfers, true),
              // refData: 'RefSubcontractor',
              cellStyle: this.getLogisticsColorStatus,

              // TODO:
              filter: 'agSetColumnFilter',
              filterParams: {},
              floatingFilterComponentParams: { suppressFilterButton: false },
            },
            {
              field: 'connections.mh.transport.cost',
              headerName: t(
                'page.transfers.column.connections.mh.transport.cost',
                'Cost',
              ),
              columnGroupShow: 'open',
              hide: true,
              editable: isAllowed(this.roles.transfers, true),
              valueGetter: this.getCostValue,
              cellStyle: this.getLogisticsColorStatus,

              // TODO:
              filter: 'agSetColumnFilter',
              filterParams: {},
              floatingFilterComponentParams: { suppressFilterButton: false },
            },
            {
              field: 'connections.mh.transport.purchase_order',
              headerName: t(
                'page.transfers.column.connections.mh.transport.purchase_order',
                'Purchase order',
              ),
              columnGroupShow: 'open',
              hide: true,
              editable: isAllowed(this.roles.transfers, true),
              cellStyle: this.getLogisticsColorStatus,

              // TODO:
              filter: 'agSetColumnFilter',
              filterParams: {},
              floatingFilterComponentParams: { suppressFilterButton: false },
            },
            {
              field: 'connections.mh.transport.comment',
              headerName: t(
                'page.transfers.column.connections.mh.transport.comment',
                'Comment',
              ),
              columnGroupShow: 'open',
              hide: true,
              editable: isAllowed(this.roles.transfers, true),

              filter: 'agSetColumnFilter',
              filterParams: {},
              floatingFilterComponentParams: { suppressFilterButton: false },
            },
          ],
        },
        {
          headerName: t(
            'page.transfers.column.connections.mh.storage.name',
            'Connections unit storage',
          ),
          headerClass: 'groupColumnHeader',
          children: [
            {
              field: 'connections.mh.storage.details',
              headerName: t(
                'page.transfers.column.connections.mh.storage.details',
                'Details',
              ),
              hide: true,
              editable: isAllowed(this.roles.transfers, true),
              cellStyle: this.getLogisticsColorStatus,

              filter: 'agSetColumnFilter',
              filterParams: {},
              floatingFilterComponentParams: { suppressFilterButton: false },
            },
            {
              field: 'connections.mh.storage.cost',
              headerName: t(
                'page.transfers.column.connections.mh.storage.cost',
                'Cost',
              ),
              columnGroupShow: 'open',
              hide: true,
              editable: isAllowed(this.roles.transfers, true),
              valueGetter: this.getCostValue,
              cellStyle: this.getLogisticsColorStatus,

              // TODO:
              filter: 'agSetColumnFilter',
              filterParams: {},
              floatingFilterComponentParams: { suppressFilterButton: false },
            },
            {
              field: 'connections.mh.storage.purchase_order',
              headerName: t(
                'page.transfers.column.connections.mh.storage.purchase_order',
                'Purchase order',
              ),
              columnGroupShow: 'open',
              hide: true,
              editable: isAllowed(this.roles.transfers, true),
              cellStyle: this.getLogisticsColorStatus,

              // TODO:
              filter: 'agSetColumnFilter',
              filterParams: {},
              floatingFilterComponentParams: { suppressFilterButton: false },
            },
          ],
        },
        {
          headerName: t(
            'page.transfers.column.connections.mh.transport_bis.name',
            'Connections unit transport bis',
          ),
          headerClass: 'groupColumnHeader',
          children: [
            {
              field: 'connections.mh.transport_bis.week.lib',
              headerName: t(
                'page.transfers.column.connections.mh.transport_bis.week',
                'Week',
              ),
              hide: true,
              editable: isAllowed(this.roles.transfers, true),
              cellStyle: this.getLogisticsColorStatus,
              cellEditorSelector: () => {
                return {
                  component: 'agRichSelect',
                  params: {
                    values: this.generateRefWeek(),
                  },
                };
              },

              filter: 'agSetColumnFilter',
              filterParams: {},
              floatingFilterComponentParams: { suppressFilterButton: false },
            },
            {
              field: 'connections.mh.transport_bis.status.lib',
              headerName: t(
                'page.transfers.column.connections.mh.transport_bis.status',
                'Status',
              ),
              columnGroupShow: 'open',
              hide: true,
              editable: isAllowed(this.roles.transfers, true),
              // refData: 'RefConnectionStatus',
              cellStyle: this.getLogisticsColorStatus,
              cellEditorSelector: () => {
                return {
                  component: 'agRichSelect',
                  params: {
                    values: this.REF_LABELS.RefConnectionStatus,
                  },
                };
              },

              // TODO:
              filter: 'agSetColumnFilter',
              filterParams: {},
              floatingFilterComponentParams: { suppressFilterButton: false },
            },
            {
              field: 'connections.mh.transport_bis.week_confirmed.lib',
              headerName: t(
                'page.transfers.column.connections.mh.transport_bis.week_confirmed',
                'Week confirmed',
              ),
              columnGroupShow: 'open',
              hide: true,
              editable: isAllowed(this.roles.transfers, true),
              cellStyle: this.getLogisticsColorStatus,
              cellEditorSelector: () => {
                return {
                  component: 'agRichSelect',
                  params: {
                    values: this.generateRefWeek(),
                  },
                };
              },

              filter: 'agSetColumnFilter',
              filterParams: {},
              floatingFilterComponentParams: { suppressFilterButton: false },
            },
            {
              field: 'connections.mh.transport_bis.subcontractor.lib',
              headerName: t(
                'page.transfers.column.connections.mh.transport_bis.subcontractor',
                'Subcontractor',
              ),
              columnGroupShow: 'open',
              hide: true,
              editable: isAllowed(this.roles.transfers, true),
              // refData: 'RefSubcontractor',
              cellStyle: this.getLogisticsColorStatus,

              // TODO:
              filter: 'agSetColumnFilter',
              filterParams: {},
              floatingFilterComponentParams: { suppressFilterButton: false },
            },
            {
              field: 'connections.mh.transport_bis.cost',
              headerName: t(
                'page.transfers.column.connections.mh.transport_bis.cost',
                'Cost',
              ),
              columnGroupShow: 'open',
              hide: true,
              editable: isAllowed(this.roles.transfers, true),
              valueGetter: this.getCostValue,
              cellStyle: this.getLogisticsColorStatus,

              // TODO:
              filter: 'agSetColumnFilter',
              filterParams: {},
              floatingFilterComponentParams: { suppressFilterButton: false },
            },
            {
              field: 'connections.mh.transport_bis.purchase_order',
              headerName: t(
                'page.transfers.column.connections.mh.transport_bis.purchase_order',
                'Purchase order',
              ),
              columnGroupShow: 'open',
              hide: true,
              editable: isAllowed(this.roles.transfers, true),
              cellStyle: this.getLogisticsColorStatus,

              // TODO:
              filter: 'agSetColumnFilter',
              filterParams: {},
              floatingFilterComponentParams: { suppressFilterButton: false },
            },
            {
              field: 'connections.mh.transport_bis.comment',
              headerName: t(
                'page.transfers.column.connections.mh.transport_bis.comment',
                'Comment',
              ),
              columnGroupShow: 'open',
              hide: true,
              editable: isAllowed(this.roles.transfers, true),

              // TODO:
              filter: 'agSetColumnFilter',
              filterParams: {},
              floatingFilterComponentParams: { suppressFilterButton: false },
            },
          ],
        },
        {
          headerName: t(
            'page.transfers.column.connections.mh.reconn.name',
            'Connections unit reconn',
          ),
          headerClass: 'groupColumnHeader',
          children: [
            {
              field: 'connections.mh.reconn.week.lib',
              headerName: t(
                'page.transfers.column.connections.mh.reconn.week',
                'Week',
              ),
              hide: true,
              editable: isAllowed(this.roles.transfers, true),
              cellStyle: this.getLogisticsColorStatus,
              cellEditorSelector: () => {
                return {
                  component: 'agRichSelect',
                  params: {
                    values: this.generateRefWeek(),
                  },
                };
              },

              filter: 'agSetColumnFilter',
              filterParams: {},
              floatingFilterComponentParams: { suppressFilterButton: false },
            },
            {
              field: 'connections.mh.reconn.status.lib',
              headerName: t(
                'page.transfers.column.connections.mh.reconn.status',
                'Status',
              ),
              columnGroupShow: 'open',
              hide: true,
              editable: isAllowed(this.roles.transfers, true),
              // refData: 'RefConnectionStatus',
              cellStyle: this.getLogisticsColorStatus,
              cellEditorSelector: () => {
                return {
                  component: 'agRichSelect',
                  params: {
                    values: this.REF_LABELS.RefConnectionStatus,
                  },
                };
              },

              // TODO:
              filter: 'agSetColumnFilter',
              filterParams: {},
              floatingFilterComponentParams: { suppressFilterButton: false },
            },
            {
              field: 'connections.mh.reconn.week_confirmed.lib',
              headerName: t(
                'page.transfers.column.connections.mh.reconn.week_confirmed',
                'Week confirmed',
              ),
              columnGroupShow: 'open',
              hide: true,
              editable: isAllowed(this.roles.transfers, true),
              cellStyle: this.getLogisticsColorStatus,
              cellEditorSelector: () => {
                return {
                  component: 'agRichSelect',
                  params: {
                    values: this.generateRefWeek(),
                  },
                };
              },

              filter: 'agSetColumnFilter',
              filterParams: {},
              floatingFilterComponentParams: { suppressFilterButton: false },
            },
            {
              field: 'connections.mh.reconn.subcontractor.lib',
              headerName: t(
                'page.transfers.column.connections.mh.reconn.subcontractor',
                'Subcontractor',
              ),
              columnGroupShow: 'open',
              hide: true,
              editable: isAllowed(this.roles.transfers, true),
              // refData: 'RefSubcontractor',
              cellStyle: this.getLogisticsColorStatus,

              // TODO:
              filter: 'agSetColumnFilter',
              filterParams: {},
              floatingFilterComponentParams: { suppressFilterButton: false },
            },
            {
              field: 'connections.mh.reconn.cost',
              headerName: t(
                'page.transfers.column.connections.mh.reconn.cost',
                'Cost',
              ),
              columnGroupShow: 'open',
              hide: true,
              editable: isAllowed(this.roles.transfers, true),
              valueGetter: this.getCostValue,
              cellStyle: this.getLogisticsColorStatus,

              // TODO:
              filter: 'agSetColumnFilter',
              filterParams: {},
              floatingFilterComponentParams: { suppressFilterButton: false },
            },
            {
              field: 'connections.mh.reconn.purchase_order',
              headerName: t(
                'page.transfers.column.connections.mh.reconn.purchase_order',
                'Purchase order',
              ),
              columnGroupShow: 'open',
              hide: true,
              editable: isAllowed(this.roles.transfers, true),
              cellStyle: this.getLogisticsColorStatus,

              // TODO:
              filter: 'agSetColumnFilter',
              filterParams: {},
              floatingFilterComponentParams: { suppressFilterButton: false },
            },
            {
              field: 'connections.mh.reconn.craning',
              headerName: t(
                'page.transfers.column.connections.mh.reconn.craning',
                'Craning',
              ),
              columnGroupShow: 'open',
              hide: true,
              editable: isAllowed(this.roles.transfers, true),
              cellEditor: 'agRichSelectCellEditor',
              cellEditorParams: { values: ['true', 'false'] },

              // TODO:
              filter: 'agSetColumnFilter',
              filterParams: {},
              floatingFilterComponentParams: { suppressFilterButton: false },
            },
          ],
        },
        {
          headerName: t(
            'page.transfers.column.connections.deck.reconn.name',
            'Connections deck reconn',
          ),
          headerClass: 'groupColumnHeader',
          children: [
            {
              field: 'connections.deck.reconn.week.lib',
              headerName: t(
                'page.transfers.column.connections.deck.reconn.week',
                'Week',
              ),
              hide: true,
              editable: isAllowed(this.roles.transfers, true),
              cellStyle: this.getLogisticsColorStatus,
              cellEditorSelector: () => {
                return {
                  component: 'agRichSelect',
                  params: {
                    values: this.generateRefWeek(),
                  },
                };
              },

              filter: 'agSetColumnFilter',
              filterParams: {},
              floatingFilterComponentParams: { suppressFilterButton: false },
            },
            {
              field: 'connections.deck.reconn.status.lib',
              headerName: t(
                'page.transfers.column.connections.deck.reconn.status',
                'Status',
              ),
              columnGroupShow: 'open',
              hide: true,
              editable: isAllowed(this.roles.transfers, true),
              // refData: 'RefConnectionStatus',
              cellStyle: this.getLogisticsColorStatus,
              cellEditorSelector: () => {
                return {
                  component: 'agRichSelect',
                  params: {
                    values: this.REF_LABELS.RefConnectionStatus,
                  },
                };
              },

              // TODO:
              filter: 'agSetColumnFilter',
              filterParams: {},
              floatingFilterComponentParams: { suppressFilterButton: false },
            },
            {
              field: 'connections.deck.reconn.week_confirmed.lib',
              headerName: t(
                'page.transfers.column.connections.deck.reconn.week_confirmed',
                'Week confirmed',
              ),
              columnGroupShow: 'open',
              hide: true,
              editable: isAllowed(this.roles.transfers, true),
              cellStyle: this.getLogisticsColorStatus,
              cellEditorSelector: () => {
                return {
                  component: 'agRichSelect',
                  params: {
                    values: this.generateRefWeek(),
                  },
                };
              },

              filter: 'agSetColumnFilter',
              filterParams: {},
              floatingFilterComponentParams: { suppressFilterButton: false },
            },
            {
              field: 'connections.deck.reconn.subcontractor.lib',
              headerName: t(
                'page.transfers.column.connections.deck.reconn.subcontractor',
                'Subcontractor',
              ),
              columnGroupShow: 'open',
              hide: true,
              editable: isAllowed(this.roles.transfers, true),
              // refData: 'RefSubcontractor',
              cellStyle: this.getLogisticsColorStatus,

              // TODO:
              filter: 'agSetColumnFilter',
              filterParams: {},
              floatingFilterComponentParams: { suppressFilterButton: false },
            },
            {
              field: 'connections.deck.reconn.cost',
              headerName: t(
                'page.transfers.column.connections.deck.reconn.cost',
                'Cost',
              ),
              columnGroupShow: 'open',
              hide: true,
              editable: isAllowed(this.roles.transfers, true),
              valueGetter: this.getCostValue,
              cellStyle: this.getLogisticsColorStatus,

              // TODO:
              filter: 'agSetColumnFilter',
              filterParams: {},
              floatingFilterComponentParams: { suppressFilterButton: false },
            },
            {
              field: 'connections.deck.reconn.purchase_order',
              headerName: t(
                'page.transfers.column.connections.deck.reconn.purchase_order',
                'Purchase order',
              ),
              columnGroupShow: 'open',
              hide: true,
              editable: isAllowed(this.roles.transfers, true),
              cellStyle: this.getLogisticsColorStatus,

              // TODO:
              filter: 'agSetColumnFilter',
              filterParams: {},
              floatingFilterComponentParams: { suppressFilterButton: false },
            },
          ],
        },
        {
          headerName: t(
            'page.transfers.column.connections.aircon.reconn.name',
            'Connections AC reconn',
          ),
          headerClass: 'groupColumnHeader',
          children: [
            {
              field: 'connections.aircon.reconn.week.lib',
              headerName: t(
                'page.transfers.column.connections.aircon.reconn.week',
                'Week',
              ),
              hide: true,
              editable: isAllowed(this.roles.transfers, true),
              cellStyle: this.getLogisticsColorStatus,
              cellEditorSelector: () => {
                return {
                  component: 'agRichSelect',
                  params: {
                    values: this.generateRefWeek(),
                  },
                };
              },

              filter: 'agSetColumnFilter',
              filterParams: {},
              floatingFilterComponentParams: { suppressFilterButton: false },
            },
            {
              field: 'connections.aircon.reconn.status.lib',
              headerName: t(
                'page.transfers.column.connections.aircon.reconn.status',
                'Status',
              ),
              columnGroupShow: 'open',
              hide: true,
              editable: isAllowed(this.roles.transfers, true),
              // refData: 'RefConnectionStatus',
              cellStyle: this.getLogisticsColorStatus,
              cellEditorSelector: () => {
                return {
                  component: 'agRichSelect',
                  params: {
                    values: this.REF_LABELS.RefConnectionStatus,
                  },
                };
              },

              // TODO:
              filter: 'agSetColumnFilter',
              filterParams: {},
              floatingFilterComponentParams: { suppressFilterButton: false },
            },
            {
              field: 'connections.aircon.reconn.week_confirmed.lib',
              headerName: t(
                'page.transfers.column.connections.aircon.reconn.week_confirmed',
                'Week confirmed',
              ),
              columnGroupShow: 'open',
              hide: true,
              editable: isAllowed(this.roles.transfers, true),
              cellStyle: this.getLogisticsColorStatus,
              cellEditorSelector: () => {
                return {
                  component: 'agRichSelect',
                  params: {
                    values: this.generateRefWeek(),
                  },
                };
              },

              filter: 'agSetColumnFilter',
              filterParams: {},
              floatingFilterComponentParams: { suppressFilterButton: false },
            },
            {
              field: 'connections.aircon.reconn.subcontractor.lib',
              headerName: t(
                'page.transfers.column.connections.aircon.reconn.subcontractor',
                'Subcontractor',
              ),
              columnGroupShow: 'open',
              hide: true,
              editable: isAllowed(this.roles.transfers, true),
              // refData: 'RefSubcontractor',
              cellStyle: this.getLogisticsColorStatus,

              // TODO:
              filter: 'agSetColumnFilter',
              filterParams: {},
              floatingFilterComponentParams: { suppressFilterButton: false },
            },
            {
              field: 'connections.aircon.reconn.cost',
              headerName: t(
                'page.transfers.column.connections.aircon.reconn.cost',
                'Cost',
              ),
              columnGroupShow: 'open',
              hide: true,
              editable: isAllowed(this.roles.transfers, true),
              valueGetter: this.getCostValue,
              cellStyle: this.getLogisticsColorStatus,

              // TODO:
              filter: 'agSetColumnFilter',
              filterParams: {},
              floatingFilterComponentParams: { suppressFilterButton: false },
            },
            {
              field: 'connections.aircon.reconn.purchase_order',
              headerName: t(
                'page.transfers.column.connections.aircon.reconn.purchase_order',
                'Purchase order',
              ),
              columnGroupShow: 'open',
              hide: true,
              editable: isAllowed(this.roles.transfers, true),
              cellStyle: this.getLogisticsColorStatus,

              // TODO:
              filter: 'agSetColumnFilter',
              filterParams: {},
              floatingFilterComponentParams: { suppressFilterButton: false },
            },
          ],
        },

        {
          field: 'created_at',
          headerName: t('common.label.created_at', 'Created at'),
          hide: true,

          filter: 'agSetColumnFilter',
          filterParams: {},
          floatingFilterComponentParams: { suppressFilterButton: false },
        },
        {
          field: 'updated_at',
          headerName: t('common.label.updated_at', 'Updated at'),

          filter: 'agSetColumnFilter',
          filterParams: {},
          floatingFilterComponentParams: { suppressFilterButton: false },
        },

        // {
        //   field: 'is_checked_by_admin',
        //   headerName: t(
        //     'page.transfers.column.is_checked_by_admin',
        //     'Admin Check',
        //   ),
        //   hide: true,
        //   filter: 'agSetColumnFilter',
        //   filterParams: {
        //
        //   },
        //   floatingFilterComponentParams: { suppressFilterButton: false },
        // },
        {
          field: '_id',

          filter: 'agSetColumnFilter',
          filterParams: {},
          floatingFilterComponentParams: { suppressFilterButton: false },
        },
        {
          field: 'expected_validation_date',
          headerName: t(
            'page.transfers.column.expected_validation_date',
            'Expected validation date',
          ),
          filter: 'agSetColumnFilter',
          filterParams: {},
          floatingFilterComponentParams: { suppressFilterButton: false },
          valueFormatter: (params) =>
            params.value ? dayjs(params.value).format('LL') : 'Not defined',
        },
        {
          field: 'locked',
          headerName: t('page.transfers.column.locked', 'Locked'),
          filter: 'agSetColumnFilter',
          filterParams: {},
          floatingFilterComponentParams: { suppressFilterButton: false },
          valueFormatter: (params) => (params.value ? '🔒' : '🔓'),
        },
      ],
      methods,
      // namespace for permissions.
      'mv',
    );
  }

  getSiteListConfiguration(methods, t) {
    return this.getConfiguration(
      [
        {
          field: 'lib',
          headerName: t('page.campsites.column.name', 'Name'),
          filter: 'agSetColumnFilter',
          pinned: 'left',
          filterParams: {},
          floatingFilterComponentParams: { suppressFilterButton: false },
        },
        {
          field: 'loca.country',
          headerName: t('page.campsites.column.loca.country', 'Country'),
          editable: isAllowed(this.roles.sites, true),

          filter: 'agSetColumnFilter',
          filterParams: {},
          floatingFilterComponentParams: { suppressFilterButton: false },
          cellRenderer: 'flagCellRenderer',
        },
        {
          field: 'loca.region',
          headerName: t('page.campsites.column.loca.region', 'Region'),
          editable: isAllowed(this.roles.sites, true),

          filter: 'agSetColumnFilter',
          filterParams: {},
          floatingFilterComponentParams: { suppressFilterButton: false },
        },
        {
          field: 'loca.city',
          headerName: t('page.campsites.column.loca.city', 'City'),
          editable: isAllowed(this.roles.sites, true),

          filter: 'agSetColumnFilter',
          filterParams: {},
          floatingFilterComponentParams: { suppressFilterButton: false },
        },
        {
          field: 'zip_code',
          headerName: t('page.campsites.column.zip_code', 'Zip code'),
          hide: true,
          editable: isAllowed(this.roles.sites, true),

          filter: 'agSetColumnFilter',
          filterParams: {},
          floatingFilterComponentParams: { suppressFilterButton: false },
        },
        {
          field: 'address',
          headerName: t('page.campsites.column.address', 'Address'),
          hide: true,
          editable: isAllowed(this.roles.sites, true),

          filter: 'agSetColumnFilter',
          filterParams: {},
          floatingFilterComponentParams: { suppressFilterButton: false },
        },
        {
          field: 'depot',
          headerName: t('page.campsites.column.depot', 'Depot'),
          hide: true,
          editable: isAllowed(this.roles.sites, true),

          filter: 'agSetColumnFilter',
          filterParams: {},
          floatingFilterComponentParams: { suppressFilterButton: false },
          valueFormatter: (params) =>
            params.value?.lib ? params.value.lib : params.value,
        },
        {
          field: 'is_propre',
          headerName: t('page.campsites.column.own_campsite', 'Own campsite'),

          filter: 'agSetColumnFilter',
          filterParams: {},
          floatingFilterComponentParams: { suppressFilterButton: false },
          valueFormatter: (params) => (params.value ? 'YES' : 'NO'),
          cellClass: (params) =>
            params.value ? 'own_site_true' : 'own_site_false',
        },
        {
          field: 'currently_operated',
          headerName: t(
            'page.campsites.column.currently_operated',
            'Currently operated',
          ),

          filter: 'agSetColumnFilter',
          filterParams: {},
          floatingFilterComponentParams: { suppressFilterButton: false },
          valueFormatter: (params) => (params.value ? 'YES' : 'NO'),
          cellClass: (params) =>
            params.value
              ? 'currently_operated_true'
              : 'currently_operated_false',
        },
        {
          field: 'chain.lib',
          headerName: t('page.campsites.column.chain', 'Chain'),
          filter: 'agSetColumnFilter',
          filterParams: {},
          floatingFilterComponentParams: { suppressFilterButton: false },
        },
        {
          field: 'segment.lib',
          headerName: t('page.campsites.column.segment', 'Segment'),
          filter: 'agSetColumnFilter',
          filterParams: {},
          floatingFilterComponentParams: { suppressFilterButton: false },
        },
        {
          headerName: t(
            'page.campsites.column.opening_closing',
            'Opening/Closing dates',
          ),
          headerClass: 'groupColumnHeader',
          children: [
            {
              field: 'opening_date',
              headerName: t(
                'page.campsites.column.opening_date',
                'Opening date',
              ),
              filter: 'agSetColumnFilter',
              filterParams: {},
              floatingFilterComponentParams: { suppressFilterButton: false },
              valueFormatter: (params) =>
                params.value && dayjs(params.value).format('ll'),
            },
            {
              field: 'closing_date',
              headerName: t(
                'page.campsites.column.closing_date',
                'Closing date',
              ),
              filter: 'agSetColumnFilter',
              filterParams: {},
              floatingFilterComponentParams: { suppressFilterButton: false },
              valueFormatter: (params) =>
                params.value && dayjs(params.value).format('ll'),
            },
            {
              field: 'brand_opening_date',
              headerName: t(
                'page.campsites.column.brand_opening_date',
                'Brand opening date',
              ),
              filter: 'agSetColumnFilter',
              filterParams: {},
              floatingFilterComponentParams: { suppressFilterButton: false },
              columnGroupShow: 'closed',
              valueFormatter: (params) =>
                params.value && dayjs(params.value).format('ll'),
            },
            {
              field: 'brand_closing_date',
              headerName: t(
                'page.campsites.column.brand_closing_date',
                'Brand closing date',
              ),
              filter: 'agSetColumnFilter',
              filterParams: {},
              floatingFilterComponentParams: { suppressFilterButton: false },
              columnGroupShow: 'closed',
              valueFormatter: (params) =>
                params.value && dayjs(params.value).format('ll'),
            },
            {
              field: 'partner_deadline',
              headerName: t(
                'page.campsites.column.partner_deadline',
                'Partner deadline',
              ),
              filter: 'agSetColumnFilter',
              filterParams: {},
              floatingFilterComponentParams: { suppressFilterButton: false },
              columnGroupShow: 'closed',
              valueFormatter: (params) =>
                params.value && dayjs(params.value).format('ll'),
            },
            {
              field: 'brand_deadline',
              headerName: t(
                'page.campsites.column.brand_deadline',
                'Brand deadline',
              ),
              filter: 'agSetColumnFilter',
              filterParams: {},
              floatingFilterComponentParams: { suppressFilterButton: false },
              columnGroupShow: 'closed',
              valueFormatter: (params) =>
                params.value && dayjs(params.value).format('ll'),
            },
          ],
        },
        {
          headerName: t(
            'page.campsites.column.contact.homair.tab',
            'Campsite | Homair contact',
          ),
          headerClass: 'groupColumnHeader',
          children: [
            {
              field: 'homair_contact.lib',
              headerName: t(
                'page.campsites.column.contact.homair.label',
                'Homair contact | Label',
              ),
              filter: 'agSetColumnFilter',
              filterParams: {},
              floatingFilterComponentParams: { suppressFilterButton: false },
              hide: true,
            },
            {
              field: 'homair_contact.email',
              headerName: t(
                'page.campsites.column.contact.homair.email',
                'Homair contact | E-mail',
              ),
              columnGroupShow: 'open',

              filter: 'agSetColumnFilter',
              filterParams: {},
              floatingFilterComponentParams: { suppressFilterButton: false },
              hide: true,
            },
            {
              field: 'homair_contact.phone',
              headerName: t(
                'page.campsites.column.contact.homair.phone',
                'Homair contact | Phone',
              ),
              columnGroupShow: 'open',

              filter: 'agSetColumnFilter',
              filterParams: {},
              floatingFilterComponentParams: { suppressFilterButton: false },
              hide: true,
            },
          ],
        },
        {
          headerName: t(
            'page.campsites.column.contact.eurocamp.tab',
            'Campsite | Eurocamp contact',
          ),
          headerClass: 'groupColumnHeader',
          children: [
            {
              field: 'eurocamp_contact.lib',
              headerName: t(
                'page.campsites.column.contact.eurocamp.label',
                'Eurocamp contact | Label',
              ),
              filter: 'agSetColumnFilter',
              filterParams: {},
              floatingFilterComponentParams: { suppressFilterButton: false },
              hide: true,
            },
            {
              field: 'eurocamp_contact.email',
              headerName: t(
                'page.campsites.column.contact.eurocamp.email',
                'Eurocamp contact | E-mail',
              ),
              columnGroupShow: 'open',

              filter: 'agSetColumnFilter',
              filterParams: {},
              floatingFilterComponentParams: { suppressFilterButton: false },
              hide: true,
            },
            {
              field: 'eurocamp_contact.phone',
              headerName: t(
                'page.campsites.column.contact.eurocamp.phone',
                'Eurocamp contact | Phone',
              ),
              columnGroupShow: 'open',

              filter: 'agSetColumnFilter',
              filterParams: {},
              floatingFilterComponentParams: { suppressFilterButton: false },
              hide: true,
            },
          ],
        },
        {
          headerName: t(
            'page.campsites.column.contact.roan.tab',
            'Campsite | Roan contact',
          ),
          headerClass: 'groupColumnHeader',
          children: [
            {
              field: 'roan_contact.lib',
              headerName: t(
                'page.campsites.column.contact.roan.label',
                'Roan contact | Label',
              ),
              filter: 'agSetColumnFilter',
              filterParams: {},
              floatingFilterComponentParams: { suppressFilterButton: false },
              hide: true,
            },
            {
              field: 'roan_contact.email',
              headerName: t(
                'page.campsites.column.contact.roan.email',
                'Roan contact | E-mail',
              ),
              columnGroupShow: 'open',

              filter: 'agSetColumnFilter',
              filterParams: {},
              floatingFilterComponentParams: { suppressFilterButton: false },
              hide: true,
            },
            {
              field: 'roan_contact.phone',
              headerName: t(
                'page.campsites.column.contact.roan.phone',
                'Roan contact | Phone',
              ),
              columnGroupShow: 'open',

              filter: 'agSetColumnFilter',
              filterParams: {},
              floatingFilterComponentParams: { suppressFilterButton: false },
              hide: true,
            },
          ],
        },
        {
          //field: 'contract_manager',
          headerName: t(
            'page.sites.column.contract_manager.lib',
            'Contract manager',
          ),
          headerClass: 'groupColumnHeader',
          children: [
            {
              field: 'contract_managers',
              headerName: t(
                'page.sites.column.contract_manager.lib',
                'Contract manager',
              ),
              valueGetter: (params) => {
                const contract_managers = params?.data?.contract_managers;
                if (contract_managers?.length > 0) {
                  return contract_managers
                    ?.map((manager) => manager?.member?.lib)
                    .join(', ');
                } else return null;
              },
              filter: 'agSetColumnFilter',
              filterParams: {},
              floatingFilterComponentParams: { suppressFilterButton: false },
            },
            // {
            //   field: 'contract_manager.email',
            //   headerName: t('page.sites.column.contract_manager.email'),
            //   columnGroupShow: 'open',
            //   filter: 'agSetColumnFilter',
            //   filterParams: {
            //
            //   },
            //   floatingFilterComponentParams: { suppressFilterButton: false },
            // },
            // {
            //   field: 'contract_manager.phone',
            //   headerName: t('page.sites.column.contract_manager.phone'),
            //   columnGroupShow: 'open',
            //   filter: 'agSetColumnFilter',
            //   filterParams: {
            //
            //   },
            //   floatingFilterComponentParams: { suppressFilterButton: false },
            // },
          ],
        },
        {
          headerName: t(
            'page.campsites.column.homair_area_manager',
            'Area manager | Homair',
          ),
          headerClass: 'groupColumnHeader',
          children: [
            {
              field: 'french_brand_area.lib',
              headerName: t(
                'page.campsites.column.homair_area_manager_label',
                'Homair AM | Label',
              ),
              filter: 'agSetColumnFilter',
              filterParams: {},
              floatingFilterComponentParams: { suppressFilterButton: false },
            },
            {
              field: 'french_brand_area.hv_am_email',
              headerName: t(
                'page.campsites.column.homair_area_manager_email',
                'Homair AM | E-mail',
              ),
              columnGroupShow: 'open',

              filter: 'agSetColumnFilter',
              filterParams: {},
              floatingFilterComponentParams: { suppressFilterButton: false },
            },
            {
              field: 'french_brand_area.hv_am_phone',
              headerName: t(
                'page.campsites.column.homair_area_manager_phone',
                'Homair AM | Phone',
              ),
              columnGroupShow: 'open',

              filter: 'agSetColumnFilter',
              filterParams: {},
              floatingFilterComponentParams: { suppressFilterButton: false },
            },
            {
              field: 'french_brand_area.hv_pdm_email',
              headerName: t(
                'page.campsites.column.homair_area_manager_pdm_email',
                'Homair AM | PDM e-mail',
              ),
              columnGroupShow: 'open',

              filter: 'agSetColumnFilter',
              filterParams: {},
              floatingFilterComponentParams: { suppressFilterButton: false },
            },
          ],
        },
        {
          headerName: t(
            'page.campsites.column.eurocamp_area_manager',
            'Area manager | Eurocamp',
          ),
          headerClass: 'groupColumnHeader',
          children: [
            {
              field: 'brit_brand_area.lib',
              headerName: t(
                'page.campsites.column.eurocamp_area_manager_label',
                'Eurocamp AM | Label',
              ),

              // TODO:
              // editable: true,
              // customFilter: true,
              // cellRenderer: 'listCellRenderer',
              // refData: 'RefBritBrandArea',

              filter: 'agSetColumnFilter',
              filterParams: {},
              floatingFilterComponentParams: { suppressFilterButton: false },
            },
            {
              field: 'brit_brand_area.bb_email',
              headerName: t(
                'page.campsites.column.eurocamp_area_manager_email',
                'Eurocamp AM | E-mail',
              ),
              columnGroupShow: 'open',

              filter: 'agSetColumnFilter',
              filterParams: {},
              floatingFilterComponentParams: { suppressFilterButton: false },
            },
            {
              field: 'brit_brand_area.bb_phone',
              headerName: t(
                'page.campsites.column.eurocamp_area_manager_phone',
                'Eurocamp AM | Phone',
              ),
              columnGroupShow: 'open',

              filter: 'agSetColumnFilter',
              filterParams: {},
              floatingFilterComponentParams: { suppressFilterButton: false },
            },
            {
              field: 'brit_brand_area.bb_pdm_email',
              headerName: t(
                'page.campsites.column.eurocamp_area_manager_pdm_email',
                'Eurocamp AM | PDM e-mail',
              ),
              columnGroupShow: 'open',

              filter: 'agSetColumnFilter',
              filterParams: {},
              floatingFilterComponentParams: { suppressFilterButton: false },
            },
          ],
        },
        {
          headerName: t(
            'page.campsites.column.roan_area_manager',
            'Area manager | Roan',
          ),
          headerClass: 'groupColumnHeader',
          children: [
            {
              field: 'roan_area.lib',
              headerName: t(
                'page.campsites.column.roan_area_manager_label',
                'Roan AM | Label',
              ),

              // TODO:
              // editable: true,
              // customFilter: true,
              // cellRenderer: 'listCellRenderer',
              // refData: 'RefRoanArea',

              filter: 'agSetColumnFilter',
              filterParams: {},
              floatingFilterComponentParams: { suppressFilterButton: false },
            },
            {
              field: 'roan_area.r_am_email',
              headerName: t(
                'page.campsites.column.roan_area_manager_email',
                'Roan AM | E-mail',
              ),
              columnGroupShow: 'open',

              filter: 'agSetColumnFilter',
              filterParams: {},
              floatingFilterComponentParams: { suppressFilterButton: false },
            },
            {
              field: 'roan_area.r_am_phone',
              headerName: t(
                'page.campsites.column.roan_area_manager_phone',
                'Roan AM | Phone',
              ),
              columnGroupShow: 'open',

              filter: 'agSetColumnFilter',
              filterParams: {},
              floatingFilterComponentParams: { suppressFilterButton: false },
            },
            {
              field: 'roan_area.r_pdm_email',
              headerName: t(
                'page.campsites.column.roan_area_manager_pdm_email',
                'Roan AM | PDM e-mail',
              ),
              columnGroupShow: 'open',

              filter: 'agSetColumnFilter',
              filterParams: {},
              floatingFilterComponentParams: { suppressFilterButton: false },
            },
          ],
        },
        {
          headerName: t('page.campsites.campsites.rmt', 'RMT'),
          headerClass: 'groupColumnHeader',
          children: [
            {
              field: 'rmt_regions',
              headerName: t(
                'page.campsites.column.rmt_regions.code',
                'RMT Regions | Code',
              ),
              // editable: isAllowed(this.roles.sites, true),

              // cellEditorSelector: () => {
              //   return {
              //     component: 'agRichSelect',
              //     params: {
              //       values: this.REF_LABELS.RefRmtRegion,
              //     },
              //   };
              // },
              valueGetter: (params) => {
                const rmt_regions = params?.data?.rmt_regions;
                if (rmt_regions?.length > 0) {
                  return rmt_regions?.map((region) => region._id).join(', ');
                } else return null;
              },
              filter: 'agSetColumnFilter',
              filterParams: {},
              floatingFilterComponentParams: { suppressFilterButton: false },
            },
            {
              // field: 'rmt_regions',
              headerName: t(
                'page.campsite.column.rmt_regions.members',
                'RMT Regions | Technicians',
              ),
              columnGroupShow: 'open',
              filter: 'agSetColumnFilter',
              filterParams: {},
              valueGetter: (params) => {
                const rmt_regions = params?.data?.rmt_regions;
                if (rmt_regions?.length > 0) {
                  let members = rmt_regions.map((region) => region?.members);
                  members = members.flat();
                  return members
                    .map(
                      (member) =>
                        `${member.firstname} ${member.lastname} (${member.code})`,
                    )
                    .join(', ');
                  // return rmt_regions
                  //   ?.map((region) => region?.members)
                } else return null;
              },

              // valueFormatter: (params) => {
              //   console.info(params.value);
              // },
              floatingFilterComponentParams: { suppressFilterButton: false },
            },
            {
              headerName: t(
                'page.campsites.column.rmt_regions.phones',
                'RMT Regions | Technicians phone number',
              ),
              columnGroupShow: 'open',
              filter: 'agSetColumnFilter',
              filterParams: {},
              floatingFilterComponentParams: { suppressFilterButton: false },
              valueGetter: (params) => {
                const rmt_regions = params?.data?.rmt_regions;
                if (rmt_regions?.length > 0) {
                  let members = rmt_regions.map((region) => region?.members);
                  members = members.flat();
                  return members
                    .map((member) => `${member.phone || null} (${member.code})`)
                    .join(', ');
                } else return null;
              },
            },
            {
              headerName: t(
                'page.campsites.column.rmt_regions.emails',
                'RMT Regions | E-mails',
              ),
              columnGroupShow: 'open',
              filter: 'agSetColumnFilter',
              filterParams: {},
              floatingFilterComponentParams: { suppressFilterButton: false },
              valueGetter: (params) => {
                const rmt_regions = params?.data?.rmt_regions;
                if (rmt_regions?.length > 0) {
                  let members = rmt_regions.map((region) => region?.members);
                  members = members.flat();
                  return members
                    .map((member) => `${member.email || null} (${member.code})`)
                    .join(', ');
                } else return null;
              },
            },
            {
              headerName: t(
                'page.campsites.column.rmt_regions.languages',
                'RMT Regions | Languages',
              ),
              columnGroupShow: 'open',
              filter: 'agSetColumnFilter',
              filterParams: {},
              floatingFilterComponentParams: { suppressFilterButton: false },
              valueGetter: (params) => {
                const rmt_regions = params?.data?.rmt_regions;
                if (rmt_regions?.length > 0) {
                  let members = rmt_regions.map((region) => region?.members);
                  members = members.flat();
                  return members
                    .map(
                      (member) => `${member.language || null} (${member.code})`,
                    )
                    .join(', ');
                } else return null;
              },
            },
            {
              headerName: t(
                'page.campsites.column.rmt_region.vehicle_registration_number',
                'RMT Regions | RMT vehicle registration number',
              ),
              columnGroupShow: 'open',
              filter: 'agSetColumnFilter',
              filterParams: {},
              floatingFilterComponentParams: { suppressFilterButton: false },
              valueGetter: (params) => {
                const rmt_regions = params?.data?.rmt_regions;
                if (rmt_regions?.length > 0) {
                  let members = rmt_regions.map((region) => region?.members);
                  members = members.flat();
                  return members
                    .map(
                      (member) =>
                        `${member.vehicle_registration_number || null} (${
                          member.code
                        })`,
                    )
                    .join(', ');
                } else return null;
              },
            },
            {
              headerName: t(
                'page.campsites.column.rmt_region.rmm.name',
                'RMT Regions | RMM name',
              ),
              columnGroupShow: 'open',
              filter: 'agSetColumnFilter',
              filterParams: {},
              floatingFilterComponentParams: { suppressFilterButton: false },
              valueGetter: (params) => {
                const rmt_regions = params?.data?.rmt_regions;
                if (rmt_regions?.length > 0) {
                  let members = rmt_regions.map((region) => region?.members);
                  members = members.flat();
                  members
                    .filter((member) => member.rmm)
                    .forEach((member) => {
                      member.rmm.member_code = member.code;
                    });
                  let seniors = members
                    .filter((member) => member.rmm)
                    .map((member) => member.rmm);
                  if (seniors?.length > 0) {
                    return seniors
                      .map(
                        (senior) =>
                          `${senior?.firstname} ${senior?.lastname} (${senior?.member_code})`,
                      )
                      .join(', ');
                  }
                } else return null;
              },
            },
            {
              headerName: t(
                'page.campsites.column.rmt_region.seniors.phone',
                'RMT Regions | RMM phone',
              ),
              columnGroupShow: 'open',
              filter: 'agSetColumnFilter',
              filterParams: {},
              floatingFilterComponentParams: { suppressFilterButton: false },
              valueGetter: (params) => {
                const rmt_regions = params?.data?.rmt_regions;
                if (rmt_regions?.length > 0) {
                  let members = rmt_regions.map((region) => region?.members);
                  members = members.flat();
                  members
                    .filter((member) => member.rmm)
                    .forEach((member) => {
                      member.rmm.member_code = member.code;
                    });
                  let seniors = members
                    .filter((member) => member.rmm)
                    .map((member) => member.rmm);
                  return seniors
                    .map(
                      (senior) => `${senior?.phone} (${senior?.member_code})`,
                    )
                    .join(', ');
                } else return null;
              },
            },
            {
              headerName: t(
                'page.campsites.column.rmt_region.seniors.email',
                'RMT Regions | RMM e-mail',
              ),
              columnGroupShow: 'open',
              filter: 'agSetColumnFilter',
              filterParams: {},
              floatingFilterComponentParams: { suppressFilterButton: false },
              valueGetter: (params) => {
                const rmt_regions = params?.data?.rmt_regions;
                if (rmt_regions?.length > 0) {
                  let members = rmt_regions.map((region) => region?.members);
                  members = members.flat();
                  members
                    .filter((member) => member.rmm)
                    .forEach((member) => {
                      member.rmm.member_code = member.code;
                    });
                  let seniors = members
                    .filter((member) => member.rmm)
                    .map((member) => member.rmm);
                  return seniors
                    .map(
                      (senior) => `${senior?.email} (${senior?.member_code})`,
                    )
                    .join(', ');
                } else return null;
              },
            },
            {
              headerName: t(
                'page.campsites.column.rmt_region.cmm.name',
                'RMT Regions | CMM name',
              ),
              columnGroupShow: 'open',
              filter: 'agSetColumnFilter',
              filterParams: {},
              floatingFilterComponentParams: { suppressFilterButton: false },
              valueGetter: (params) => {
                const rmt_regions = params?.data?.rmt_regions;
                if (rmt_regions?.length > 0) {
                  let members = rmt_regions.map((region) => region?.members);
                  members = members.flat();
                  members
                    .filter((member) => member?.rmm?.cmm)
                    .forEach((member) => {
                      member.rmm.cmm.member_code = member.code;
                    });

                  let countryManagers = members
                    .filter((member) => member?.rmm?.cmm)
                    .map((member) => member?.rmm?.cmm);
                  if (countryManagers.length > 0) {
                    return countryManagers
                      .map(
                        (item) =>
                          `${item?.firstname} ${item?.lastname} (${item?.member_code})`,
                      )
                      .join(', ');
                  }
                } else return null;
              },
            },
            {
              headerName: t(
                'page.campsites.column.rmt_region.cmm.phone',
                'RMT Regions | CMM phone',
              ),
              columnGroupShow: 'open',
              filter: 'agSetColumnFilter',
              filterParams: {},
              floatingFilterComponentParams: { suppressFilterButton: false },
              valueGetter: (params) => {
                const rmt_regions = params?.data?.rmt_regions;
                if (rmt_regions?.length > 0) {
                  let members = rmt_regions.map((region) => region?.members);
                  members = members.flat();
                  members
                    .filter((member) => member?.rmm?.cmm)
                    .forEach((member) => {
                      member.rmm.cmm.member_code = member.code;
                    });
                  let countryManagers = members
                    .filter((member) => member?.rmm?.cmm)
                    .map((member) => member?.rmm?.cmm);
                  if (countryManagers.length > 0) {
                    return countryManagers
                      .map((item) => `${item?.phone} (${item?.member_code})`)
                      .join(', ');
                  }
                } else return null;
              },
            },
            {
              headerName: t(
                'page.campsites.column.rmt_region.cmm.email',
                'RMT Regions | CMM e-mail',
              ),
              columnGroupShow: 'open',
              filter: 'agSetColumnFilter',
              filterParams: {},
              floatingFilterComponentParams: { suppressFilterButton: false },
              valueGetter: (params) => {
                const rmt_regions = params?.data?.rmt_regions;
                if (rmt_regions?.length > 0) {
                  let members = rmt_regions.map((region) => region?.members);
                  members = members.flat();
                  members
                    .filter((member) => member?.rmm?.cmm)
                    .forEach((member) => {
                      member.rmm.cmm.member_code = member.code;
                    });
                  let countryManagers = members
                    .filter((member) => member?.rmm?.cmm)
                    .map((member) => member?.rmm?.cmm);
                  if (countryManagers.length > 0) {
                    return countryManagers
                      .map((item) => `${item?.email} (${item?.member_code})`)
                      .join(', ');
                  }
                } else return null;
              },
            },
          ],
        },
        /*{
          headerName: t('page.campsites.column.maintenance.lib', 'Maintenance'),
          headerClass: 'groupColumnHeader',
          children: [
            {
              field: 'safety_commissioning_report',
              headerName: t(
                'page.campsites.column.safety_commissioning',
                'Safety Commisioning',
              ),
              editable: false,
              filter: 'agSetColumnFilter',
              filterParams: {},
              floatingFilterComponentParams: { suppressFilterButton: false },
              cellRenderer: 'percentCellRenderer',
              valueFormatter: (params) =>
                (params.value = params?.data?.safety_commissioning_report
                  ? params.data.safety_commissioning_report
                  : 0),
              valueGetter: (params) => {
                let level = 'Success';
                const value = params?.data?.safety_commissioning_report;
                if (value < 90) level = 'Moderate';
                if (value < 70) level = 'Warning';
                if (value < 50) level = 'Danger';
                return level;
              },
            },
            {
              field: 'equipment_check_report',
              headerName: t(
                'page.campsites.column.equipment_check',
                'Equipment Check',
              ),
              columnGroupShow: 'open',
              editable: false,
              filter: 'agSetColumnFilter',
              filterParams: {},
              floatingFilterComponentParams: { suppressFilterButton: false },
              cellRenderer: 'percentCellRenderer',
              valueFormatter: (params) =>
                (params.value = params?.data?.equipment_check_report
                  ? params.data.equipment_check_report
                  : 0),
              valueGetter: (params) => {
                let level = 'Success';
                const value = params?.data?.equipment_check_report;
                if (value < 90) level = 'Moderate';
                if (value < 70) level = 'Warning';
                if (value < 50) level = 'Danger';
                return level;
              },
            },
            {
              field: 'tech_check_report',
              headerName: t(
                'page.campsites.column.technical_check',
                'Technical Check',
              ),
              columnGroupShow: 'open',
              editable: false,
              filter: 'agSetColumnFilter',
              filterParams: {},
              floatingFilterComponentParams: { suppressFilterButton: false },
              cellRenderer: 'percentCellRenderer',
              valueFormatter: (params) =>
                (params.value = params?.data?.tech_check_report
                  ? params.data.tech_check_report
                  : 0),
              valueGetter: (params) => {
                let level = 'Success';
                const value = params?.data?.tech_check_report;
                if (value < 90) level = 'Moderate';
                if (value < 70) level = 'Warning';
                if (value < 50) level = 'Danger';
                return level;
              },
            },
          ],
        },*/
        {
          field: 'gps.lat',
          headerName: t('page.campsites.column.gps_lat', 'Latitude'),
          editable: isAllowed(this.roles.sites, true),
          hide: true,

          filter: 'agSetColumnFilter',
          filterParams: {},
          floatingFilterComponentParams: { suppressFilterButton: false },
        },
        {
          field: 'gps.lon',
          headerName: t('page.campsites.column.gps_lon', 'Longitude'),
          editable: isAllowed(this.roles.sites, true),
          hide: true,

          filter: 'agSetColumnFilter',
          filterParams: {},
          floatingFilterComponentParams: { suppressFilterButton: false },
        },
        {
          field: 'abc_cluster',
          headerName: t('page.campsites.column.abc_cluster', 'ABC Cluster'),
          filter: 'agSetColumnFilter',
          filterParams: {},
          floatingFilterComponentParams: { suppressFilterButton: false },
          cellStyle: { 'font-weight': 600 },
        },
        {
          field: 'code_group',
          headerName: t('page.campsites.column.group_code', 'Group code'),
          filter: 'agSetColumnFilter',
          filterParams: {},
          floatingFilterComponentParams: { suppressFilterButton: false },
          cellStyle: { 'font-weight': 600 },
        },
        {
          field: 'comments',
          headerName: t(
            'page.campsites.column.comments',
            'Additional comments',
          ),
          hide: true,
          editable: isAllowed(this.roles.units, true),
          cellEditor: 'agLargeTextCellEditor',
          cellEditorParams: {
            maxLength: '300', // override the editor defaults
            cols: '50',
            rows: '5',
          },
          filter: 'agSetColumnFilter',
          filterParams: {},
          floatingFilterComponentParams: { suppressFilterButton: false },
        },
        {
          headerName: t('page.campsites.column.pitch.lib', 'Pitch'),
          headerClass: 'groupColumnHeader',
          hide: true,
          children: [
            {
              field: 'pitch.pricing',
              headerName: t('page.campsites.column.pitch_pricing', 'Pricing'),
              editable: false,
              hide: true,
              filter: 'agSetColumnFilter',
              filterParams: {},
              floatingFilterComponentParams: { suppressFilterButton: false },
              tooltipField: 'pitch.pricing',
            },
            {
              field: 'pitch.information',
              headerName: t(
                'page.campsites.column.pitch_information',
                'Information',
              ),
              editable: false,
              hide: true,
              filter: 'agSetColumnFilter',
              filterParams: {},
              floatingFilterComponentParams: { suppressFilterButton: false },
              tooltipField: 'pitch.information',
            },
          ],
        },
        {
          field: 'created_at',
          headerName: t('common.label.created_at', 'Created at'),
          hide: true,

          filter: 'agSetColumnFilter',
          filterParams: {},
          floatingFilterComponentParams: { suppressFilterButton: false },
        },
        {
          field: 'updated_at',
          headerName: t('common.label.updated_at', 'Updated at'),

          filter: 'agSetColumnFilter',
          filterParams: {},
          floatingFilterComponentParams: { suppressFilterButton: false },
        },
        // {
        //   field: 'is_checked_by_admin',
        //   headerName: t('page.sites.column.is_checked_by_admin', 'Admin Check'),
        //   hide: true,
        //   filter: 'agSetColumnFilter',
        //   filterParams: {
        //
        //   },
        //   floatingFilterComponentParams: { suppressFilterButton: false },
        // },

        {
          field: '_id',
          filter: 'agSetColumnFilter',
          filterParams: {},
          floatingFilterComponentParams: { suppressFilterButton: false },
        },
      ],
      methods,
      // namespace for permissions.
      'site',
    );
  }

  getPitchFeesListConfiguration(methods, t) {
    return this.getConfiguration(
      [
        {
          headerName: t('page.pitch_fees.column.campsite.title', 'Campsite'),
          headerClass: 'groupColumnHeader',
          children: [
            {
              field: 'site.lib',
              headerName: t('page.pitch_fees.column.campsite.name', 'Name'),
              editable: isAllowed(this.roles.pitchFees, true),
              cellEditorSelector: () => {
                return {
                  component: 'agRichSelect',
                  params: {
                    values: this.REF_LABELS.RefSite,
                  },
                };
              },
              filter: 'agSetColumnFilter',
              filterParams: {},
              floatingFilterComponentParams: { suppressFilterButton: false },
            },
            {
              field: 'site.code_group',
              headerName: t(
                'page.pitch_fees.column.campsite.group_code',
                'Group code',
              ),
              cellClass: 'uneditable',
              filter: 'agSetColumnFilter',
              filterParams: {},
              floatingFilterComponentParams: { suppressFilterButton: false },
              cellStyle: { 'font-weight': 600 },
            },
            {
              field: 'site.loca.country',
              headerName: t(
                'page.pitch_fees.column.campsite.loca.country',
                'Country',
              ),
              columnGroupShow: 'open',
              cellClass: 'uneditable',
              filter: 'agSetColumnFilter',
              filterParams: {},
              floatingFilterComponentParams: { suppressFilterButton: false },
            },
            {
              field: 'site.loca.region',
              headerName: t(
                'page.pitch_fees.column.campsite.loca.region',
                'Region',
              ),
              columnGroupShow: 'open',
              cellClass: 'uneditable',
              filter: 'agSetColumnFilter',
              filterParams: {},
              floatingFilterComponentParams: { suppressFilterButton: false },
            },
            {
              field: 'site.loca.city',
              headerName: t(
                'page.pitch_fees.column.campsite.loca.city',
                'City',
              ),
              columnGroupShow: 'open',
              cellClass: 'uneditable',
              filter: 'agSetColumnFilter',
              filterParams: {},
              floatingFilterComponentParams: { suppressFilterButton: false },
            },
            {
              field: 'site.is_propre',
              headerName: t('page.pitch_fees.column.own_site', 'Own site'),
              columnGroupShow: 'open',
              cellClass: 'uneditable',
              filter: 'agSetColumnFilter',
              filterParams: {},
              floatingFilterComponentParams: { suppressFilterButton: false },
              valueFormatter: (params) => (params.value ? '✅' : '❌'),
            },
            {
              field: 'site.chain.lib',
              headerName: t('page.pitch_fees.column.chain', 'Group/Chain'),
              columnGroupShow: 'open',
              cellClass: 'uneditable',
              filter: 'agSetColumnFilter',
              filterParams: {},
              floatingFilterComponentParams: { suppressFilterButton: false },
            },
          ],
        },
        {
          field: 'season',
          headerName: t('page.pitch_fees.column.season', 'Season'),
          editable: isAllowed(this.roles.pitchFees, true),
          cellEditorSelector: () => {
            return {
              component: 'agRichSelect',
              params: {
                values: this.generateYearListTD(),
              },
            };
          },

          filter: 'agSetColumnFilter',
          filterParams: {},
          floatingFilterComponentParams: { suppressFilterButton: false },
        },
        {
          field: 'commercial_brand.lib',
          headerName: t(
            'page.pitch_fees.column.commercial_brand',
            'Commercial brand',
          ),
          editable: isAllowed(this.roles.pitchFees, true),
          cellEditorSelector: () => {
            return {
              component: 'agRichSelect',
              params: {
                values: this.REF_LABELS.RefMarqueCommerciale,
              },
            };
          },
          filter: 'agSetColumnFilter',
          filterParams: {},
          floatingFilterComponentParams: { suppressFilterButton: false },
          cellRenderer: 'flagCellRenderer',
        },
        {
          headerName: t('page.pitch_fees.column.product', 'Product'),
          headerClass: 'groupColumnHeader',
          children: [
            {
              field: 'product_type.lib',
              headerName: t(
                'page.pitch_fees.column.product_type',
                'Product Type',
              ),
              editable: isAllowed(this.roles.pitchFees, true),
              cellEditorSelector: () => {
                return {
                  component: 'agRichSelect',
                  params: {
                    values: this.REF_LABELS.RefContractingProductTypes,
                  },
                };
              },
              filter: 'agSetColumnFilter',
              filterParams: {},
              floatingFilterComponentParams: { suppressFilterButton: false },
              cellStyle: { 'font-weight': 600 },
            },
            {
              field: 'product_type.group',
              headerName: t('page.pitch_fees.column.product_group', 'Group'),
              cellClass: 'uneditable',
              editable: false,
              filter: 'agSetColumnFilter',
              filterParams: {},
              floatingFilterComponentParams: { suppressFilterButton: false },
            },
            {
              field: 'product_type.customer_pitches',
              headerName: t(
                'page.pitch_fees.column.customer_pitches',
                'Customer Pitch',
              ),
              cellClass: 'uneditable',
              editable: false,
              filter: 'agSetColumnFilter',
              filterParams: {},
              floatingFilterComponentParams: { suppressFilterButton: false },
              valueFormatter: (params) => (params.value ? '✅' : '❌'),
            },
          ],
        },
        {
          headerName: t('page.pitch_fees.column.quantity', 'Quantity'),
          headerClass: 'groupColumnHeader',
          children: [
            {
              field: 'quantity.budget',
              headerName: t('page.pitch_fees.column.quantity_budget', 'Budget'),
              editable: isAllowed(this.roles.pitchFees, true),
              filter: 'agSetColumnFilter',
              filterParams: {},
              floatingFilterComponentParams: { suppressFilterButton: false },
              cellClass: 'numberType',
            },
            {
              field: 'quantity.actual',
              headerName: t('page.pitch_fees.column.quantity_actual', 'Actual'),
              editable: isAllowed(this.roles.pitchFees, true),
              filter: 'agSetColumnFilter',
              filterParams: {},
              floatingFilterComponentParams: { suppressFilterButton: false },
              cellClass: 'numberType',
            },
          ],
        },
        {
          headerName: t(
            'page.pitch_fees.column.unit_price_excluding_tax',
            'Unit price excluding tax',
          ),
          headerClass: 'groupColumnHeader',
          children: [
            {
              field: 'unit_price_excluding_tax.budget',
              headerName: t(
                'page.pitch_fees.column.unit_price_excluding_tax_budget',
                'Budget',
              ),
              editable: isAllowed(this.roles.pitchFees, true),
              filter: 'agNumberColumnFilter',
              filterParams: {
                numAlwaysVisibleConditions: 2,
                defaultJoinOperator: 'OR',
              },
              suppressMenu: true,
              cellClass: 'currencyType',
              valueFormatter: (params) => {
                const value = params.value?.toString();
                return currencyFormat.format(value);
              },
              valueParser: (params) => {
                const regex = /^[0-9]+([,.][0-9]+)?$/;
                if (!regex.test(params.newValue)) {
                  return params?.oldValue || 0.0;
                }
                const value = `${params.newValue}`.replace(',', '.');
                return value;
              },
            },
            {
              field: 'unit_price_excluding_tax.actual',
              headerName: t(
                'page.pitch_fees.column.unit_price_excluding_tax_actual',
                'Actual',
              ),
              editable: isAllowed(this.roles.pitchFees, true),
              filter: 'agNumberColumnFilter',
              filterParams: {
                numAlwaysVisibleConditions: 2,
                defaultJoinOperator: 'OR',
              },
              suppressMenu: true,
              cellClass: 'currencyType',
              valueFormatter: (params) => {
                const value = params.value?.toString();
                return currencyFormat.format(value);
              },
              valueParser: (params) => {
                const regex = /^[0-9]+([,.][0-9]+)?$/;
                if (!regex.test(params.newValue)) {
                  return params?.oldValue || 0.0;
                }
                const value = `${params.newValue}`.replace(',', '.');
                return value;
              },
            },
          ],
        },
        {
          headerName: t(
            'page.pitch_fees.column.fees_excluding_tax',
            'Fees excluding tax',
          ),
          headerClass: 'groupColumnHeader',
          children: [
            {
              field: 'fees_excluding_tax_budget',
              headerName: t(
                'page.pitch_fees.column.fees_excluding_tax_budget',
                'Budget',
              ),
              editable: false,
              filter: 'agNumberColumnFilter',
              filterParams: {
                numAlwaysVisibleConditions: 2,
                defaultJoinOperator: 'OR',
              },
              suppressMenu: true,
              cellClass: ['currencyType', 'uneditable'],
              valueGetter: (params) => {
                return (
                  Number(
                    params.data?.unit_price_excluding_tax?.budget?.toString(),
                  ) * Number(params.data?.quantity?.budget)
                );
              },
              valueFormatter: (params) => {
                const value = params.value;
                return currencyFormat.format(value);
              },
              // valueFormatter: (params) => {
              //   const value = params.value?.toString();
              //   return currencyFormatruet.format(value);
              // },
              // valueParser: (params) => {
              //   const regex = /^[0-9]+([,.][0-9]+)?$/;
              //   if (!regex.test(params.newValue)) {
              //     return params?.oldValue || 0.0;
              //   }
              //   const value = `${params.newValue}`.replace(',', '.');
              //   return value;
              // },
            },
            {
              field: 'fees_excluding_tax_actual',
              headerName: t(
                'page.pitch_fees.column.fees_excluding_tax_actual',
                'Actual',
              ),
              editable: false,
              filter: 'agNumberColumnFilter',
              filterParams: {
                numAlwaysVisibleConditions: 2,
                defaultJoinOperator: 'OR',
              },
              suppressMenu: true,
              cellClass: ['currencyType', 'uneditable'],
              valueGetter: (params) => {
                return (
                  Number(
                    params.data?.unit_price_excluding_tax?.actual?.toString(),
                  ) * Number(params.data?.quantity?.actual)
                );
              },
              valueFormatter: (params) => {
                const value = params.value;
                return currencyFormat.format(value);
              },
              // valueFormatter: (params) => {
              //   const value = params.value?.toString();
              //   return currencyFormat.format(value);
              // },
              // valueParser: (params) => {
              //   const regex = /^[0-9]+([,.][0-9]+)?$/;
              //   if (!regex.test(params.newValue)) {
              //     return params?.oldValue || 0.0;
              //   }
              //   const value = `${params.newValue}`.replace(',', '.');
              //   return value;
              // },
            },
          ],
        },
        {
          headerName: t(
            'page.pitch_fees.column.fees_including_tax',
            'Fees including tax',
          ),
          headerClass: 'groupColumnHeader',
          children: [
            {
              field: 'fees_including_tax.budget',
              headerName: t(
                'page.pitch_fees.column.fees_including_tax_budget',
                'Budget',
              ),
              editable: false,
              filter: 'agNumberColumnFilter',
              filterParams: {
                numAlwaysVisibleConditions: 2,
                defaultJoinOperator: 'OR',
              },
              suppressMenu: true,
              cellClass: ['currencyType', 'uneditable'],
              valueGetter: (params) => {
                return (
                  Number(
                    params.data?.unit_price_excluding_tax?.budget?.toString(),
                  ) *
                    Number(params.data?.quantity?.budget) +
                  Number(
                    params.data?.unit_price_excluding_tax?.budget?.toString(),
                  ) *
                    Number(params.data?.quantity?.budget) *
                    Number(params.data?.tax_rate)
                );
              },
              valueFormatter: (params) => {
                const value = params.value;
                return currencyFormat.format(value);
              },
            },
            {
              field: 'fees_including_tax.actual',
              headerName: t(
                'page.pitch_fees.column.fees_including_tax_actual',
                'Actual',
              ),
              editable: false,
              filter: 'agNumberColumnFilter',
              filterParams: {
                numAlwaysVisibleConditions: 2,
                defaultJoinOperator: 'OR',
              },
              suppressMenu: true,
              cellClass: ['currencyType', 'uneditable'],
              valueGetter: (params) => {
                return (
                  Number(
                    params.data?.unit_price_excluding_tax?.actual?.toString(),
                  ) *
                    Number(params.data?.quantity?.actual) +
                  Number(
                    params.data?.unit_price_excluding_tax?.actual?.toString(),
                  ) *
                    Number(params.data?.quantity?.actual) *
                    Number(params.data?.tax_rate)
                );
              },
              valueFormatter: (params) => {
                const value = params.value;
                return currencyFormat.format(value);
              },
            },
          ],
        },
        {
          field: 'tax_rate',
          headerName: t('page.pitch_fees.column.tax_rate', 'Tax rate'),
          editable: isAllowed(this.roles.pitchFees, true),
          filter: 'agSetColumnFilter',
          filterParams: {},
          floatingFilterComponentParams: { suppressFilterButton: false },
          cellClass: 'percentType',
          valueFormatter: (params) => {
            const value = Number(params.value).toFixed(2);
            return value * 100 + ' %';
          },
          valueParser: (params) => {
            const regex = /^([,.][0-9]+)?$/;
            if (!regex.test(params.newValue) || params.newValue > 0.5) {
              return params?.oldValue || 0.0;
            }
            const value = `${params.newValue}`.replace(',', '.');
            return value;
          },
        },
        {
          headerName: `${t(
            'page.pitch_fees.column.provisional_pitch_fees',
            'Provisional Pitch Fees',
          )} ${new Date().getFullYear() + 1}`,
          headerClass: 'groupColumnHeader',
          children: [
            {
              headerName: t(
                'page.pitch_fees.column.provisional_excluding_tax',
                'Excluding tax',
              ),
              editable: false,
              filter: 'agSetColumnFilter',
              filterParams: {},
              floatingFilterComponentParams: { suppressFilterButton: false },
              cellClass: ['currencyType', 'uneditable'],
              valueGetter: (params) => {
                return (
                  Number(
                    params.data?.unit_price_excluding_tax?.actual?.toString(),
                  ) *
                    Number(params.data?.quantity?.actual) +
                  Number(
                    params.data?.unit_price_excluding_tax?.actual?.toString(),
                  ) *
                    Number(params.data?.quantity?.actual) *
                    Number(params.data?.provisional_rate)
                );
              },
              valueFormatter: (params) => {
                const value = params.value;
                return currencyFormat.format(value);
              },
            },
            {
              headerName: t(
                'page.pitch_fees.column.provisional_including_tax',
                'Including tax',
              ),
              editable: false,
              filter: 'agSetColumnFilter',
              filterParams: {},
              floatingFilterComponentParams: { suppressFilterButton: false },
              cellClass: ['currencyType', 'uneditable'],
              valueGetter: (params) => {
                return (
                  Number(
                    params.data?.unit_price_excluding_tax?.actual?.toString(),
                  ) *
                    Number(params.data?.quantity?.actual) +
                  Number(
                    params.data?.unit_price_excluding_tax?.actual?.toString(),
                  ) *
                    Number(params.data?.quantity?.actual) *
                    (Number(params.data?.tax_rate) +
                      Number(params.data?.provisional_rate))
                );
              },
              valueFormatter: (params) => {
                const value = params.value;
                return currencyFormat.format(value);
              },
            },
            {
              field: 'provisional_rate',
              headerName: t(
                'page.pitch_fees.column.provisional_rate',
                'Provisional rate',
              ),
              editable: isAllowed(this.roles.pitchFees, true),
              filter: 'agSetColumnFilter',
              filterParams: {},
              floatingFilterComponentParams: { suppressFilterButton: false },
              cellClass: 'percentType',
              valueFormatter: (params) => {
                const value = Number(params.value).toFixed(2);
                return value * 100 + ' %';
              },
              valueParser: (params) => {
                const regex = /^([,.][0-9]+)?$/;
                if (!regex.test(params.newValue) || params.newValue > 0.5) {
                  return params?.oldValue || 0.0;
                }
                const value = `${params.newValue}`.replace(',', '.');
                return value;
              },
            },
          ],
        },
        {
          field: 'notes',
          headerName: t('page.pitch_fees.column.notes', 'Notes'),
          editable: isAllowed(this.roles.pitchFees, true),
          filter: 'agSetColumnFilter',
          filterParams: {},
          floatingFilterComponentParams: { suppressFilterButton: false },
          tooltipField: 'notes',
        },
        {
          headerName: t('page.pitch_fees.column.status', 'Payment Status'),
          editable: false,
          filter: 'agSetColumnFilter',
          filterParams: {},
          pinned: 'right',
          floatingFilterComponentParams: { suppressFilterButton: false },
          cellClassRules: {
            'payment-critical': (params) =>
              params.value >= 0 && params.value < 20,
            'payment-warning': (params) =>
              params.value >= 20 && params.value < 50,
            'payment-moderate': (params) =>
              params.value >= 50 && params.value < 75,
            'payment-valid': (params) =>
              params.value >= 75 && params.value < 90,
            'payment-success': (params) => params.value >= 90,
          },
          valueGetter: (params) => {
            const actualTerms = params.data?.terms?.actual;
            let sum = actualTerms?.map((item) =>
              item?.locked ? item.value : 0,
            );

            sum =
              sum.length > 0 ? sum.reduce((a, b) => Number(a) + Number(b)) : 0;

            if (sum < 100) {
              return sum;
            } else return 100;
          },
          valueFormatter: (params) => {
            const value = params.value;
            return value === 100 ? 'Successfully' : `${value}%`;
          },
        },
      ],
      methods,
      // namespace for permissions.
      'site',
    );
  }

  getPitchesListConfiguration(methods, t) {
    return this.getConfiguration(
      [
        {
          headerName: t('page.pitches.column.campsite.title', 'Campsite'),
          headerClass: 'groupColumnHeader',
          children: [
            {
              field: 'campsite._id',
              headerName: t(
                'page.pitches.column.goToCampsite',
                'Go to campsite',
              ),
              filterParams: {},
              floatingFilterComponentParams: { suppressFilterButton: false },
              valueFormatter: (params) => `/site/${params.value}`,
              width: 150,
              cellRenderer: 'linkCellRenderer',
            },
            {
              field: 'campsite.lib',
              headerName: t('page.pitches.column.campsite.name', 'Name'),
              filter: 'agSetColumnFilter',
              filterParams: {},
              floatingFilterComponentParams: { suppressFilterButton: false },
            },
            {
              field: 'campsite.code_group',
              headerName: t(
                'page.pitches.column.campsite.group_code',
                'Group code',
              ),
              filter: 'agSetColumnFilter',
              filterParams: {},
              floatingFilterComponentParams: { suppressFilterButton: false },
              cellStyle: { 'font-weight': 600 },
            },
            {
              field: 'campsite.loca.country',
              headerName: t(
                'page.pitches.column.campsite.loca.country',
                'Country',
              ),
              columnGroupShow: 'open',
              filter: 'agSetColumnFilter',
              filterParams: {},
              floatingFilterComponentParams: { suppressFilterButton: false },
              cellRenderer: 'flagCellRenderer',
            },
            {
              field: 'campsite.loca.region',
              headerName: t(
                'page.pitches.column.campsite.loca.region',
                'Region',
              ),
              columnGroupShow: 'open',
              filter: 'agSetColumnFilter',
              filterParams: {},
              floatingFilterComponentParams: { suppressFilterButton: false },
            },
            {
              field: 'campsite.loca.city',
              headerName: t('page.pitches.column.campsite.loca.city', 'City'),
              columnGroupShow: 'open',
              filter: 'agSetColumnFilter',
              filterParams: {},
              floatingFilterComponentParams: { suppressFilterButton: false },
            },
            {
              field: 'campsite.is_propre',
              headerName: t('page.pitches.column.own_site', 'Own site'),
              columnGroupShow: 'open',
              filter: 'agSetColumnFilter',
              filterParams: {},
              floatingFilterComponentParams: { suppressFilterButton: false },
              valueFormatter: (params) => (params.value ? '✅' : '❌'),
            },
            {
              field: 'campsite.chain.lib',
              headerName: t('page.pitches.column.chain', 'Group/Chain'),
              columnGroupShow: 'open',
              filter: 'agSetColumnFilter',
              filterParams: {},
              floatingFilterComponentParams: { suppressFilterButton: false },
            },
          ],
        },
        {
          headerName: t('page.pitches.column.pitch.header', 'Pitch'),
          headerClass: 'groupColumnHeader',
          children: [
            {
              field: 'label',
              headerName: t('page.pitches.column.label', 'Label'),
              // editable: isAllowed(this.roles.pitchFees, true),
              filter: 'agSetColumnFilter',
              filterParams: {},
              floatingFilterComponentParams: { suppressFilterButton: false },
              editable: isAllowed(this.roles.pitches, true),
            },
            {
              field: 'width',
              headerName: t('page.pitches.column.width', 'Width'),
              filter: 'agSetColumnFilter',
              filterParams: {},
              floatingFilterComponentParams: { suppressFilterButton: false },
              valueFormatter: (params) =>
                params.value ? `${params.value} m` : null,
              editable: isAllowed(this.roles.pitches, true),
            },
            {
              field: 'length',
              headerName: t('page.pitches.column.length', 'Length'),
              filter: 'agSetColumnFilter',
              filterParams: {},
              floatingFilterComponentParams: { suppressFilterButton: false },
              valueFormatter: (params) =>
                params.value ? `${params.value} m` : null,
              editable: isAllowed(this.roles.pitches, true),
            },
            {
              headerName: t('page.pitches.column.dimension', 'Dimension'),
              filter: 'agSetColumnFilter',
              filterParams: {},
              floatingFilterComponentParams: { suppressFilterButton: false },
              valueGetter: (params) => {
                return params.data?.width && params.data?.length
                  ? Number(params.data?.width) * Number(params.data?.length)
                  : null;
              },
              valueFormatter: (params) =>
                params.value ? `${params.value} m` : null,
            },
            {
              field: 'accommodation_type.lib',
              headerName: t(
                'page.pitches.column.sales.accommodation_type',
                'Accommodation type',
              ),
              filter: 'agSetColumnFilter',
              filterParams: {},
              editable: isAllowed(this.roles.pitches, true),
              floatingFilterComponentParams: { suppressFilterButton: false },
              cellEditorSelector: () => {
                return {
                  component: 'agRichSelect',
                  params: {
                    values: this.REF_LABELS.RefSalesAccommodationType,
                  },
                };
              },
            },
            {
              field: 'ambiance.lib',
              headerName: t('page.pitches.column.sales.ambiance', 'Ambiance'),
              filter: 'agSetColumnFilter',
              filterParams: {},
              editable: isAllowed(this.roles.pitches, true),
              floatingFilterComponentParams: { suppressFilterButton: false },
              cellEditorSelector: () => {
                return {
                  component: 'agRichSelect',
                  params: {
                    values: this.REF_LABELS.RefSalesAmbiance,
                  },
                };
              },
            },
            {
              field: 'shape.lib',
              headerName: t('page.pitches.column.sales.shape', 'Shape'),
              filter: 'agSetColumnFilter',
              filterParams: {},
              editable: isAllowed(this.roles.pitches, true),
              floatingFilterComponentParams: { suppressFilterButton: false },
              cellEditorSelector: () => {
                return {
                  component: 'agRichSelect',
                  params: {
                    values: this.REF_LABELS.RefSalesShape,
                  },
                };
              },
            },
            {
              field: 'delimitation.lib',
              headerName: t(
                'page.pitches.column.sales.delimitation',
                'Delimitation',
              ),
              filter: 'agSetColumnFilter',
              filterParams: {},
              editable: isAllowed(this.roles.pitches, true),
              floatingFilterComponentParams: { suppressFilterButton: false },
              cellEditorSelector: () => {
                return {
                  component: 'agRichSelect',
                  params: {
                    values: this.REF_LABELS.RefSalesDelimitation,
                  },
                };
              },
            },
            {
              field: 'accessibility.lib',
              headerName: t(
                'page.pitches.column.sales.accessibility',
                'Accessibility',
              ),
              filter: 'agSetColumnFilter',
              filterParams: {},
              editable: isAllowed(this.roles.pitches, true),
              floatingFilterComponentParams: { suppressFilterButton: false },
              cellEditorSelector: () => {
                return {
                  component: 'agRichSelect',
                  params: {
                    values: this.REF_LABELS.RefSalesAccessibility,
                  },
                };
              },
            },
            {
              field: 'amperage',
              headerName: t('page.pitches.column.sales.amperage', 'Amperage'),
              editable: isAllowed(this.roles.pitches, true),
              filter: 'agSetColumnFilter',
              filterParams: {},
              floatingFilterComponentParams: { suppressFilterButton: false },
              valueFormatter: (params) =>
                params.value ? `🔌 ${params.value}A` : '',
            },
            {
              field: 'serviceable',
              headerName: t(
                'page.pitches.column.sales.serviceable',
                'Serviceable',
              ),
              editable: isAllowed(this.roles.pitches, true),
              filter: 'agSetColumnFilter',
              filterParams: {},
              floatingFilterComponentParams: { suppressFilterButton: false },
              cellEditorSelector: () => {
                return {
                  component: 'agRichSelect',
                  params: {
                    values: [true, false],
                  },
                };
              },
              valueFormatter: (params) => (params.value ? 'YES' : 'NO'),
              cellClass: (params) =>
                params.value ? 'default_boolean_true' : 'default_boolean_false',
            },
            {
              field: 'serviced',
              headerName: t('page.pitches.column.sales.serviced', 'Serviced'),
              editable: isAllowed(this.roles.pitches, true),
              filter: 'agSetColumnFilter',
              filterParams: {},
              floatingFilterComponentParams: { suppressFilterButton: false },
              cellEditorSelector: () => {
                return {
                  component: 'agRichSelect',
                  params: {
                    values: [true, false],
                  },
                };
              },
              valueFormatter: (params) => (params.value ? 'YES' : 'NO'),
              cellClass: (params) =>
                params.value ? 'default_boolean_true' : 'default_boolean_false',
            },
            {
              field: 'status.lib',
              headerName: t('page.pitches.column.sales.status', 'Status'),
              filter: 'agSetColumnFilter',
              filterParams: {},
              editable: (params) => !params?.data?.unit && true,
              floatingFilterComponentParams: { suppressFilterButton: false },
              cellEditorSelector: () => {
                return {
                  component: 'agRichSelect',
                  params: {
                    values: this.REF_LABELS.RefSalesStatus,
                  },
                };
              },
              cellRenderer: 'tagCellRenderer',
            },
          ],
        },
        {
          headerName: t('page.pitches.column.unit_model.header', 'Unit'),
          headerClass: 'groupColumnHeader',
          children: [
            {
              field: 'unit._id',
              headerName: t('page.pitches.column.goToUnit', 'Go to unit'),
              filterParams: {},
              floatingFilterComponentParams: { suppressFilterButton: false },
              valueFormatter: (params) => `/mh/${params.value}`,
              width: 150,
              cellRenderer: 'linkCellRenderer',
            },
            {
              field: 'unit.barcode',
              headerName: t('page.pitches.column.barcode', 'Barcode'),
              filter: 'agSetColumnFilter',
              filterParams: {},
              floatingFilterComponentParams: { suppressFilterButton: false },
              cellStyle: { 'font-weight': 600 },
            },
            {
              field: 'unit.commercial_type.lib',
              headerName: t(
                'page.pitches.column.commercial_type.lib',
                'Commercial type',
              ),
              filter: 'agSetColumnFilter',
              filterParams: {},
              floatingFilterComponentParams: { suppressFilterButton: false },
            },
            {
              field: 'unit.commercial_type.code',
              headerName: t(
                'page.pitches.column.commercial_type.code',
                'Commercial code',
              ),
              filter: 'agSetColumnFilter',
              filterParams: {},
              floatingFilterComponentParams: { suppressFilterButton: false },
            },
            {
              field: 'unit.mh_model.lib',
              headerName: t('page.units.column.unit_model.lib', 'Label'),
              filter: 'agSetColumnFilter',
              filterParams: {},
              floatingFilterComponentParams: { suppressFilterButton: false },
            },
            {
              field: 'unit.mh_model.type.lib',
              headerName: t('page.units.column.unit_model.unit_type', 'Type'),
              filter: 'agSetColumnFilter',
              filterParams: {},
              floatingFilterComponentParams: { suppressFilterButton: false },
              cellRenderer: 'unitTypeCellRenderer',
            },
            {
              field: 'unit.mh_model.manufacturer.lib',
              headerName: t(
                'page.units.column.unit_model.manufacturer',
                'Manufacturer',
              ),
              columnGroupShow: 'open',
              filter: 'agSetColumnFilter',
              filterParams: {},
              floatingFilterComponentParams: { suppressFilterButton: false },
            },
            {
              field: 'unit.mh_model.manufacturer_model.lib',
              headerName: t(
                'page.units.column.unit_model.manufacturer_model',
                'Manufacturer model',
              ),
              columnGroupShow: 'open',
              filter: 'agSetColumnFilter',
              filterParams: {},
              floatingFilterComponentParams: { suppressFilterButton: false },
            },
            {
              field: 'unit.mh_model.vintage',
              headerName: t('page.units.column.unit_model.vintage', 'Vintage'),
              columnGroupShow: 'open',
              filter: 'agSetColumnFilter',
              filterParams: {},
              floatingFilterComponentParams: { suppressFilterButton: false },
            },
            {
              field: 'unit.mh_model.length',
              headerName: t(
                'page.units.column.unit_model.length',
                'Length (m)',
              ),
              columnGroupShow: 'open',
              hide: true,
              filter: 'agSetColumnFilter',
              filterParams: {},
              floatingFilterComponentParams: { suppressFilterButton: false },
            },
            {
              field: 'unit.mh_model.width',
              headerName: t('page.units.column.unit_model.width', 'Width (m)'),
              columnGroupShow: 'open',
              hide: true,
              filter: 'agSetColumnFilter',
              filterParams: {},
              floatingFilterComponentParams: { suppressFilterButton: false },
            },
          ],
        },
        {
          field: 'use_type.lib',
          headerName: t('page.units.column.use_type', 'Use type'),
          columnGroupShow: 'open',
          filter: 'agSetColumnFilter',
          filterParams: {},
          cellRenderer: 'tagCellRenderer',
          floatingFilterComponentParams: { suppressFilterButton: false },
        },
        {
          field: '_id',
          headerName: t('page.pitches.column.id', 'Id'),
          filter: 'agSetColumnFilter',
          filterParams: {},
          floatingFilterComponentParams: { suppressFilterButton: false },
          hide: true,
        },
      ],
      methods,
      // namespace for permissions.
      'pitches',
    );
  }
}
