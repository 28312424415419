import get from 'lodash/get';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import Tippy from '@tippyjs/react';
const localizedFormat = require('dayjs/plugin/localizedFormat');
dayjs.extend(localizedFormat);
// Layout
import Layout from '@layout/default';

// Components
import ContentHeader from '@components/headers/ContentHeader';
import RedirectionButton from '@components/buttons/RedirectionButton';
import { Linear } from '@components/Loader';
import GoogleMap from '@components/common/GoogleMap';
import Weather from '@components/campsites/Weather';
import Arrow from '@components/icons/Arrow';
import Tree from '@components/icons/Tree';
import BaseNavigation from '@components/navigations/BaseNavigation';
import ArticleHeader from '@components/headers/ArticleHeader';
import locationJSON from '@assets/lottie/location.json';
import contactJSON from '@assets/lottie/contact.json';
import detailsJSON from '@assets/lottie/details.json';
import KeyFigures from '@components/campsites/KeyFigures';
import Contacts from '@components/campsites/Contacts';
import DataTable from '@components/campsites/DataTable';
import InAndOut from '@components/campsites/InAndOut';
import Pitches from '@components/campsites/Pitches';

// Logos
import HomairLogo from '@assets/images/common/brands/homair_full.png';
import EurocampLogo from '@assets/images/common/brands/eurocamp_full.png';
import RoanLogo from '@assets/images/common/brands/roan_full.png';
import Homair from '@assets/images/common/brands/homair.png';
import Eurocamp from '@assets/images/common/brands/eurocamp.png';
import Roan from '@assets/images/common/brands/roan.png';
import Vacanceselect from '@assets/images/common/brands/vacanceselect.png';

// Store
import {
  sendFlashMessage,
  getCampsiteWeather,
} from '@store/actions/action-common';
import { fetchSite } from '@store/actions/action-site';

// Helpers
import { debugSite } from '@lib/debug';

import style from './style.module.scss';

class SiteDetail extends React.Component {
  static propTypes = {
    dispatch: PropTypes.func,
    t: PropTypes.func,
    history: PropTypes.object,
    match: PropTypes.shape({
      params: PropTypes.shape({
        id: PropTypes.string,
      }),
    }),
  };

  constructor(props) {
    super(props);

    this.state = {
      lists: [],
      counters: {
        french: 0,
        brit: 0,
        dutch: 0,
      },
      weather: null,
      contract_managers: [],
      currentIndex: 0,
      contactsCurrentIndex: 0,
    };

    this.onTabChanged = this.onTabChanged.bind(this);
    this.backToList = this.backToList.bind(this);
    this.gotoMhSites = this.gotoMhSites.bind(this);
    this.changeContactTab = this.changeContactTab.bind(this);
    this.changeTab = this.changeTab.bind(this);
  }

  componentDidMount() {
    debugSite(
      `SiteDetail: componentDidMount, loading site ${this.props.match.params.id}...`,
    );

    //                   label: get(site, 'contract_managers'),

    // Fetching data by its ID.
    this.props
      .dispatch(fetchSite(this.props.match.params.id))
      .then((site) => {
        // Contacts
        // On Site
        const onSiteContacts = [];
        get(site, 'homair_contact.lib') &&
          onSiteContacts.push({
            icon: HomairLogo,
            label: get(site, 'homair_contact.lib'),
            email: get(site, 'homair_contact.email'),
            phone: get(site, 'homair_contact.phone'),
          });
        get(site, 'eurocamp_contact.lib') &&
          onSiteContacts.push({
            icon: EurocampLogo,
            label: get(site, 'eurocamp_contact.lib'),
            email: get(site, 'eurocamp_contact.email'),
            phone: get(site, 'eurocamp_contact.phone'),
          });
        get(site, 'roan_contact.lib') &&
          onSiteContacts.push({
            icon: EurocampLogo,
            label: get(site, 'roan_contact.lib'),
            email: get(site, 'roan_contact.email'),
            phone: get(site, 'roan_contact.phone'),
          });

        // AM
        const amContacts = [];
        get(site, 'french_brand_area.lib') &&
          amContacts.push({
            icon: HomairLogo,
            label: get(site, 'french_brand_area.lib'),
            email: get(site, 'french_brand_area.hv_am_email'),
            phone: get(site, 'french_brand_area.hv_am_phone'),
            senior_email: get(site, 'french_brand_area.hv_pdm_email'),
          });
        get(site, 'brit_brand_area.lib') &&
          amContacts.push({
            icon: EurocampLogo,
            label: get(site, 'brit_brand_area.lib'),
            email: get(site, 'brit_brand_area.bb_email'),
            phone: get(site, 'brit_brand_area.bb_phone'),
            senior_email: get(site, 'brit_brand_area.bb_pdm_email'),
          });
        get(site, 'dutch_brand_area.lib') &&
          amContacts.push({
            icon: RoanLogo,
            label: get(site, 'dutch_brand_area.lib'),
            email: get(site, 'dutch_brand_area.r_am_email'),
            phone: get(site, 'dutch_brand_area.r_am_phone'),
            senior_email: get(site, 'dutch_brand_area.r_pdm_email'),
          });

        const RMTContacts = get(site, 'rmt_regions').map((rmt) => {
          return {
            label:
              rmt?.members &&
              `${rmt?.members?.firstname} ${rmt?.members?.lastname} | ${rmt?.members?.language}`,
            email: rmt?.members && rmt?.members?.email,
            phone: rmt?.members && rmt?.members?.phone,
            senior_email: rmt?.members && rmt?.members?.rmm?.email,
            cmm_email: rmt?.members && rmt?.members?.rmm?.cmm?.email,
          };
        });

        const contractManagersContacts = get(site, 'contract_managers').map(
          (manager) => {
            return {
              label: manager?.lib,
              email: manager?.email,
              phone: manager?.phone.replaceAll(/ /g, ''),
            };
          },
        );

        this.setState({
          contacts: [
            {
              label: this.props.t('common.campsite.tab.on_site', 'On site'),
              content: onSiteContacts,
            },
            {
              label: this.props.t(
                'common.campsite.tab.area_managers',
                'Area managers',
              ),
              content: amContacts,
            },
            {
              label: this.props.t('common.campsite.tab.rmt', 'RMT'),
              content: RMTContacts,
            },
            {
              label: this.props.t(
                'common.campsite.tab.contract_managers',
                'Contract managers',
              ),
              content: contractManagersContacts,
            },
          ],
        });

        this.setState({
          data: [
            {
              label: this.props.t('form.campsite.loca.country', 'Country'),
              value: get(site, 'loca.country') || '-',
            },
            {
              label: this.props.t('form.campsite.loca.region', 'Region'),
              value: get(site, 'loca.region') || '-',
            },
            {
              label: this.props.t('form.campsite.loca.city', 'City'),
              value: get(site, 'loca.city') || '-',
              important: true,
            },
            {
              label: this.props.t('form.campsite.zip_code', 'Zip code'),
              value: get(site, 'zip_code') || '-',
            },
            {
              label: this.props.t('form.campsite.address', 'Address'),
              value: get(site, 'address') || '-',
            },
            {
              label: this.props.t('form.campsite.lat', 'Latitude'),
              value: get(site, 'gps.lat') || '-',
              important: true,
            },
            {
              label: this.props.t('form.campsite.lng', 'Longitude'),
              value: get(site, 'gps.lon') || '-',
              important: true,
            },
            {
              label: this.props.t('form.campsite.own_campsite', 'Own campsite'),
              value: get(site, 'is_propre') ? 'Yes' : 'No',
            },
            {
              label: this.props.t('form.campsite.chain', 'Chain'),
              value: get(site, 'chain.lib') || '-',
            },
            {
              label: this.props.t('form.campsite.segment', 'Segment'),
              value: get(site, 'segment.lib') || '-',
            },
            {
              label: this.props.t('form.campsite.depot', 'Depot'),
              value: get(site, 'depot.code') || get(site, 'depot') || '-',
            },
            {
              label: this.props.t(
                'form.campsite.in_season_depot',
                'In season depot',
              ),
              value: get(site, 'in_season_depot') || '-',
            },
            {
              label: this.props.t('form.campsite.group_code', 'Group code'),
              value: get(site, 'code_group') || '-',
              important: true,
            },
            {
              label: this.props.t('form.campsite.abc_cluster', 'ABC Cluster'),
              value: get(site, 'abc_cluster') || '-',
            },
            {
              label: this.props.t(
                'form.campsite.campsite_opening_date',
                'Opening date',
              ),
              value: dayjs(get(site, 'opening_date')).format('ll') || '-',
            },
            {
              label: this.props.t(
                'form.campsite.campsite_closing_date',
                'Closing date',
              ),
              value: dayjs(get(site, 'closing_date')).format('ll') || '-',
            },
            {
              label: this.props.t(
                'form.campsite.brand_opening_date',
                'Brand opening date',
              ),
              value: dayjs(get(site, 'brand_opening_date')).format('ll') || '-',
            },
            {
              label: this.props.t(
                'form.campsite.brand_closing_date',
                'Brand closing date',
              ),
              value: dayjs(get(site, 'brand_closing_date')).format('ll') || '-',
            },
            {
              label: this.props.t(
                'form.campsite.partner_deadline',
                'Partner deadline',
              ),
              value: dayjs(get(site, 'partner_deadline')).format('ll') || '-',
            },
            {
              label: this.props.t(
                'form.campsite.brand_deadline',
                'Brand deadline',
              ),
              value: dayjs(get(site, 'brand_deadline')).format('ll') || '-',
            },
          ],
          keyFigures: [
            {
              icon: Eurocamp,
              brand: 'Eurocamp',
              description: this.props.t(
                'common.brand.british_brand',
                'British brand',
              ),
              value: get(site, 'afCount') + get(site, 'ecCount'),
            },
            {
              icon: Homair,
              brand: 'Homair',
              description: this.props.t(
                'common.brand.french_brand',
                'French brand',
              ),
              value: get(site, 'hvCount') + get(site, 'pCount'),
            },
            {
              icon: Roan,
              brand: 'Roan',
              description: this.props.t(
                'common.brand.dutch_brand',
                'Dutch brand',
              ),
              value: get(site, 'rCount'),
            },
            {
              icon: Vacanceselect,
              brand: 'Vacanceselect',
              description: this.props.t(
                'common.brand.french_brand',
                'French brand',
              ),
              value: get(site, 'vsCount'),
            },
          ],
        });

        // ? Get current weather
        this.getWeather(get(site, 'gps.lat'), get(site, 'gps.lon'));
      })
      .catch((err) => {
        // Custom message for 404 only.
        if (err && err.code === 404) {
          err.message = this.props.t('form.campsite.error.not_found', {
            _id: this.props.match.params.id,
          });
        }
        this.props.dispatch(sendFlashMessage(`${err}`, 'error'));
        this.props.history.push('/site');
      });
  }

  async getWeather(lat, lon) {
    const weather = await this.props.dispatch(getCampsiteWeather(lat, lon));
    this.setState({ weather: weather });
  }

  onTabChanged = (event, tab) => {
    this.setState({ tab });
  };

  backToList() {
    this.props.history.push('/site');
  }

  gotoMhSites() {
    this.props.history.push(`/mh?site._id=${this.props.match.params.id}`);
  }

  renderTitle() {
    const { site } = this.props;
    return { __html: site.lib };
  }

  changeTab(index) {
    this.setState({ currentIndex: index });
  }

  changeContactTab(index) {
    this.setState({ contactsCurrentIndex: index });
  }

  render() {
    const { submitting, t, classes, site, ...otherProps } = this.props;
    const {
      weather,
      currentIndex,
      contacts,
      contactsCurrentIndex,
      keyFigures,
      data,
    } = this.state;
    const days = weather?.daily?.slice(0, 5);
    debugSite('SiteDetail : render, site= ', site);

    // ! Document Title
    document.title = `EUROPEAN CAMPING GROUP | ${this.props.t(
      'page.campsite.document_title',
      'Campsite',
    )} ${get(site, 'code_group')}`;

    // early render if no props found...
    if (!site.lib) {
      return (
        <Layout {...this.props}>
          <Linear isFetching={!site.lib} {...this.props} />
        </Layout>
      );
    }

    let marker;
    if (site.gps) {
      marker = {
        lat: Number(site.gps.lat),
        lng: Number(site.gps.lon),
        name: site.lib,
        title: `${site.lib} - ${get(site, 'loca.city')}, ${get(
          site,
          'loca.region',
        )}, ${get(site, 'loca.country')}`,
      };
    }
    let targets = [];
    if (get(site, 'capacity_target')) {
      targets = Object.keys(get(site, 'capacity_target')).filter(
        (target) => target !== '_id',
      );
    }

    const tabs = [
      {
        label: t('form.campsite.tab.general', 'General'),
        content: () => (
          <div className={style['campsite-content']}>
            <div>
              <article className={style['campsite-article']}>
                <div>
                  <ArticleHeader label="Contacts" lottieJSON={contactJSON} />
                  <div>
                    <h3>{t('form.campsite.tab.contact', 'Contacts')}</h3>
                    <BaseNavigation
                      items={contacts.map((contact) => contact.label)}
                      currentIndex={contactsCurrentIndex}
                      updateIndex={this.changeContactTab}
                    />

                    {contacts[contactsCurrentIndex].content.length > 0 ? (
                      <Contacts
                        items={contacts[contactsCurrentIndex].content}
                      />
                    ) : (
                      <p className={style['unavailable']}>
                        {t('common.label.unavailable', 'Not available.')}
                      </p>
                    )}
                  </div>
                </div>
              </article>

              <article className={style['campsite-article']}>
                <div>
                  <ArticleHeader
                    label={`${get(site, 'loca.city') || 'N/D'}, ${
                      get(site, 'loca.region') || 'N/D'
                    }, ${get(site, 'loca.country') || 'N/D'}`}
                    description={`${get(site, 'gps.lat') || 'N/D'}, ${
                      get(site, 'gps.lon') || 'N/D'
                    }`}
                    lottieJSON={locationJSON}
                  />
                  {weather?.current && (
                    <div>
                      <h3>
                        {t('form.campsite.tab.forecast', 'Weather forecast')}
                      </h3>
                      <Weather days={days} current={weather?.current} />
                    </div>
                  )}

                  <div className={style['campsite-map-container']}>
                    <h3>{t('form.campsite.tab.map', 'Map')}</h3>
                    <div>
                      {marker ? (
                        <GoogleMap /*GoogleApiWrapper*/ points={[marker]} />
                      ) : null}
                    </div>
                  </div>
                </div>
              </article>
            </div>

            <article className={style['campsite-article']}>
              <div>
                <ArticleHeader
                  label={t(
                    'form.campsite.tab.campsite_detail',
                    'Campsite details',
                  )}
                  lottieJSON={detailsJSON}
                />
                {keyFigures.some((item) => item?.value > 0) ? (
                  <div>
                    <div className={style['campsite-linked-subtitle']}>
                      <h3>
                        {t('common.label.available_units', 'Available units')}
                      </h3>
                      <Tippy
                        content={t(
                          'common.action.see_whole_fleet',
                          'See whole fleet',
                        )}
                        placement="top"
                      >
                        <button type="button" onClick={this.gotoMhSites}>
                          <Arrow />
                        </button>
                      </Tippy>
                    </div>
                    <KeyFigures items={keyFigures} />
                  </div>
                ) : null}

                {targets.map((target, index) => (
                  <div key={index}>
                    <div className={style['campsite-linked-subtitle']}>
                      <h3>
                        Capacity target - {target.toUpperCase()}{' '}
                        {target === 'ecg' && '(excl. ROAN)'}
                      </h3>
                    </div>
                    <div className={style['capacity-target-row']}>
                      <div>
                        <Tippy content={`Incoming`}>
                          <span className={style['capacity-target-row-header']}>
                            📥
                          </span>
                        </Tippy>
                        <span>{get(site, 'incomingUnits')?.length}</span>
                      </div>
                      <div>
                        <Tippy content={`Outgoing`}>
                          <span className={style['capacity-target-row-header']}>
                            📤
                          </span>
                        </Tippy>
                        <span>{get(site, 'outgoingUnits')?.length}</span>
                      </div>
                      <div>
                        <Tippy content={`Capacity target`}>
                          <span className={style['capacity-target-row-header']}>
                            🎯
                          </span>
                        </Tippy>
                        <span>
                          {get(site, `capacity_target.${target}`) || 0}
                        </span>
                      </div>
                      <div>
                        <Tippy content={`Actual capacity`}>
                          <span className={style['capacity-target-row-header']}>
                            📋
                          </span>
                        </Tippy>
                        <span
                          className={
                            get(site, `capacity_target.${target}`) -
                              get(site, 'incomingUnits')?.length +
                              get(site, 'outgoingUnits')?.length >=
                            0
                              ? style['positive']
                              : style['negative']
                          }
                        >
                          {get(site, `capacity_target.${target}`) -
                            get(site, 'incomingUnits')?.length +
                            get(site, 'outgoingUnits')?.length}
                        </span>
                      </div>
                    </div>
                  </div>
                ))}

                <div>
                  <h3>
                    {t('form.campsite.tab.characteristics', 'Characteristics')}
                  </h3>
                  <DataTable data={data} />
                </div>
              </div>
            </article>
          </div>
        ),
      },
      {
        label: t('form.campsite.tab.in_and_out', 'In and out'),
        content: () => (
          <InAndOut
            incoming={get(site, 'incomingUnits')}
            outgoing={get(site, 'outgoingUnits')}
          />
        ),
      },
      {
        label: t('form.campsite.tab.pitches', 'Pitches'),
        content: () => (
          <Pitches
            pricing={get(site, 'pitch.pricing')}
            information={get(site, 'pitch.information')}
          />
        ),
      },
    ];

    return (
      data?.length > 0 && (
        <Layout {...otherProps}>
          <div className={style['campsite-details']}>
            <div className={style['upper-header']}>
              <RedirectionButton
                icon={Arrow}
                action={this.backToList}
                label={t(
                  'common.action.go_back_campsites',
                  'Go back to campsites section',
                )}
              />
            </div>

            <ContentHeader
              icon={Tree}
              title={`${get(site, 'lib')} ${get(site, 'code_group')}`}
              subtitle={`Update at ${dayjs(get(site, 'updated_at')).format(
                'lll',
              )}`}
            />

            <section className={style['campsite-section']}>
              <div className={style['campsite-section-base-navigation']}>
                <BaseNavigation
                  items={tabs.map((tab) => tab.label)}
                  currentIndex={currentIndex}
                  updateIndex={this.changeTab}
                />
              </div>

              {tabs[currentIndex].content()}
            </section>
          </div>
        </Layout>
      )
    );
  }
}

const mapStateToProps = (state) => ({
  // Init form data from the Model in our store.
  site: state.site,
  refDatas: state.refDatas.data,
});
export default connect(mapStateToProps)(withTranslation()(SiteDetail));
