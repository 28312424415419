import style from './style.module.scss';

export default (props) => {
  return (
    <div className={style['banner']}>
      <p>
        The current Asset Register version is <strong>outdated</strong>. While
        it remains accessible for now, we recommend transitioning to the{' '}
        <a href="https://asset-register.ecg-support.com" target="_blank">
          new version
        </a>
        . For login assistance, please contact{' '}
        <a href="https://ecgit.freshservice.com/a/tickets/new" target="_blank">
          support
        </a>
        .
      </p>
    </div>
  );
};
