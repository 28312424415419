import { sendFlashMessage } from '@store/actions/action-common';
import { getAPIToken } from '../../security/User';
import fetch from 'node-fetch';
import { debugStore } from '../../lib/debug';
import { fixRefValues } from '../../lib/utils';

const BSON = require('bson');

// ! Worker
const mobihomeWorker = new Worker('@workers/mobilhome.worker.js', {
  type: 'module',
});

// ------------------------------------------------------------------------
// MOBILHOMES LIST
// ------------------------------------------------------------------------
export var REQUEST_MH_LIST = 'REQUEST_MH_LIST';
export var RECEIVE_MH_LIST = 'RECEIVE_MH_LIST';

//
// Before calling API.
//
export function requestMhList() {
  return {
    type: REQUEST_MH_LIST,
  };
}

//
// After receiving data from API.
//
export function receiveMhList() {
  // Add selectedSeason inside data for the grid

  return {
    type: RECEIVE_MH_LIST,
    receivedAt: Date.now(),
  };
}

export var SAVE_PITCH_FEES = 'SAVE_PITCH_FEES';

//
// After receiving data from API.
//
export function saveNewPitchFees({ _id, pitchFees }) {
  return () => {
    const API_TOKEN = getAPIToken();

    debugStore('saveNewPitchFees: _id=', _id);
    debugStore('saveNewPitchFees: pitchFees=', pitchFees);

    const url = `${process.env.REACT_APP_API_URL}/api/mobilhomes/${_id}`;

    const options = {
      method: 'put',
      body: JSON.stringify({ pitch_fees: pitchFees }),
      headers: {
        'Content-Type': 'application/json',
        'x-access-token': API_TOKEN,
      },
    };

    debugStore('saveNewPitchFees: url=', url, options);

    return fetch(url, options)
      .then((response) => {
        if (response.status >= 400) {
          console.error(
            `saveNewPitchFees: error status=${response.status}, statusText=${response.statusText}, for url=${url}`,
          );
        }

        // Better JSON parsing error handling.
        return response.text();
      })
      .then((text) => {
        try {
          return JSON.parse(text);
        } catch (err) {
          console.error(
            `saveNewPitchFees: JSON parse error from ${url} with response=${text}, err=${err}`,
          );
          throw Error(`Error while saving MH transfer, err=${err}`);
        }
      })
      .then((json) => {
        // dispatch(receiveMh(json))
        console.log('Pitch Fees saved ! ', json);
        // return json
      });
  };
}

//
// Fetch the mobilhomes datas from API.
// CAUTION: this function handle 2 cases, standard & one with stream if the nb of lines is > 5000
//
export function fetchMhList(query) {
  // Thunk middleware knows how to handle functions.
  // It passes the dispatch method as an argument to the function,
  // thus making it able to dispatch actions itself.
  return async function (dispatch) {
    const API_TOKEN = getAPIToken();

    // First dispatch: the app state is updated to inform that the API call is starting.
    debugStore('fetchMhList: BEFORE REQUEST RESULT DISPATCH !!!!');
    dispatch(requestMhList());

    // The function called by the thunk middleware can return a value,
    // that is passed on as the return value of the dispatch method.

    // In this case, we return a promise to wait for.
    // This is not required by thunk middleware, but it is convenient for us.

    const url = `${process.env.REACT_APP_API_URL}/api/mobilhomes${
      query && query
    }`;

    // add credentials (ie the whole bunch of cookies...) ONLY for websites that need it !
    const options = {
      method: 'GET',
      headers: {
        'x-access-token': API_TOKEN,
        'Content-Encoding': 'gzip',
      },
    };

    debugStore('fetchMhList: url=', url, options);

    try {
      const response = await fetch(url, options);

      if (response.status >= 400) {
        console.error(
          `fetchMhList: error status=${response.status}, statusText=${response.statusText}, for url=${url}`,
        );
      }

      let arrayResult = [];

      const reader = await response.arrayBuffer();
      BSON.deserializeStream(
        reader,
        0,
        response.headers.get('nbResults'),
        arrayResult,
        0,
      );

      // ? Get uncached mobilhomes
      let uncached =
        response.headers.get('uncached') &&
        response.headers.get('uncached').split(',');

      for (let doc of arrayResult) {
        if (doc?._id) {
          doc._id = doc._id?.toString();
          if (!!uncached && uncached.includes(doc?._id)) {
            doc.uncached = true;
            uncached = uncached.filter((id) => id !== doc?._id);
          }
        }
      }

      const created =
        !!uncached &&
        uncached.map(async (id) => {
          const url = `${process.env.REACT_APP_API_URL}/api/mobilhomes/${id}`;

          // add credentials (ie the whole bunch of cookies...) ONLY for websites that need it !
          const options = {
            method: 'GET',
            headers: {
              'x-access-token': API_TOKEN,
            },
          };

          const doc = await fetch(url, options);
          if (doc.status >= 400) {
            return null;
          }
          return doc.json();
        });

      // ? Created uncached ones
      return !!uncached
        ? Promise.all(created).then((docs) => {
            docs = docs.map((doc) => {
              if (!doc) return null;
              return { ...doc, uncached: true };
            });

            docs = docs.filter((doc) => doc !== null);

            arrayResult.push(...docs);
            dispatch(receiveMhList());
            return arrayResult;
          })
        : arrayResult;
    } catch (e) {
      console.error('fetchMhList:', e);
    }
  };
}

// ------------------------------------------------------------------------
// FETCH UNITS COUNT
// ------------------------------------------------------------------------
/**
 * OPTIONAL : Use this to filter output
 * @param {any} value
 * @returns
 */
export function fetchUnitsCount(value) {
  // Thunk middleware knows how to handle functions.
  // It passes the dispatch method as an argument to the function,
  // thus making it able to dispatch actions itself.
  return async function (dispatch) {
    const API_TOKEN = getAPIToken();

    // First dispatch: the app state is updated to inform that the API call is starting.
    // debugStore('fetchMhList: BEFORE REQUEST RESULT DISPATCH !!!!');
    // dispatch(requestMhList());

    // The function called by the thunk middleware can return a value,
    // that is passed on as the return value of the dispatch method.

    // In this case, we return a promise to wait for.
    // This is not required by thunk middleware, but it is convenient for us.
    const params = value ? `?type=${value}` : '';

    const url = `${process.env.REACT_APP_API_URL}/api/mobilhomes/count${params}`;

    // add credentials (ie the whole bunch of cookies...) ONLY for websites that need it !
    const options = {
      method: 'GET',
      headers: {
        'x-access-token': API_TOKEN,
        'Content-Type': 'application/json',
      },
    };

    // debugStore('fetchMhList: url=', url, options);

    let arrayResult = [];

    try {
      const response = await fetch(url, options);
      const contentType = response.headers.get('content-type');

      if (response.status >= 400) {
        console.error(
          `fetchMhList: error status=${response.status}, statusText=${response.statusText}, for url=${url}`,
        );
      }

      // dispatch(receiveMhList());

      if (contentType && contentType.indexOf('application/json') !== -1) {
        return response.json().then(function (json) {
          return json;
        });
      } else {
        console.log("Oops, nous n'avons pas du JSON!");
      }
    } catch (e) {
      console.error('fetchUnitsCount:', e);
    }
  };
}

// ------------------------------------------------------------------------
// FETCH UNIT MODELS
// ------------------------------------------------------------------------
/**
 * OPTIONAL : Use this to filter output
 * @param {any} value
 * @returns
 */
export function fetchUnitModels() {
  // Thunk middleware knows how to handle functions.
  // It passes the dispatch method as an argument to the function,
  // thus making it able to dispatch actions itself.
  return async function (dispatch) {
    const API_TOKEN = getAPIToken();

    // First dispatch: the app state is updated to inform that the API call is starting.
    // debugStore('fetchMhList: BEFORE REQUEST RESULT DISPATCH !!!!');
    // dispatch(requestMhList());

    // The function called by the thunk middleware can return a value,
    // that is passed on as the return value of the dispatch method.

    // In this case, we return a promise to wait for.
    // This is not required by thunk middleware, but it is convenient for us.
    const url = `${process.env.REACT_APP_API_URL}/api/mobilhomes/unit-models`;

    // add credentials (ie the whole bunch of cookies...) ONLY for websites that need it !
    const options = {
      method: 'GET',
      headers: {
        'x-access-token': API_TOKEN,
        'Content-Type': 'application/json',
      },
    };

    // debugStore('fetchMhList: url=', url, options);

    try {
      const response = await fetch(url, options);
      const contentType = response.headers.get('content-type');

      if (response.status >= 400) {
        console.error(
          `fetchMhList: error status=${response.status}, statusText=${response.statusText}, for url=${url}`,
        );
      }

      // dispatch(receiveMhList());

      if (contentType && contentType.indexOf('application/json') !== -1) {
        return response.json().then(function (json) {
          return json;
        });
      } else {
        console.log("Oops, nous n'avons pas du JSON!");
      }
    } catch (e) {
      console.error('fetchUnitsCount:', e);
    }
  };
}

// ------------------------------------------------------------------------
// FETCH COMMERCIAL TYPES MODELS
// ------------------------------------------------------------------------
/**
 * OPTIONAL : Use this to filter output
 * @param {any} value
 * @returns
 */
export function fetchCommercialTypeModels() {
  // Thunk middleware knows how to handle functions.
  // It passes the dispatch method as an argument to the function,
  // thus making it able to dispatch actions itself.
  return async function (dispatch) {
    const API_TOKEN = getAPIToken();

    // First dispatch: the app state is updated to inform that the API call is starting.
    // debugStore('fetchMhList: BEFORE REQUEST RESULT DISPATCH !!!!');
    // dispatch(requestMhList());

    // The function called by the thunk middleware can return a value,
    // that is passed on as the return value of the dispatch method.

    // In this case, we return a promise to wait for.
    // This is not required by thunk middleware, but it is convenient for us.
    const url = `${process.env.REACT_APP_API_URL}/api/mobilhomes/commercial-type-models`;

    // add credentials (ie the whole bunch of cookies...) ONLY for websites that need it !
    const options = {
      method: 'GET',
      headers: {
        'x-access-token': API_TOKEN,
        'Content-Type': 'application/json',
      },
    };

    // debugStore('fetchMhList: url=', url, options);

    try {
      const response = await fetch(url, options);
      const contentType = response.headers.get('content-type');

      if (response.status >= 400) {
        console.error(
          `fetchMhList: error status=${response.status}, statusText=${response.statusText}, for url=${url}`,
        );
      }

      // dispatch(receiveMhList());

      if (contentType && contentType.indexOf('application/json') !== -1) {
        return response.json().then(function (json) {
          return json;
        });
      } else {
        console.log("Oops, nous n'avons pas du JSON!");
      }
    } catch (e) {
      console.error('fetchUnitsCount:', e);
    }
  };
}

// ------------------------------------------------------------------------
// MOBILHOME EDITING
// ------------------------------------------------------------------------
export var RECEIVE_MH = 'RECEIVE_MH';

//
// After receiving data from API.
//
export function receiveMh(data) {
  return {
    type: RECEIVE_MH,
    data,
  };
}

//
// Fetch the mobilhome from API.
//
export function fetchMh(id) {
  return async function (dispatch) {
    const API_TOKEN = getAPIToken();

    debugStore(`fetchMh: EDIT MH id=${id}`);

    const url = `${process.env.REACT_APP_API_URL}/api/mobilhomes/${id}`;

    // add credentials (ie the whole bunch of cookies...) ONLY for websites that need it !
    const options = {
      method: 'GET',
      headers: {
        'x-access-token': API_TOKEN,
      },
    };

    debugStore('fetchMh: url=', url, options);

    return fetch(url, options)
      .then((res) => {
        if (res.status >= 400) {
          console.error(
            `fetchMh: error status=${response.status}, statusText=${response.statusText}, for url=${url}`,
          );
          const err = new Error(response.statusText);
          err.code = res.status;
          throw err;
        }

        return res.clone().json();
      })
      .then((res) => {
        dispatch(receiveMh(res));
        return res;
      });
  };
}

// -------------------
// Fetch file
// -------------------
export function fetchUnitFile(id) {
  return async function (dispatch) {
    const API_TOKEN = getAPIToken();

    // debugStore(`fetchMh: EDIT MH id=${id}`);

    const url = `${process.env.REACT_APP_API_URL}/api/mobilhomes/file/${id}`;

    // add credentials (ie the whole bunch of cookies...) ONLY for websites that need it !
    const options = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/octet-stream',
        'x-access-token': API_TOKEN,
      },
    };

    debugStore('fetchUnitFile: url=', url, options);

    return fetch(url, options).then((response) => {
      if (response.status >= 400) {
        console.error(
          `Error ${response.status} on fetch for fetchUnitFile ${id}, ${response.statusText}`,
        );

        return null;
      }
      return response.blob().then((blob) => {
        var url = window.URL.createObjectURL(blob);
        return url;
      });
    });
  };
}

// -------------------
// ONLY fetch F2M data
// -------------------
export function fetchUnitF2MData(barcode) {
  return async function (dispatch) {
    const API_TOKEN = getAPIToken();

    // debugStore(`fetchMh: EDIT MH id=${id}`);

    const url = `${process.env.REACT_APP_API_URL}/api/mobilhomes/f2m-data/${barcode}`;

    // add credentials (ie the whole bunch of cookies...) ONLY for websites that need it !
    const options = {
      method: 'GET',
      headers: {
        'x-access-token': API_TOKEN,
      },
    };

    debugStore('fetchMh: url=', url, options);

    return fetch(url, options)
      .then((response) => {
        if (response.status >= 400) {
          console.error(
            `Error ${response.status} on fetch for UnitF2MData ${barcode}, ${response.statusText}`,
          );

          return null;
        }
        return response.text();
      })
      .then((text) => {
        return text ? JSON.parse(text) : null;
      });
  };
}

// ------------------------
// Fetch all F2M data units
// ------------------------
export function fetchUnitsBarcode() {
  return async function (dispatch) {
    const API_TOKEN = getAPIToken();

    // debugStore(`fetchMh: EDIT MH id=${id}`);

    const url = `${process.env.REACT_APP_API_URL}/api/mobilhomes/barcodes`;
    const options = {
      method: 'GET',
      headers: {
        'x-access-token': API_TOKEN,
        'Content-Encoding': 'gzip',
      },
    };

    debugStore('fetchMhList: url=', url, options);

    let arrayResult = [];

    try {
      const response = await fetch(url, options);

      if (response.status >= 400) {
        console.error(
          `fetchMhList: error status=${response.status}, statusText=${response.statusText}, for url=${url}`,
        );
      }

      const reader = await response.arrayBuffer();
      // console.info(reader);
      BSON.deserializeStream(
        reader,
        0,
        response.headers.get('nbResults'),
        arrayResult,
        0,
      );

      for (let doc of arrayResult) {
        if (doc?._id) {
          doc._id = doc._id?.toString();
        }
      }

      dispatch(receiveMhList());

      return arrayResult;
    } catch (e) {
      console.error('fetchMhList:', e);
    }
  };
}

// ------------------------
// Fetch units by barcode
// ------------------------
export function fetchUnitsByBarcode(barcode) {
  return async function (dispatch) {
    const API_TOKEN = getAPIToken();

    // debugStore(`fetchMh: EDIT MH id=${id}`);

    const url = `${process.env.REACT_APP_API_URL}/api/mobilhomes/barcode/${barcode}`;
    const options = {
      method: 'GET',
      headers: {
        'x-access-token': API_TOKEN,
        'Content-Type': 'application/json',
      },
    };
    fetchUnitsByBarcode;
    debugStore('fetchMhList: url=', url, options);

    return fetch(url, options).then((response) => {
      if (response.status >= 400) {
        console.error(
          `Error ${response.status} on fetch for UnitF2MData ${barcode}, ${response.statusText}`,
        );

        return null;
      }
      return response.json();
    });
  };
}

//
// After receiving data from API.
//
export function updateMh(myValues, callback = null) {
  return async function (dispatch) {
    const API_TOKEN = getAPIToken();

    debugStore('updateMh: myValues=', myValues);

    delete myValues.__v;

    fixRefValues(myValues);

    // ! Encoding
    const enc = new TextEncoder('utf-8');

    const isEdit =
      myValues._id &&
      myValues._id.toString() &&
      myValues._id.toString().length > 0;

    const isEditBuffer = enc.encode(isEdit).buffer;

    const apiTokenBuffer = enc.encode(API_TOKEN).buffer;

    // ! Delegate to worker
    const valuesStringify = enc.encode(JSON.stringify(myValues));
    const valuesBuffer = valuesStringify.buffer;

    mobihomeWorker.onmessage = (event) => {
      const { _id, barcode } = event.data;

      let unitAwaitingRegistration = sessionStorage.getItem(
        'unitAwaitingRegistration',
      );
      unitAwaitingRegistration = !unitAwaitingRegistration
        ? []
        : JSON.parse(unitAwaitingRegistration);

      if (!unitAwaitingRegistration.some((id) => id === _id)) {
        unitAwaitingRegistration.push(_id);
      }

      sessionStorage.setItem(
        'unitAwaitingRegistration',
        JSON.stringify(unitAwaitingRegistration),
      );

      dispatch(receiveMh(event.data));
      dispatch(sendFlashMessage(`Saved ${barcode}!`, 'success'));
      callback && isEdit && callback();
      callback && !isEdit && callback(_id);

      return _id;
    };

    // ? Transferable object
    mobihomeWorker.postMessage({ apiTokenBuffer, isEditBuffer, valuesBuffer }, [
      apiTokenBuffer,
      isEditBuffer,
      valuesBuffer,
    ]);
  };
}

//
// For a new pending unit
//
export function createPendingUnit(myValues) {
  return function (dispatch) {
    const API_TOKEN = getAPIToken();

    debugStore('updateMh: myValues=', myValues);

    delete myValues.__v;

    fixRefValues(myValues);

    // console.log('JYO: myValues: ', myValues);

    // let url = isEdit ? `mobilhomes/${myValues._id}` : 'mobilhomes';
    const url = `${process.env.REACT_APP_API_URL}/api/mobilhomes/pending`;

    const options = {
      method: 'post',
      body: JSON.stringify(myValues),
      headers: {
        'Content-Type': 'application/json',
        'x-access-token': API_TOKEN,
      },
    };

    debugStore('updateMh: url=', url, options);

    return fetch(url, options)
      .then((response) => {
        if (response.status >= 400) {
          console.error(
            `updateMh: error status=${response.status}, statusText=${response.statusText}, for url=${url}`,
          );
          return response;
        }

        // Better JSON parsing error handling.
        return response.text();
      })
      .then((text) => {
        try {
          return JSON.parse(text);
        } catch (err) {
          console.error(
            `updateMh: JSON parse error from ${url} with response=${text}, err=${err}`,
          );
          // throw Error(`Error while saving MH data, err=${err}`);
          throw new Error(
            'Sorry but you do not have the right to create a new unit.',
          );
        }
      })
      .then((json) => {
        dispatch(receiveMh(json));
        return json;
      });
  };
}

// ------------------------------------------------------------------------
// MOBILHOME EXPORT
// ------------------------------------------------------------------------
export var REQUEST_MH_EXPORT = 'REQUEST_MH_EXPORT';
export var RECEIVE_MH_EXPORT = 'RECEIVE_MH_EXPORT';

//
// Before calling API.
//
export function requestMhExport() {
  return {
    type: REQUEST_MH_EXPORT,
  };
}

export var RECEIVE_MH_MOVEMENTS = 'RECEIVE_MH_MOVEMENTS';

//
// After receiving data from API.
//
export function receiveMhMovements(data) {
  return {
    type: RECEIVE_MH_MOVEMENTS,
    data,
  };
}

//
// Fetch the F2M data of this mobilhome from API.
//
export function fetchMhF2M(id) {
  return function (dispatch) {
    const API_TOKEN = getAPIToken();

    debugStore(`fetchMhF2M: GET MH MOVEMENTS id=${id}`);

    const url = `${process.env.REACT_APP_API_URL}/api/f2m/data_by_mh/${id}`;

    // add credentials (ie the whole bunch of cookies...) ONLY for websites that need it !
    const options = {
      method: 'GET',
      headers: {
        'x-access-token': API_TOKEN,
      },
    };

    debugStore('fetchMhF2M: url=', url, options);

    return fetch(url, options)
      .then((response) => {
        if (response.status >= 400) {
          console.error(
            `fetchMhF2M: error status=${response.status}, statusText=${response.statusText}, for url=${url}`,
          );
        }

        // Better JSON parsing error handling.
        return response.text();
      })
      .then((text) => {
        try {
          return JSON.parse(text);
        } catch (err) {
          console.error(
            `fetchMhF2M: JSON parse error from ${url} with response=${text}, err=${err}`,
          );
          throw Error(
            `Error while getting the MH data for id=${id}, err=${err}`,
          );
        }
      })
      .then((json) => {
        // ? Need to save in the redux cache ?
        return json;
      });
  };
}

//
// Fetch the movements of this mobilhome from API.
//
export function fetchMhMovements(id) {
  return function (dispatch) {
    const API_TOKEN = getAPIToken();

    debugStore(`fetchMhMovements: GET MH MOVEMENTS id=${id}`);

    const url = `${process.env.REACT_APP_API_URL}/api/transfers_by_mh?mh=${id}`;

    // add credentials (ie the whole bunch of cookies...) ONLY for websites that need it !
    const options = {
      method: 'GET',
      headers: {
        'x-access-token': API_TOKEN,
      },
    };

    debugStore('fetchMhMovements: url=', url, options);

    return fetch(url, options)
      .then((response) => {
        if (response.status >= 400) {
          console.error(
            `fetchMhMovements: error status=${response.status}, statusText=${response.statusText}, for url=${url}`,
          );
        }

        // Better JSON parsing error handling.
        return response.text();
      })
      .then((text) => {
        try {
          return JSON.parse(text);
        } catch (err) {
          console.error(
            `fetchMhMovements: JSON parse error from ${url} with response=${text}, err=${err}`,
          );
          throw Error(
            `Error while getting the MH data for id=${id}, err=${err}`,
          );
        }
      })
      .then((json) => {
        dispatch(receiveMhMovements(json));
        return json;
      });
  };
}

//
// Trig a transfer for selected MHs
//
export function transferMh(myValues) {
  return function (dispatch) {
    const API_TOKEN = getAPIToken();

    debugStore('transferMh: myValues=', myValues);

    delete myValues.__v;
    delete myValues?.user?.views;
    fixRefValues(myValues);

    const url = `${process.env.REACT_APP_API_URL}/api/transfers`;

    const options = {
      method: 'post',
      body: JSON.stringify(myValues),
      headers: {
        'Content-Type': 'application/json',
        'x-access-token': API_TOKEN,
      },
    };

    debugStore('transferMh: url=', url, options);

    return fetch(url, options)
      .then((response) => {
        if (response.status >= 400) {
          console.error(
            `transferMh: error status=${response.status}, statusText=${response.statusText}, for url=${url}`,
          );
        }

        // Better JSON parsing error handling.
        return response.text();
      })
      .then((text) => {
        try {
          return JSON.parse(text);
        } catch (err) {
          console.error(
            `transferMh: JSON parse error from ${url} with response=${text}, err=${err}`,
          );
          throw Error(`Error while saving MH transfer, err=${err}`);
        }
      });
    // .then(json => {
    //   dispatch(receiveMh(json))
    //   return json
    // })
  };
}

export function setMhForSale(ids) {
  return (dispatch) => {
    const API_TOKEN = getAPIToken();

    const url = `${process.env.REACT_APP_API_URL}/api/mobilhomes/for-sale`;

    const options = {
      method: 'PUT',
      body: JSON.stringify({ ids }),
      headers: {
        'Content-Type': 'application/json',
        'x-access-token': API_TOKEN,
      },
    };

    return fetch(url, options)
      .then((res) => {
        res.status >= 400 &&
          console.error(
            `salesMh: error status=${res.status}, statusText=${res.statusText}, for url=${url}`,
          );

        return res.json();
      })
      .then((res) => dispatch(sendFlashMessage(res, 'success')));

    /*
    debugStore('transferMh: url=', url, options);

    return fetch(url, options)
      .then((response) => {
        if (response.status >= 400) {
          
        }

        // Better JSON parsing error handling.
        return response.text();
      })
      .then((text) => {
        try {
          return JSON.parse(text);
        } catch (err) {
          console.error(
            `transferMh: JSON parse error from ${url} with response=${text}, err=${err}`,
          );
          throw Error(`Error while saving MH transfer, err=${err}`);
        }
      }); */
  };
}

export function setMhNotForSale(ids) {
  return (dispatch) => {
    const API_TOKEN = getAPIToken();

    const url = `${process.env.REACT_APP_API_URL}/api/mobilhomes/not-for-sale`;

    const options = {
      method: 'PUT',
      body: JSON.stringify({ ids }),
      headers: {
        'Content-Type': 'application/json',
        'x-access-token': API_TOKEN,
      },
    };

    return fetch(url, options)
      .then((res) => {
        res.status >= 400 &&
          console.error(
            `salesMh: error status=${res.status}, statusText=${res.statusText}, for url=${url}`,
          );

        return res.json();
      })
      .then((res) => dispatch(sendFlashMessage(res, 'success')));
  };
}
//
// Remove a mobilhome by its ID.
//
export function removeMh(mvId, callback = null) {
  return function (dispatch) {
    const API_TOKEN = getAPIToken();

    debugStore(`removeMh: REMOVE MOBILHOME id=${mvId}`);

    // ! Encoding
    const enc = new TextEncoder('utf-8');
    const apiTokenBuffer = enc.encode(API_TOKEN).buffer;
    const idBuffer = enc.encode(mvId).buffer;
    // ! Delegate to saving worker

    mobihomeWorker.onmessage = (event) => {
      const _id = event.data;
      let unitAwaitingRemoving = sessionStorage.getItem('unitAwaitingRemoving');
      unitAwaitingRemoving = !unitAwaitingRemoving
        ? []
        : JSON.parse(unitAwaitingRemoving);

      if (!unitAwaitingRemoving.some((id) => id === _id)) {
        unitAwaitingRemoving.push(_id);
      }

      sessionStorage.setItem(
        'unitAwaitingRemoving',
        JSON.stringify(unitAwaitingRemoving),
      );

      dispatch(sendFlashMessage(`Removed ${_id}`, 'success'));
      callback && callback();
    };

    // ? Transferable object
    mobihomeWorker.postMessage({ apiTokenBuffer, idBuffer }, [
      apiTokenBuffer,
      idBuffer,
    ]);
  };
}
