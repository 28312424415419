import React, { useState, useEffect } from 'react';
import getUser from '@security/User';
import MenuApp from '@components/navigations/MenuApp/index';
import CookieButton from '@components/buttons/CookieButton';
import Banner from '@components/common/Banner';

import { useCookies } from 'react-cookie';
import classnames from 'classnames';
import {
  sendFlashMessage,
  receiveNotification,
} from '@store/actions/action-common';

import style from './style.module.scss';
import socket from '@helpers/socketio-helper';

import MainHeader from '@components/headers/MainHeader';

const Layout = (props) => {
  const {
    children,
    history,
    location,
    userSettings,
    gridApi,
    api,
    gridName,
    dispatch,
  } = props;
  let { expanded } = props;
  const [cookies, setCookie, removeCookie] = useCookies(['enable_ga']);
  if (expanded === undefined) expanded = true;
  // console.log('@layout this.props: ', this.props)

  const user = getUser();
  // Not authenticated ? => no menu
  if (!user?.isAuthenticated) history.push('/login');

  function notificationSocket(notification) {
    if (user._id.toString() === notification.user_id.toString()) return;
    dispatch(sendFlashMessage(notification.message, 'info'));
    dispatch(receiveNotification(true));
  }

  useEffect(() => {
    socket.on('notification', notificationSocket);
    document.body.classList.remove('disabled-scrolling');

    return () => {
      socket.off('notification', notificationSocket);
    };
  }, []);

  return (
    <main className={classnames(style.main, 'layout')}>
      <Banner />
      <MainHeader t={props.t} history={props.history} />
      <MenuApp
        history={history}
        location={location}
        userSettings={userSettings}
        gridApi={gridApi}
        api={api}
        gridName={gridName}
      />
      <div
        className={classnames(
          style['layout-content'],
          cookies?.enable_ga === undefined || cookies?.enable_ga === 'false'
            ? style['layout-disabled']
            : undefined,
        )}
      >
        <div className={style['layout-body-width']}>{children}</div>
      </div>
      <CookieButton history={history} />
    </main>
  );
};

export default Layout;
